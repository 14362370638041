import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { SqueezeBoxComponent, ToastService } from 'ng-uikit-pro-standard';
import { DndDropEvent } from 'ngx-drag-drop';
import { CustomFormStatus, CustomFormType } from 'src/app/Enums/custom-form.enum';
import 'src/app/Extensions/arrayExtension';
import { BulkCommunicationService } from 'src/app/communication/bulk-communication.service';
import { PatientsService } from 'src/app/core/Patient/patients.service';
import { RingCentralService } from 'src/app/core/RingCentral/ring-central.service';
import { AppUiService } from 'src/app/core/app-ui.service';
import { ClonerService } from 'src/app/core/cloner.service';
import { CommunicationService } from 'src/app/core/communication.service';
import { CustomFormsService } from 'src/app/core/custom-forms.service';
import { DataFilterService } from 'src/app/core/data-filter.service';
import { EmitEvent, EventBusService, EventTypes } from 'src/app/core/event-bus.service';
import { FacilityDepartmentService } from 'src/app/core/facility-department.service';
import { FacilityService } from 'src/app/core/facility/facility.service';
import { HealthScoreService } from 'src/app/core/health-score.service';
import { SecurityService } from 'src/app/core/security/security.service';
import { TwoCTimezoneService } from 'src/app/core/two-ctime-zone.service';
import { LazyModalDto, TwoCModulesEnum } from 'src/app/model/AppModels/app.model';
import { PatientNotificationDto } from 'src/app/model/Patient/patient-notification-model';
import { BulkCommTagData, BulkTagDataGroupDto, BulkTagSection, BulkTemplateDataGroupDto, CommunicationStateEnum, CommunicationTagListDto, FilterTagDataParam, NewBulkCommTemplateListDto, PatientCommTemplateGroup, PatientTagData, PostBulkCommDto, TagCategory, TagSection, TemplateGroupListDto } from 'src/app/model/PatientEngagement/bulk-communication.model';
import { BulkCommunicationOption, CommunicationMethod } from 'src/app/model/PatientEngagement/communication.model';
import { HttpResError } from 'src/app/model/common/http-response-error';
import { AddEditCustomForm, FilterCustomForm } from 'src/app/model/custom-forms/custom-form.model';
import { Department } from 'src/app/model/facility-department.model';
import { PHSCareEpisodes } from 'src/app/model/health-score/health-score.model';
@Component({
  selector: 'app-new-bulk-communication',
  templateUrl: './new-bulk-communication.component.html',
  styleUrls: ['./new-bulk-communication.component.scss']
})
export class NewBulkCommunicationComponent implements OnInit {
  draggable = {
    // note that data is handled with JSON.stringify/JSON.parse
    // only set simple data or POJO's as methods will be lost
    data: "myDragData",
    effectAllowed: "all",
    disable: false,
    handle: false
  };

  // templatesList: TemplateGroupListDto[] = [];
  // bulkTagData: BulkCommTagData[] = [];
  bulkTagSections: BulkTagSection[] = [];
  bulkTemplateGroups: BulkTemplateDataGroupDto[] = [];
  bulkTemplateGroupsCopy: BulkTemplateDataGroupDto[] = [];
  gettingTemplates: boolean;
  facilityId: number;
  TagsList: CommunicationTagListDto[] = [];
  gettingTags: boolean;

  newBulkTemplatesList: NewBulkCommTemplateListDto[] = []
  postingCommunication: boolean;
  searchStr: string;
  filterTagParam = new FilterTagDataParam();
  CommunicationMethod = CommunicationMethod;
  TwoCModulesEnum = TwoCModulesEnum;
  CommunicationStateEnum = CommunicationStateEnum;
  filterCollapsed = false
  searchedPatients: PatientTagData[] = [];
  searchTemplateTerm: string;
  msgHeaderType: string;

  hideActionBtn: string;

  bulkCommunicationOption = BulkCommunicationOption.Template;

  BulkCommunicationOption = BulkCommunicationOption;
  isLoadingForms: boolean;

  phsCareEpisodesList = new Array<PHSCareEpisodes>();
  CustomFormsList = new Array<AddEditCustomForm>();
  tempCustomFormsList= new Array<AddEditCustomForm>();
  CustomFormStatusEnum = CustomFormStatus;
  filterCustomFormDto = new FilterCustomForm();
  chronicDiseaseList = new Array<{ id: 0; desc: ""; detail: "" }>();

  twoCModulesEnumList = this.dataService.getEnumAsList(TwoCModulesEnum);
  searchFormParam = '';
  isLoadingDepartmentsList= false;
  departmentsList = new Array<Department>();

  charCount = 0;
  charLimit= 900;

  constructor(@Inject(ToastService) private toaster: ToastService, private commService: CommunicationService, private dataService: DataFilterService,
    private healthScoreService: HealthScoreService,
    private eventBus: EventBusService, private router: Router,private bulkCommunication: BulkCommunicationService, private route: ActivatedRoute,
    private cdr: ChangeDetectorRef, public rcService: RingCentralService, private cloner: ClonerService, private appUi: AppUiService,
    private securityService: SecurityService, private patientsService: PatientsService, private facilityService: FacilityService,
    private customFormsService: CustomFormsService, private facilityDepartment: FacilityDepartmentService) { }

  ngOnInit(): void {
    this.facilityId = +this.securityService.getClaim('FacilityId')?.claimValue || 0;
    this.hideActionBtn = this.route.snapshot.queryParamMap.get("hideActionBtn");
    this.filterTagParam.communicationMethod = +(this.route.snapshot.queryParamMap.get("method")) as CommunicationMethod;
    this.filterTagParam.patientIds = this.route.snapshot.queryParamMap.get("patientIds");
    const formModule = this.route.snapshot.queryParamMap.get("FormModule");
    if(formModule){
      this.filterCustomFormDto.moduleIds = [+formModule]
    }
    const bulkCommunicationOption = this.route.snapshot.queryParamMap.get("option") as BulkCommunicationOption;
    if (bulkCommunicationOption) {
      this.bulkCommunicationOption = bulkCommunicationOption
    }
    this.InitFilterByPermission();
    this.GetAllTags();
    this.GetAllTemplates();
    this.getDepartmentsByFacilityId();
    this.getCronicDiseases();
    this.getAllCustomForms();
    this.getPHSCareEpisodes();
    window.addEventListener('message', this.receiveMessage, false);
  }
  InitFilterByPermission() {
    const TelephonyCommunication = this.securityService.getClaim('TelephonyCommunication')?.claimValue;
    const ccmService = this.securityService.getClaim('ccmService')?.claimValue;
    const rpmService = this.securityService.getClaim('rpmService')?.claimValue;
    const bhiService = this.securityService.getClaim('bhiService')?.claimValue;
    if (!TelephonyCommunication) {
      this.filterTagParam.communicationMethod = CommunicationMethod.App
    }
    if (!ccmService) {
      this.filterTagParam.serviceModule = TwoCModulesEnum.RPM
    }
    if (!rpmService) {
      this.filterTagParam.serviceModule = TwoCModulesEnum.BHI
    }
    this.GetTagsData()
  }
  SearchPatients() {
    this.searchStr = this.searchStr.trim();
    this.searchedPatients = []
    if (!this.searchStr) {
      return;
    }
    const clonedSections = this.bulkTagSections.deepClone()
    let patients: PatientTagData[] = []
    this.bulkTagSections.forEach(section => {
      section.values.forEach(group => {
        group.values.forEach(tag => {
          patients.push(...tag.patients);
        })
      })
    })
    patients = patients.distinctBy('patientId')
    this.searchedPatients = patients.filter(patient =>
      (patient.firstName || '').toLowerCase().includes(this.searchStr) ||
      (patient.middleName || '').toLowerCase().includes(this.searchStr) ||
      (patient.lastName || '').toLowerCase().includes(this.searchStr) ||
      ((patient.firstName || '') + ' ' + (patient.middleName || '') + ' ' + (patient.lastName || '')).toLowerCase().includes(this.searchStr)
    );
  }
  AppendForm(selectedForm: AddEditCustomForm) {
    if (this.filterTagParam.patientIds) {
      this.ResetExistingForm()
    }
    const alreadyExist = this.newBulkTemplatesList.some(x => x.formId == selectedForm.id)
    if (alreadyExist) {
      return
    }
    this.newBulkTemplatesList.forEach(element => {
      element.collapsed = true;
    });
    this.cdr.detectChanges();
    const nTemplate = new NewBulkCommTemplateListDto();
    nTemplate.formId = selectedForm.id;
    nTemplate.title = selectedForm.title
    nTemplate.text = `Hi @PatientFirstName@, please complete the ${selectedForm.title} need to be evaluated your current condition. @Link@ \n Thank you`
    nTemplate.collapsed = false;
    nTemplate.textCharCount =nTemplate.text.length;
    this.newBulkTemplatesList.push(nTemplate)
    this.cdr.detectChanges();


    if (this.filterTagParam.patientIds) {
      this.AddPatientsToTemplate(this.bulkTagSections[0].patients, nTemplate)
    }
  }
  AppendTemplate(template: TemplateGroupListDto, accordian: SqueezeBoxComponent) {
    if (this.filterTagParam.patientIds) {
      this.ResetExistingForm()
    }
    const alreadyExist = this.newBulkTemplatesList.some(x => x.templateId == template.id)
    if (alreadyExist) {
      return
    }
    if (accordian) {
      accordian.items.forEach(acc => {
        acc.applyToggle(true)
      })
    }
    this.newBulkTemplatesList.forEach(element => {
      element.collapsed = true;
    });
    const nTemplate = new NewBulkCommTemplateListDto();
    nTemplate.templateId = template.id;
    nTemplate.title = template.title
    nTemplate.text = `${template.text}`
    nTemplate.textCharCount = template.text.length;
    nTemplate.collapsed = false;
    this.newBulkTemplatesList.push(nTemplate)
    this.cdr.detectChanges();

    if (this.filterTagParam.patientIds) {
      this.AddPatientsToTemplate(this.bulkTagSections[0].patients, nTemplate)
    }
  }
  GetAllTemplates() {
    this.bulkTemplateGroups = [];
    this.bulkTemplateGroupsCopy = [];
    this.gettingTemplates = true;

    this.bulkCommunication.GetAllTemplates(this.facilityId).subscribe(
      (res: TemplateGroupListDto[]) => {
        this.gettingTemplates = false;

        if (res?.length) {
          // res = res.sortByNumber('patientsCount', true)
          res.forEach(x => {
            x.templateGroupName = PatientCommTemplateGroup[x.templateGroup]
          })
          const sectionCategories = res.groupByProp('templateGroupName')
          sectionCategories.forEach(group => {
            const newGroup = new BulkTemplateDataGroupDto();
            newGroup.Key = group.Key
            newGroup.collapsed = false
            newGroup.values = group.values;
            this.bulkTemplateGroups.push(newGroup)
            this.bulkTemplateGroupsCopy.push(newGroup)
          });
          // this.templatesList = res;

        }
      },
      (error: HttpResError) => {
        this.gettingTemplates = false;
        this.toaster.error(error.error, error.message);
      }
    );
  }
  // ApplyTemplateHeader(template: NewBulkCommTemplateListDto, type: string) {

  //   template.headerType = type;
  //   if (template.headerType == 'none') {
  //     template.headerText = ''
  //   }
  //   if (template.headerType == 'short') {
  //     template.headerText = `From ${this.securityService.securityObject.fullName} \n`
  //   }
  //   if (template.headerType == 'long') {
  //     const fName = this.securityService.getClaim("FacilityName").claimValue;
  //     template.headerText = `From ${this.securityService.securityObject.fullName} from ${fName}\n`
  //   }
  // }
  searchTemplates() {
    if (!this.searchTemplateTerm) {
      this.bulkTemplateGroups = this.bulkTemplateGroupsCopy.deepClone()
      return;
    }
    let sourceData = this.bulkTemplateGroupsCopy.deepClone()

    const searchResults: BulkTemplateDataGroupDto[] = [];

    for (const group of sourceData) {
      const filteredValues = group.values.filter(
        (item) => item.title.toLowerCase().includes(this.searchTemplateTerm.toLowerCase())
      );

      if (filteredValues.length > 0) {
        const searchResultGroup: BulkTemplateDataGroupDto = {
          Key: group.Key,
          collapsed: group.collapsed,
          values: filteredValues,
        };
        searchResults.push(searchResultGroup);
      }
    }

    this.bulkTemplateGroups = searchResults.deepClone()
  }

  ApplyTemplateHeader(template: NewBulkCommTemplateListDto, type: string) {

    template.headerType = type;
    if (template.headerType == 'none') {
      template.headerText = '';
      template.textCharCount = template.headerText.length + template.text.length;
      this.updateCharacterCount(template);
    }
    if (template.headerType == 'short') {
      template.headerText = `From: ${this.securityService.securityObject.fullName} \n`;
      template.textCharCount = template.headerText.length + template.text.length;
      this.updateCharacterCount(template);
    }
    if (template.headerType == 'long') {
      const fName = this.securityService.getClaim("FacilityName").claimValue;
      template.headerText = `From: ${this.securityService.securityObject.fullName} from ${fName}\n`;
      template.textCharCount = template.headerText.length + template.text.length;
      this.updateCharacterCount(template);
    }
  }
  ConfirmResetTagsDataParam() {
    if (!this.newBulkTemplatesList?.length) {
      this.ResetTagFilterData();
      return;
    }
    const modalDto = new LazyModalDto();
    modalDto.Title = "Reset Filters";
    modalDto.Text = "All templates will be removed. Are you sure to Reset Filters?";
    modalDto.callBack = this.ResetTagFilterData;
    modalDto.data = null;
    this.appUi.openLazyConfrimModal(modalDto);
  }
  ResetTagFilterData = () => {
    this.filterTagParam = new FilterTagDataParam();
    this.searchStr = '';
    this.newBulkTemplatesList.forEach(template => {
      this.RemoveTemplate(template)
    });
    this.InitFilterByPermission();
  }
  ConfirmApplyTagsDataParam() {
    if (!this.newBulkTemplatesList?.length) {
      this.GetTagsData();
      return;
    }
    const modalDto = new LazyModalDto();
    modalDto.Title = "Apply filters";
    modalDto.Text = "All templates will be removed. Are you sure to Apply filters?";
    modalDto.callBack = this.GetTagsData;
    modalDto.data = null;
    this.appUi.openLazyConfrimModal(modalDto);
  }
  GetTagsData = () => {
    this.bulkTagSections = [];
    this.gettingTags = true;
    this.filterTagParam.facilityId = this.facilityId;
    this.searchStr = '';
    this.ResetExistingForm();
    this.bulkCommunication.GetTagsData(this.filterTagParam).subscribe(
      (res: BulkCommTagData[]) => {
        if (res?.length) {
          res = res.filter(x => x.patientsCount)
          res = res.sortByNumber('patientsCount', true)
          res.forEach(x => {
            x.tagCategoryName = TagCategory[x.tagCategory]
            x.tagSectionName = TagSection[x.tagSection]
            x.patientsCount = x.patients?.length || 0;
            x.patients.forEach(patient => {
              patient.onDobPreview = this.isWithin7Days(patient.dob);
              patient.onStatsuPreview = x.tagSection == TagSection.Others;
              patient.reviewNoteCopy = patient.reviewNote;
              patient.telephonyCommunication = patient.telephonyCommunication;
              patient.lastAppLaunchDate = moment.utc(patient.lastAppLaunchDate).tz(TwoCTimezoneService?.currentTimeZone || moment.tz.guess()).toString();
              const today = moment();
              var duration = today.diff(patient.lastAppLaunchDate, "days");
              if (duration < 30) {
                patient.isActiveMobileUser = true;
              }
            })
          })
          const sections = res.groupByProp('tagSectionName')
          sections.forEach(section => {
            const newSection = new BulkTagSection();
            newSection.Key = section.Key;
            newSection.patients = [];
            newSection.collapsed = false;
            const sectionCategories = section.values.groupByProp('tagCategoryName')
            sectionCategories.forEach(group => {
              const newGroup = new BulkTagDataGroupDto();
              newGroup.Key = group.Key
              newGroup.collapsed = false
              newGroup.values = group.values
              newSection.values.push(newGroup)
            });
            this.bulkTagSections.push(newSection)
            this.FillSectionPatientsCount()
          });
          this.bulkTagSections = this.bulkTagSections.sortAlphabetically('Key')
          // this.bulkTagData = res;
        }
        this.gettingTags = false;
      },
      (error: HttpResError) => {
        this.gettingTags = false;
        this.toaster.error(error.error, error.message);
      }
    );
  }
  isWithin7Days(dateToCheck: string) {
    const currentDate = moment().startOf('day'); // Current date, starting at midnight
    const previousDate = moment().subtract(3, 'days').startOf('day'); // 3 days ago, starting at midnight
    const nextDate = moment().add(3, 'days').endOf('day'); // 3 days from now, ending at 23:59:59

    const dateToCheckWithoutYear = moment(dateToCheck).format('MM-DD');

    return (
      moment().format('MM-DD') === dateToCheckWithoutYear || // Check if it's the current date
      moment(previousDate).format('MM-DD') <= dateToCheckWithoutYear && dateToCheckWithoutYear <= moment(nextDate).format('MM-DD')
    );
  }
  GetAllTags() {
    this.TagsList = [];
    this.gettingTags = true;

    this.bulkCommunication.GetAllTags(this.facilityId).subscribe(
      (res: CommunicationTagListDto[]) => {
        this.gettingTags = false;
        if (res?.length) {
          this.TagsList = res;

        }
      },
      (error: HttpResError) => {
        this.gettingTags = false;
        this.toaster.error(error.error, error.message);
      }
    );
  }
  openConfirmModal() {
    const alertContent = ''; // this.GetAlertText()
    const modalDto = new LazyModalDto();
    modalDto.Title = "Post Bulk Communication";
    // modalDto.Text = `${alertContent}`;
    modalDto.Text = `Are you sure to post bulk communication?`;
    modalDto.callBack = this.SubmitBulkCommunicationData;
    // modalDto.data = data;
    this.appUi.openLazyConfrimModal(modalDto);
  }
  SubmitBulkCommunicationData = () => {
    const templateData = this.newBulkTemplatesList.deepClone();
    templateData.forEach(template => {
      const BulkCommObj = new PostBulkCommDto()
      BulkCommObj.facilityId = this.facilityId;
      BulkCommObj.message =  `${template.headerText}${template.text}`;
      BulkCommObj.method = this.filterTagParam.communicationMethod;
      // BulkCommObj.method = CommunicationMethod.Telephony;
      if (template.formId) {
        BulkCommObj.formId = template.formId;
      }
      // Filter unconsented or inactive app users from list
      template.patients = template.patients.filter(patient => patient.excludeReview || !patient.onStatsuPreview && !patient.onDobPreview && !patient.reviewNote &&
        ((this.filterTagParam.communicationMethod == CommunicationMethod.Telephony && patient.telephonyCommunication) || (this.filterTagParam.communicationMethod ==CommunicationMethod.App && patient.isActiveMobileUser)))
      // template.patients = template.patients.filter(x => (this.filterTagParam.communicationMethod ==CommunicationMethod.Telephony && x.telephonyCommunication)
      //   || (this.filterTagParam.communicationMethod ==CommunicationMethod.App && x.isActiveMobileUser)
      // )

      BulkCommObj.patientIds = template.patients.map(x => x.patientId);
      BulkCommObj.senderUserId = this.securityService.securityObject.appUserId;
      this.PostBulkMessage(BulkCommObj);
    });
    // this.newBulkTemplatesList.forEach(template => {
    //   this.RemoveTemplate(template)
    // });
    this.ResetExistingForm()
  }
  PostBulkMessage(objData: PostBulkCommDto) {
    this.postingCommunication = true;

    this.bulkCommunication.PostBulkMessage(objData).subscribe(
      (res: any) => {
        this.postingCommunication = false;
        this.toaster.info(`Campaign with id ${res?.campaignId} Registered `, `All messages will be sent shortly `)

      },
      (error: HttpResError) => {
        this.postingCommunication = false;
        this.toaster.error(error.error, error.message);
      }
    );
  }
  EditPatientReviewNote = (patient: PatientTagData) => {
    this.bulkCommunication.EditPatientReviewNote(patient.patientId, patient.reviewNoteCopy).subscribe(
      (res: any) => {

      },
      (error: HttpResError) => {
        this.toaster.error(error.error, error.message);
      }
    );
  };

  onDragStart(event: DragEvent) {

    // console.log("drag started", JSON.stringify(event, null, 2));
  }

  onDragEnd(event: DragEvent) {

    // console.log("drag ended", JSON.stringify(event, null, 2));
  }

  onDraggableCopied(event: DragEvent) {

    // console.log("draggable copied", JSON.stringify(event, null, 2));
  }

  onDraggableLinked(event: DragEvent) {

    // console.log("draggable linked", JSON.stringify(event, null, 2));
  }

  onDraggableMoved(event: DragEvent) {

    // console.log("draggable moved", JSON.stringify(event, null, 2));
  }

  onDragCanceled(event: DragEvent) {

    // console.log("drag cancelled", JSON.stringify(event, null, 2));
  }

  onDragover(event: DragEvent) {
    // console.log("dragover", JSON.stringify(event, null, 2));
  }

  onDrop(event: DndDropEvent, template: NewBulkCommTemplateListDto) {
    // console.log("dropped", JSON.stringify(event, null, 2));
    if (!event.data) {
      return;
    }
    const selection = event.data as any;
    if(selection.patients) {
      this.AddPatientsToTemplate(selection.patients, template)
    }
    if (selection.patientId) {
      this.AddPatientsToTemplate([selection], template)
    }
    // this.AddTagToTemplate(selectedTag, template)

  }
  AddPatientsToTemplate(selectedPatients: PatientTagData[], template: NewBulkCommTemplateListDto) {
    let tagDataList: BulkCommTagData[] = []
    const clonedSections = this.bulkTagSections.deepClone()
    clonedSections.forEach(section => {
      section.values.forEach(group => {
        group.values.forEach(tag => {
          const tagPatients = tag.patients.filter(y => !y.selected && selectedPatients.some(p => p.patientId == y.patientId))
          if (tagPatients?.length) {
            const nTag = tag;
            nTag.patients = tagPatients;
            nTag.patientsCount = tagPatients.length
            tagDataList.push(nTag)
          }
        });
      });
    });
    tagDataList.forEach(tag => {
      this.AddTagToTemplate(tag, template)
    });
  }
  AddTagToTemplate(selectedTag: BulkCommTagData, template: NewBulkCommTemplateListDto) {
    const templatetag = template.tags.find(x => x.tagName == selectedTag.tagName);
    selectedTag.patients.filter(x => !x.selected)
    if (templatetag) {
      const ids = [...selectedTag.patients, ...templatetag.patients]
      templatetag.patients = ids.distinctBy('patientId')
    } else {
      template.tags.push(selectedTag)
    }
    template.tags.forEach(x => {
      x.patients = x.patients.filter(x => !x.selected)
      x.patientsCount = x.patients.length;
    })
    let patients = template.tags.reduce((acc: PatientTagData[], tag) => acc.concat(tag.patients), []);
    patients = patients.distinctBy('patientId')
    template.patientsCount = patients.length
    template.patients = patients;
    template.tags = template.tags.sortByNumber('patientsCount', true)
    template.previewCount = patients.filter(x => (x.onStatsuPreview || x.onDobPreview || x.reviewNote
      || (this.filterTagParam.communicationMethod ==CommunicationMethod.Telephony && !x.telephonyCommunication)
      || (this.filterTagParam.communicationMethod ==CommunicationMethod.App && !x.isActiveMobileUser)) && !x.excludeReview
      ).length;
    this.CalculatePatientTagData();
  }

  RemoveTemplate(template: NewBulkCommTemplateListDto) {
    this.newBulkTemplatesList = this.newBulkTemplatesList.filter(x => {
      if (x.templateId && x.templateId != template.templateId) {
        return true
      }
      if (x.formId && x.formId != template.formId) {
        return true
      }
      return false
    })
    this.CalculatePatientTagData();
  }
  RemoveAllTagFromTemplate(template: NewBulkCommTemplateListDto) {
    template.tags = []
    template.patients = [];
    template.patientsCount = 0;
    template.previewCount = 0;
    this.CalculatePatientTagData();
  }
  RemoveTagFromTemplate(sTag: BulkCommTagData, template: NewBulkCommTemplateListDto) {
    const selectedTag = this.cloner.deepClone<BulkCommTagData>(sTag)
    template.tags.forEach(tag => {
      tag.patients = tag.patients.filter(y => {
        const deletePatient = selectedTag.patients.find(d => d.patientId == y.patientId)
        if (deletePatient) {
          return false;
        }
        return true;
      })
      tag.patientsCount = tag.patients.length
    });
    template.tags = template.tags.filter(tag => tag.patients?.length)
    let patients = template.tags.reduce((acc: PatientTagData[], tag) => acc.concat(tag.patients), []);
    patients = patients.distinctBy('patientId')
    template.patientsCount = patients.length
    template.patients = patients;
    template.tags = template.tags.sortByNumber('patientsCount', true)
    template.previewCount = patients.filter(x => x.onStatsuPreview || x.onDobPreview || x.reviewNote
      || (this.filterTagParam.communicationMethod ==CommunicationMethod.Telephony && !x.telephonyCommunication)
      || (this.filterTagParam.communicationMethod ==CommunicationMethod.App && !x.isActiveMobileUser)
      ).length;
    this.CalculatePatientTagData();
  }
  EmptyReviewNote(patient: PatientTagData, template: NewBulkCommTemplateListDto) {
    patient.reviewNote = ``
    patient.reviewNoteCopy = ``
    this.EditPatientReviewNote(patient)
    this.RecalculateTemplatePatientsData(patient, template)
  }
  RecalculateTemplatePatientsData(patient: PatientTagData, template: NewBulkCommTemplateListDto) {
    template.previewCount = template.patients.filter(x => (x.onStatsuPreview || x.onDobPreview || x.reviewNote
      || (this.filterTagParam.communicationMethod ==CommunicationMethod.Telephony && !x.telephonyCommunication)
      || (this.filterTagParam.communicationMethod ==CommunicationMethod.App && !x.isActiveMobileUser)) && !x.excludeReview
      ).length;
  }
  RemovePatientFromTemplate(patient: PatientTagData, template: NewBulkCommTemplateListDto) {
    template.tags.forEach(tag => {
      tag.patients = tag.patients.filter(y => {
        const deletePatient = y.patientId == patient.patientId;
        if (deletePatient) {
          return false;
        }
        return true;
      })
      tag.patientsCount = tag.patients.length
    });
    template.tags = template.tags.filter(tag => tag.patients?.length)
    let patients = template.tags.reduce((acc: PatientTagData[], tag) => acc.concat(tag.patients), []);
    patients = patients.distinctBy('patientId')
    template.patientsCount = patients.length
    template.patients = patients;
    template.tags = template.tags.sortByNumber('patientsCount', true)
    template.previewCount = patients.filter(x => (x.onStatsuPreview || x.onDobPreview || x.reviewNote
      || (this.filterTagParam.communicationMethod ==CommunicationMethod.Telephony && !x.telephonyCommunication)
      || (this.filterTagParam.communicationMethod ==CommunicationMethod.App && !x.isActiveMobileUser)) && !x.excludeReview
      ).length;
    this.CalculatePatientTagData();
  }

  CalculatePatientTagData() {
    const allTemplatePatients = this.flattenTemplatePatients();
    this.bulkTagSections.forEach(section => {
      section.values.forEach(tagGroup => {
        tagGroup.values.forEach(tag => {
          tag.patients.forEach(x => {
            const patientSelected = allTemplatePatients.some(y => y.patientId == x.patientId)
            x.selected = patientSelected;
          })
          tag.patientsCount = tag.patients?.filter(x => !x.selected).length
        });
        tagGroup.values = tagGroup.values.sortByNumber('patientsCount', true)
      })
    });
    this.FillSectionPatientsCount()
    this.bulkTagSections = this.bulkTagSections.sortAlphabetically('Key')
  }
  // Function to calculate the total number of patients
  FillSectionPatientsCount() {

    const deepBulkSections = this.bulkTagSections.deepClone()
    deepBulkSections.forEach(section => {
      let patients: PatientTagData[] = [];
      section.patients = [];
      section.values.forEach((group) => {
        group.values.forEach((tag) => {
          tag.patients = tag.patients.filter(y => !y.selected)
          patients.push(...tag.patients);
        });
      });
      patients = patients.distinctBy('patientId')
      const rSection = this.bulkTagSections.find(x => x.Key == section.Key)
      if (rSection) {
        rSection.patients = [];
        rSection.patients = [...patients];
      }
    })
  }
  // Function to flatten the nested arrays
 flattenTemplatePatients(): PatientTagData[] {
  let patients: PatientTagData[] = [];

  this.newBulkTemplatesList.forEach((template) => {
    template.tags.forEach((tag) => {
      patients.push(...tag.patients);
    });
  });
  patients = patients.distinctBy('patientId')

  return patients;
}
  heightControl(item: BulkTagDataGroupDto){
    item.showAll = !item.showAll
  }
  //// Health Score Form Work
  getAllCustomForms() {
    this.isLoadingForms = true;
    if (this.filterCustomFormDto.chronicConditionIds && this.filterCustomFormDto.chronicConditionIds.length) {
      if (this.filterCustomFormDto.chronicConditionIds.length > 1 && this.filterCustomFormDto.chronicConditionIds.includes("")) {
        this.filterCustomFormDto.chronicConditionIds = this.filterCustomFormDto.chronicConditionIds.filter((x) => x !== "");
      }
    }
    this.filterCustomFormDto.formType = CustomFormType['Patient Health Score']
    this.filterCustomFormDto.status = CustomFormStatus.Submitted
    this.customFormsService.getAllCustomForms(this.filterCustomFormDto).subscribe(
      (res: any) => {
        this.CustomFormsList = res;
        this.tempCustomFormsList = res;
        this.isLoadingForms = false;
      },
      (error: HttpResError) => {
        this.toaster.error(error.error, error.message);
        this.isLoadingForms = false;
      }
    );
  }
  getCronicDiseases() {
    this.patientsService.getChronicConditions().subscribe(
      (res: any) => {
        this.chronicDiseaseList = res;
      },
      (error: HttpResError) => {
        this.toaster.error(error.error, error.message);
      }
    );
  }
  getPHSCareEpisodes() {
    this.healthScoreService.phsCareEpisodes().subscribe(
      (res: any) => {
        this.phsCareEpisodesList = res;
      },
      (err: HttpResError) => {
        this.toaster.error(err.error);
      }
    );
  }
  ResetExistingForm () {
    if (this.newBulkTemplatesList?.length) {
      this.newBulkTemplatesList.forEach(template => {
        this.RemoveTemplate(template)
      });
    }
  }
  filterForms(event) {
    const val = event.toLowerCase();
    const temp = this.tempCustomFormsList.filter((CustomForm) => {
      return (
        CustomForm.title.toLocaleLowerCase().indexOf(val) !== -1 ||
        !val
      );
    });
    this.CustomFormsList = temp;
  }
  addChange(cValue, type){
    console.log(cValue)
    if(type == 'modules'){
      if(cValue === ''){
        this.filterCustomFormDto.moduleIds = [''];
      }
    }
    if(type == 'episodes'){
      if(cValue === ''){
        this.filterCustomFormDto.careEpisodeIds = [''];
      }
    }
    this.getAllCustomForms();
  }
  removeChange(cValue){
    this.getAllCustomForms();
  }
  changeFilter(values, type){
    if(type == 'modules'){
      if (values && values.length) {
        if (values.length > 1 && values.includes("")) {
          this.filterCustomFormDto.moduleIds = values.filter((x) => x !== "");
        }
      }
    }
    if(type == 'episodes'){
      if (values && values.length) {
        if (values.length > 1 && values.includes("")) {
          this.filterCustomFormDto.careEpisodeIds = values.filter((x) => x !== "");
        }
      }
    }
  }
  receiveMessage = (event) => {
    if (event.data.type === 'PatientNotificationSettingChanged') {
      const notificationSetting = event.data.mData as PatientNotificationDto;
      this.bulkTagSections.forEach(x => {
        x.patients.forEach(p => {
          if (p?.patientId == notificationSetting.patientId) {
            p.telephonyCommunication = notificationSetting.telephonyCommunication
          }
        })
        x.values?.forEach(m => {
          m.values?.forEach(tag => {
            tag.patients?.forEach(p => {
              if (p?.patientId == notificationSetting.patientId) {
                p.telephonyCommunication = notificationSetting.telephonyCommunication
              }
            })
          })
        })
      })
      this.newBulkTemplatesList?.forEach(template => {
        template?.patients.forEach(p => {
          if (p?.patientId == notificationSetting.patientId) {
            p.telephonyCommunication = notificationSetting.telephonyCommunication
          }
        })
        template.patientsCount = template.patients.length
        template.patients = template.patients;
        template.tags = template.tags.sortByNumber('patientsCount', true)
        template.previewCount = template.patients.filter(x => (x.onStatsuPreview || x.onDobPreview || x.reviewNote
          || (this.filterTagParam.communicationMethod ==CommunicationMethod.Telephony && !x.telephonyCommunication)
          || (this.filterTagParam.communicationMethod ==CommunicationMethod.App && !x.isActiveMobileUser)) && !x.excludeReview
          ).length;
      })

    }
  }
  OpenPatientSetting(id: number) {
    const emitObj = new EmitEvent();
    emitObj.name = EventTypes.TriggerGlobalIframe;
    emitObj.value = `insights/setting/sms-voice-consent/${id}?patientID=${id}`;

    this.eventBus.emit(emitObj);
  }

  GetAlertText() {
    const allTemplatePatients = this.flattenTemplatePatients().deepClone();
    const ignoredPatientsList = allTemplatePatients.filter(x => (this.filterTagParam.communicationMethod ==CommunicationMethod.Telephony && !x.telephonyCommunication)
      || (this.filterTagParam.communicationMethod ==CommunicationMethod.App && !x.isActiveMobileUser)
    )
    // <div class="d-flex justify-content-between">
    //   <p class="mb-2"><strong>Time</strong></p>
    //   <div>
    //     <strong class="ml-3">(${this.sumEncounterstime()})</strong>
    //     <span class="text-primary">${this.sumEncounterstime() > 19 ? "Meet" : "Not Meet"}</span>
    //   </div>
    // </div>
    let ignoreHeaderText = `<div class="d-flex justify-content-between alert alert-warning alert-dismissible animated fade show" role="alert">
        Below mentioned patients will be ignored <strong >${ignoredPatientsList?.length}</strong>
      </div>`
    let patientsListText = ``
    ignoredPatientsList.forEach(patient => {
      patientsListText += `

          <li class="mb-2"><span class="badge badge-info mr-2">${patient.lastName} ${patient.firstName} ${patient.middleName || ''}</span></li>
        `
    });
    if (!ignoredPatientsList?.length) {
      ignoreHeaderText = ''
    }

    const showValidationAlert = `
      <div>
        <p>Are you sure to post bulk communication ?</p>
        ${ignoreHeaderText}
        <div class="b-h-200" >
          <div class="b-h-scroll" >
          <ul class="m-0 p-0">
            ${patientsListText}
          </ul>
          </div>
        </div>
      </div>`;

    return showValidationAlert
  }
  getDepartmentsByFacilityId() {
    this.isLoadingDepartmentsList = true;
    this.facilityDepartment
      .getDepartmentsByFacilityId(this.facilityId)
      .subscribe(
        (res: any) => {
          this.departmentsList = res;
          this.isLoadingDepartmentsList = false;
        },
        (err: HttpResError) => {
          this.toaster.error(err.error);
          this.isLoadingDepartmentsList = false;
        }
      );
  }
  departmentFilterChange(values){
    if (values && values.length) {
      if (values.length > 1 && values.includes('')) {
        this.filterTagParam.departmentIds = values.filter((x) => x !== '');
      }
    }else{
      this.filterTagParam.departmentIds= [''];
    }
    // this.filterPatients();
  }
  departmentFilterAddChange(value){
    if(value == ''){
      this.filterTagParam.departmentIds= [''];
    }
  }
  updateCharacterCount(template: NewBulkCommTemplateListDto) {
    template.textCharCount = template.headerText.length + template.text.length;
    if(template.textCharCount >= 900){
      // template.text = template.text.slice(0, this.charLimit)
      this.toaster.warning('Character limit reached!');
    }
  }
  isTextCharCountReachedToLimit(){
    return this.newBulkTemplatesList.some((obj: NewBulkCommTemplateListDto) => obj.textCharCount > 900);
  }
}
