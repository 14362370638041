<div  mdbModal #bhiEncounterModal="mdbModal" (close)="resetBhiEncounter()" class="modal fade" tabindex="-1" role="dialog"
id="AlertModal" aria-labelledby="myBasicModalLabel" aria-hidden="true">
<div class="modal-dialog modal-dialog-centered flat-modal p-4" style="max-width:650px ;" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="bhiEncounterModal.hide()">
        <span aria-hidden="true">×</span>
      </button>
      <h4 class="text-dynamic-2c modal-title font-500 mr-3">Add BHI Encounter</h4>
    </div>
    <div class="modal-body">
      <div class="encounter-body">
        <div class="row">
          <div class="col-sm-6">
            <div class="input-group">
              <label class="w-100">Date<sup class="text-danger">*</sup></label>
              <input type="text" class="form-control form-control-sm" #dateDPicker="dpDayPicker"
                 [dpDayPicker]="datePickerConfig1" [(ngModel)]="bhiEncounterDto.encounterDate"
                [theme]="'dp-material ccm-date-picker'" [mode]="'day'" appDateTimeValidator="date" />
                <a class="input-group-append">
                  <span class="input-group-text text-white bg-dynamic-2c" id="category">
                    <i class="las la-calendar fa-lg"></i>
                  </span>
                </a>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 ">
                <div class="form-group">
                  <label class="w-100">Start Time<sup class="text-danger">*</sup></label>
                  <input type="text" class="form-control form-control-sm" #dateDpTime="dpDayPicker"
                    [dpDayPicker]="timePickerConfig" [(ngModel)]="bhiEncounterDto.startTime"
                    [theme]="'dp-material ccm-date-picker'" [mode]="'time'" appDateTimeValidator="time"  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 pl-sm-0">
                <div class="form-group">
                  <label class="w-100">End Time<sup class="text-danger">*</sup></label>
                  <input type="text" readonly class="form-control form-control-sm" [(ngModel)]="bhiEncounterDto.endTime" >
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="d-flex w-100">
              <div class="form-group flex-grow-1">
                <label class="w-100">Duration<small class="text-info">Enter in Minutes</small></label>
                <input #durationInput [(ngModel)]="durationNO" type="number" [readOnly]="!bhiEncounterDto.startTime"
                class="form-control form-control-sm" (input)="durationChanged($event.target.value)">
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group" >
              <label>Psychiatrist <sup class="text-danger">*</sup> </label>
              <ng-select [searchable]="true" [loading]=loadingPsy class="ng-select-small"
                [(ngModel)]="bhiEncounterDto.psychiatristId" placeholder="Select Provider"
                (click)="$event.stopPropagation();">
                <ng-option [value]="item.id" *ngFor="let item of (facilityUsersList | filterFUsersByRole: 'Psychiatrist')">{{item.lastName}}, 
 {{item.firstName}}</ng-option>
              </ng-select>
            </div>
      
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label>Care Coordinator <sup class="text-danger">*</sup> </label>
              <ng-select [searchable]="true" [loading]=loadingPsy class="ng-select-small" [multiple]="false"
                [(ngModel)]="bhiEncounterDto.bhiCareCoordinatorId" placeholder="Care Coordinator"
                (click)="$event.stopPropagation();">
                <ng-option [value]="item.id" *ngFor="let item of (facilityUsersList | filterFUsersByRole: 'Care Coordinator')">{{item.lastName}}, 
 {{item.firstName}}</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label>Service Name <sup class="text-danger">*</sup> </label>
              <ng-select [searchable]="true" class="ng-select-small"
                [(ngModel)]="bhiEncounterDto.bhiServiceTypeId" placeholder="Select Service Type"
                (click)="$event.stopPropagation();">
                <ng-option [value]="0">Evaluation Counselling </ng-option>
                <ng-option [value]="1">Initial Assessment </ng-option>
              </ng-select>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-form form-group" *ngIf="bhiEncounterDto.patientId || patientDetails?.id">
              <label for="form12">Notes<sup class="text-danger">*</sup></label>
              <app-two-c-text-area #myFIeldRefBHI [value]="bhiEncounterDto.note" [height]="'140px'" [PatientId]="bhiEncounterDto.patientId || patientDetails?.id" (valueChanged)="bhiEncounterDto.note=$event"></app-two-c-text-area>
          </div>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="bhiEncounterDto.id === 0 && (!patientEncounterMonthlyStatusAcknowledge || (selectedPatient.rpmMonthlyStatus === rpmMonthlyStatusEnum['Not Started']))" class="d-flex justify-content-between alert alert-warning alert-dismissible animated fade show" role="alert">
        <strong >Acknowledge/update monthly status to save</strong>
      </div> -->
    </div>

    <div class="modal-footer d-flex justify-content-between align-items-center">
      <div>
        <div *ngIf="bhiEncounterDto.id === 0" class=" d-flex justify-content-between mb-2 align-items-center">
          <p class="m-0"><strong> Monthly Status: </strong></p>
          <div style="padding: 2px;" class=" ml-3 border rounded" [class.border-success]=" (bhiEncounterDto.bhiMonthlyStatus !== bhiMonthlyStatusEnum['Not Started'])"
          [class.border-warning]="(bhiEncounterDto.bhiMonthlyStatus === bhiMonthlyStatusEnum['Not Started'])">
            <div class="btn-group cursor-pointer"  mdbDropdown>
              <mdb-badge style="line-height: 14px;"  mdbDropdownToggle success="true" >{{PatientEncounterMonthlyStatusTExt}}</mdb-badge>

              <div class="dropdown-menu status-dropdown" style="bottom: 50px; top: auto; right: -4px;width: 190px;">
                <div>
                  <a [class.active]="1 === value" (click)="bhiEncounterDto.bhiMonthlyStatus=item.value;PatientEncounterMonthlyStatusTExt=item.name;" *ngFor="let item of bhiMonthlyStatusList" class="dropdown-item">{{item.name}}</a>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
      <div>
        <button type="button" (click)="bhiEncounterModal.hide()" aria-label="Close" class="waves-light btn-sm btn-dynamic-secondary-2c btn" >Close</button>
        <button *ngIf="bhiEncounterDto.id === 0" [disabled]="isAddingEncounterLoading || (bhiEncounterDto.bhiMonthlyStatus === bhiMonthlyStatusEnum['Not Started']) || !bhiEncounterDto.bhiCareCoordinatorId || !bhiEncounterDto.note || !bhiEncounterDto.startTime || !bhiEncounterDto.endTime
        || (!bhiEncounterDto.psychiatristId && bhiStatus !== 4)" type="submit" (click)="addBhiEncounter()" mdbBtn
    class="btn btn-dynamic-2c btn-sm m-o">
    <span *ngIf="isAddingEncounterLoading" class="spinner-border spinner-border-sm" role="status"
      aria-hidden="true"></span>
    Add Encounter</button>
      </div>
    </div>
  </div>
</div>
</div>
<div mdbModal #bhiMonthStatusModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myBasicModalLabel" aria-hidden="true" [config]="{backdrop: true, ignoreBackdropClick: true}" mdbTableScroll>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="bhiMonthStatusModal.hide()">
          <span aria-hidden="true">×</span>
        </button> -->
        <h4 class="modal-title w-100" id="myModalLabel">BHI Month Status</h4>
      </div>
      <div class="modal-body">
        <!-- <p class="text-center"><i class="las la-exclamation-triangle text-danger" style="font-size: 3rem;"></i> </p> -->
        <div class="text-center" style="font-size: 20px;">Please select BHI encounter month ?</div>
        <div class="align-content-center d-flex justify-content-center">
          <ul class="status-list">
            <li > <a class="link" (click)="bhiMonthStatus = 1;SetBhiCocmMonth()">First Month </a>
            <li > <a class="link" (click)="bhiMonthStatus = 2;SetBhiCocmMonth()">Subsequent Month </a>
            </li>
          </ul>
        </div>
      </div>
      <!-- <div class="modal-footer"> -->

        <!-- <button type="button" (click)="bhiMonthStatusModal.hide()" class="waves-light btn btn-sm btn-dynamic-secondary-2c"
          aria-label="Close" mdbWavesEffect>Cancel</button> -->
        <!-- <button type="button" mdbBtn color="priary" (click)="addRequiredData()" class="relative waves-light"
          mdbWavesEffect>Add Data</button> -->
      <!-- </div> -->
    </div>
  </div>
</div>