<div class="d-flex flex-row align-items-center justify-content-between mb-3">
    <div>
      <h1 class="page-title mb-0">Bulk Communication Campaigns</h1>
    </div>
</div>
<div class="d-flex align-items-end mb-3">
  <div *hasClaim="['IsAppAdmin']" class="flex-1 mr-2">
    <div class="form-group mb-0">
      <!-- <label class="w-100 mb-0">Facility </label>
      <input type="number" [(ngModel)]="tempPatientCommunicationDto.facilityId" OnlyNumbers class="form-control form-control-sm" id="facilityId"> -->
      <label for="Facilities">Select Facility<small class="text-danger">*</small></label>
      <ng-select class="ng-select-small" name="myFacilities" [(ngModel)]="facilityId" [searchable]="true"
        placeholder="Select Facility" required #IssueFacility="ngModel" >
        <ng-option [value]="0">All</ng-option>
        <ng-option [value]="item.id" *ngFor="let item of facilityList">{{item.facilityName}}</ng-option>
      </ng-select>
    
    </div>
  </div>
  <div class="flex-1 mr-2">
    <div class="form-group mb-0">
      <label class="w-100 mb-0">Date </label>
      <input id="assignedDateField"
        class="form-control form-control-sm box-shadow w-100 myClear-custom" type="text" autocomplete="nope" [(ngModel)]="daterange"
         name="daterangeInput" ngxDaterangepickerMd [locale]="options" [alwaysShowCalendars]="false"
        placeholder="Date" (datesUpdated)="selectedDate($event, daterange)"
        [showClearButton]="true" (clearClicked)="clearDate()" />
    </div>
  </div>
  <!-- <div class="flex-1 mr-2">
    <div class="form-group mb-0">
      <label class="w-100 mb-0">Patient </label>
        <ng-select class="ng-select-small" appendTo="body" [(ngModel)]="filterPatientId"
            (ngModelChange)="fillPatientId(filterPatientId)" [loading]="isSearchingPatient"
            [closeOnSelect]="true" [multiple]="false" [searchable]="true" placeholder="Search Patient"
            [typeahead]="searchWatch">
            <ng-option [value]="item.id" *ngFor="let item of patientList">{{item.fullName}}
                <span class="text-dynamic-2c">({{item.patientEmrId}}) - </span><span
                    class="text-dynamic-secondary-2c">{{item.facilityName}}</span>
            </ng-option>
        </ng-select>
    </div>
  </div> -->
  <div class="mr-2">
      <button [disabled]="isLoadingBulkComLogs" (click)="getTempPatientCommunication()" class="d-flex  btn btn-dynamic-2c btn-sm m-0 "  
      > <span *ngIf="isLoadingBulkComLogs" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"> </span>Load</button>
  </div>
  <div>
      <button (click)="resetFilters()" class="d-flex  btn btn-dynamic-secondary-2c btn-sm m-0 ">Reset</button>
  </div>
</div>
<div>
  <mdb-progress-bar *ngIf="isLoadingBulkComLogs" class="progress primary-color-dark-s mb-0" mode="indeterminate"></mdb-progress-bar>
    <ngx-datatable #table class="material" appRecalculateNgxTable  [headerHeight]="50"
    [footerHeight]="50" [rowHeight]="40" [count]="rows.length" [scrollbarV]="false" [scrollbarH]="true"
    [rows]="rows" [offset]="0" [limit]="10">
        <ngx-datatable-column name="#" [maxWidth]=30>
        <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
            {{rowIndex + 1}}
        </ng-template>
        </ngx-datatable-column >
        <ngx-datatable-column name="Campaign Id" prop="campaignNumber" >
        </ngx-datatable-column>
        <ngx-datatable-column name="Method"  >
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ContactMethodEnum[row.method]}}
              </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Date Created" prop="dateCreated" >
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{row.timeStamp | dateFormatPipe: 'time'}} UTC
              </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Messages" >
            <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
                <div style="cursor: pointer;">
                  <span class="badge badge-secondary ml-1" title="Patients Count: {{row.patientsCount}}">{{row.patientsCount}}</span>
                  <span class="badge badge-success ml-1" title="Success Count: {{row.successCount}}">{{row.successCount}}</span>
                  <span class="badge badge-info ml-1" title="pending Count: {{row.pendingCount}}">{{row.pendingCount}}</span>
                  <span class="badge badge-danger ml-1" title="Failed Count: {{row.failedCount}}">{{row.failedCount}}</span>
                </div>
              </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="User" prop="senderName" >
        </ngx-datatable-column>
        <ngx-datatable-column *hasClaim="['IsAppAdmin']" name="Facility" prop="facilityName" >
        </ngx-datatable-column>
        <ngx-datatable-column name="Message" prop="messageTemplate" >
        </ngx-datatable-column>
        <!-- <ngx-datatable-column name="Date" prop="timeStamp">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{row.timeStamp | dateFormatPipe: 'time'}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Retry Count" prop="retryCount"  [width]=100  >
        </ngx-datatable-column>
        <ngx-datatable-column name="Status" prop="status">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{tempPatientCommunicationStatusEnum[row.status]}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Facility Id" prop="facilityId">
        </ngx-datatable-column>
        <ngx-datatable-column name="Sender Name" prop="senderName">
        </ngx-datatable-column>
        <ngx-datatable-column name="Patient Name" prop="patientName">
        </ngx-datatable-column>
        <ngx-datatable-column name="RC Response" prop="rcResponse">
        </ngx-datatable-column> -->
        <ngx-datatable-column name="Action" [canAutoResize]=false [frozenRight]=true [width]="160">
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <div class="action-button">
                <a (click)="OpenCampaignDetail(row)" class="btn btn-dynamic-2c btn-icon" title="View" placement="left"><i class="las fa-eye"></i> </a>
            </div>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
</div>

