import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { ModalDirective, PageScrollService, ToastService } from 'ng-uikit-pro-standard';
import { ECalendarValue, IDatePickerDirectiveConfig } from 'ng2-date-picker';
import { HESMonthlyStatusEnum, HESTypeEnum } from 'src/app/Enums/hes.enum';
import { SubSink } from 'src/app/SubSink';
import { PatientsService } from 'src/app/core/Patient/patients.service';
import { AppDataService } from 'src/app/core/app-data.service';
import { CustomeListService } from 'src/app/core/custome-list.service';
import { DataFilterService } from 'src/app/core/data-filter.service';
import { EmitEvent, EventBusService, EventTypes } from 'src/app/core/event-bus.service';
import { FacilityService } from 'src/app/core/facility/facility.service';
import { HealthScoreService } from 'src/app/core/health-score.service';
import { HesService } from 'src/app/core/hes.service';
import { InsuranceService } from 'src/app/core/insurance.service';
import { SecurityService } from 'src/app/core/security/security.service';
import { TwoCModulesEnum } from 'src/app/model/AppModels/app.model';
import { CreateFacilityUserDto } from 'src/app/model/Facility/facility.model';
import { HESPatientListDto, HesEncounterDto, HesEncounterListDto } from 'src/app/model/Hes/hes.model';
import { PatientDto } from 'src/app/model/Patient/patient.model';
import { HttpResError } from 'src/app/model/common/http-response-error';

@Component({
  selector: 'app-hes-quick-encounter',
  templateUrl: './hes-quick-encounter.component.html',
  styleUrls: ['./hes-quick-encounter.component.scss']
})
export class HesQuickEncounterComponent implements OnInit {
  @Input() doNotSubscribe: boolean;
  @Output() encounterSaved = new EventEmitter<HesEncounterListDto>();
  @ViewChild("hesEncounterModal") hesEncounterModal: ModalDirective;
  disabledAddEncounterButton = true
  addHesEncounterDto = new HesEncounterDto();
  durationNO: number;
  PatientData= new PatientDto();
  isLoadingPayersList: boolean;

  public datePickerConfig2: IDatePickerDirectiveConfig = {
    allowMultiSelect: false,
    returnedValueType: ECalendarValue.StringArr,
    format: "MM/DD/YYYY",
   // appendTo: "body",
  };
  public timePickerConfig: IDatePickerDirectiveConfig = {
    allowMultiSelect: false,
    returnedValueType: ECalendarValue.StringArr,
    format: "hh:mm A"
  };

  facilityUsersList = new Array<CreateFacilityUserDto>();
  serviceTypes =  this.datafilterService.getEnumAsList(HESTypeEnum)
  IsaddingEncounterLoading: boolean;
  facilityId: number;
  selectedPatientData: HESPatientListDto;
  PatientEncounterMonthlyStatusTExt = HESMonthlyStatusEnum[HESMonthlyStatusEnum['Not Started']];
  hesMonthlyStatusEnum = HESMonthlyStatusEnum;
  hesMonthlyStatusEnumList =  this.datafilterService.getEnumAsList(HESMonthlyStatusEnum);
  addressedOptionIds=[];
  stopWatchValue = 0;
  stopWatchInterval: NodeJS.Timeout;
  hesClaims = {
    ChiService : true,
    PinService: true,
    PinPeerService: true
  };
  private subs = new SubSink();
  constructor(
    private datafilterService: DataFilterService,
    private patientService: PatientsService,
    private facilityService: FacilityService,
    private insuranceService: InsuranceService,
    private securityService: SecurityService,
    @Inject(ToastService) private toaster: ToastService,
    private appData: AppDataService,
    private router: Router,
    private eventBus: EventBusService,
    private cdr: ChangeDetectorRef,
    private customListService: CustomeListService,
    private pageScrollService: PageScrollService,
    private route: ActivatedRoute,
    private healthScoreService: HealthScoreService,
    private hesService: HesService,
    private filterDataService: DataFilterService
  ) {}

  ngOnInit() {
    this.hesMonthlyStatusEnumList = this.hesMonthlyStatusEnumList.filter((ms) => ms.value !==  (-1 as any));
    this.serviceTypes = this.serviceTypes.filter((service) => service.value !==  (-1 as any));
    this.facilityId = +this.securityService.getClaim('FacilityId')?.claimValue;
    this.hesClaims.ChiService = this.securityService.hasClaim("ChiService")
    this.hesClaims.PinService = this.securityService.hasClaim("PinService")
    this.hesClaims.PinPeerService = this.securityService.hasClaim("PinPeerService")
    this.getPRCMFacilityUsers()
    this.subscribeOpenModalRequest()
  }
  subscribeOpenModalRequest() {
    if (this.doNotSubscribe) {
      return
    }
    this.eventBus
      .on(EventTypes.OpenHESQuickEncounter)
      .subscribe(
        (res: {
          type: string;
          data: { patient: HESPatientListDto; encounterObj: HesEncounterDto, addressedOptionIds?: any}, config:{hideTimer: boolean}
        }) => {
          if (res.type == EventTypes.OpenHESQuickEncounter.toString()) {
            this.addHesEncounterDto = new HesEncounterDto();
            if(res.data?.addressedOptionIds?.length){
              this.addressedOptionIds = res.data?.addressedOptionIds;
              console.log(this.addressedOptionIds)
            }
            
            // this.addHesEncounterDto.startTime = res.data.encounterObj.startTime;
            // this.addHesEncounterDto.endTime = res.data.encounterObj.endTime;
            // const date1 = moment().format("MM/DD/YYYY");
            // this.addHesEncounterDto.encounterDate = date1;
            // this.durationChanged(+res.data.encounterObj.duration);
            this.addHesEncounterDto.duration = res.data.encounterObj.duration;
            this.addHesEncounterDto.patientCommunicationIds = res.data.encounterObj.patientCommunicationIds || [];
            this.addHesEncounterDto.note = res.data.encounterObj.note;
            this.addHesEncounterDto.monthlyStatus = HESMonthlyStatusEnum['Not Started'];
            if(this.securityService.getClaim('IsCareCoordinator')?.claimValue){
              this.addHesEncounterDto.clinicalCoordinatorId = this.securityService.securityObject.id;
            }
            if(this.securityService.getClaim('IsBillingProvider')?.claimValue){
              this.addHesEncounterDto.billingProviderId = this.securityService.securityObject.id;
            }
            // this.StartTimeChanged()
            this.cdr.detectChanges();
            this.OpenEncounterModal(res.data.patient);
          }
        }
      );
  }
  getPRCMFacilityUsers() {
    // let roleName = "PRCM Care Manager";
    this.isLoadingPayersList = true;
    this.facilityService.getFacilityUserList(this.facilityId).subscribe(
      (res: []) => {
        this.facilityUsersList = res;
        this.isLoadingPayersList = false;
      },
      (error: HttpResError) => {
        this.isLoadingPayersList = false;
        this.toaster.error(error.error, error.message);
      }
    );
  }
  OpenEncounterModal(patient: HESPatientListDto) {
    this.selectedPatientData =  structuredClone(patient);
    this.addHesEncounterDto.patientId = patient?.id;

    this.addHesEncounterDto.encounterDate = moment().format("MM/DD/YYYY");
    const time = moment().format("hh:mm A");
    this.addHesEncounterDto.endTime = time;
    this.addHesEncounterDto.hesType = HESTypeEnum.CHI;
    this.addHesEncounterDto.monthlyStatus = patient.hesMonthlyStatus;
    this.PatientEncounterMonthlyStatusTExt = HESMonthlyStatusEnum[patient.hesMonthlyStatus];
    if(this.securityService.getClaim('IsCareCoordinator')?.claimValue){
      this.addHesEncounterDto.clinicalCoordinatorId = this.securityService.securityObject.id;
    }
    if(this.securityService.getClaim('IsBillingProvider')?.claimValue){
      this.addHesEncounterDto.billingProviderId = this.securityService.securityObject.id;
    }
    this.durationChanged(+this.addHesEncounterDto.duration);
    this.cdr.detectChanges();
    // this.addHesEncounterDto
    this.hesEncounterModal.show();
  }
  OpenEditEncounterModal(encounterDetails: HesEncounterDto){
    this.addHesEncounterDto = encounterDetails;
    this.addHesEncounterDto.encounterDate = moment(encounterDetails.encounterDate, "MM/DD/YYYY").format("MM/DD/YYYY");
    this.getPatientData();
    this.PatientEncounterMonthlyStatusTExt = HESMonthlyStatusEnum[this.addHesEncounterDto.monthlyStatus];
    this.cdr.detectChanges();
    this.hesEncounterModal.show();
  }
  addHesEncounter() {
    this.IsaddingEncounterLoading = true;
    this.addHesEncounterDto.duration = this.durationNO.toString();
    this.addHesEncounterDto.patientId = this.selectedPatientData?.id;
    this.hesService.AddHesEncounter(this.addHesEncounterDto).subscribe(
      (res: HesEncounterListDto) => {
        if(this.addressedOptionIds?.length){
          this.addressedOptions()
        }
        this.toaster.success('Encounter added successfully');
        this.hesEncounterModal.hide();
        this.IsaddingEncounterLoading = false;
        this.encounterSaved.emit(res)
        this.refreshHesPatientsList(this.addHesEncounterDto);
      },
      (error: HttpResError) => {
       this.IsaddingEncounterLoading = false;
        this.toaster.error(error.error, error.message);
      }
    );
  }
  editHesEncounter() {
    this.IsaddingEncounterLoading = true;
    if(this.selectedPatientData?.id){
      this.addHesEncounterDto.patientId = this.selectedPatientData?.id;
    }
    this.hesService.EditHesEncounter(this.addHesEncounterDto).subscribe(
      (res: HesEncounterListDto) => {
        this.toaster.success('Encounter edited successfully');
        this.hesEncounterModal.hide();
        this.IsaddingEncounterLoading = false;
        this.encounterSaved.emit(res)
        this.refreshHesPatientsList(this.addHesEncounterDto);
        this.filterDataService.refreshHesEncounterLogsList.next('');
      },
      (error: HttpResError) => {
       this.IsaddingEncounterLoading = false;
        this.toaster.error(error.error, error.message);
      }
    );
  }
  durationChanged(minsToSubtract: number) {
    const endTime = this.addHesEncounterDto.endTime;
    let startTime = moment(endTime, 'hh:mm A').subtract(minsToSubtract, 'minutes').format('hh:mm A');
    this.addHesEncounterDto.startTime = startTime;
  }
  StartTimeChanged() {
    const startTime = moment(this.addHesEncounterDto.startTime, "hh:mm A");
    const endTime = moment(this.addHesEncounterDto.endTime, "hh:mm A");
    this.durationNO = endTime.diff(startTime, 'minutes') > 0 ? endTime.diff(startTime, 'minutes') : null;
  }
  refreshHesPatientsList(hesEncounterDto: HesEncounterDto){
    const emitObj = new EmitEvent();
    emitObj.name = EventTypes.CommunicationEncounterEdit;
    emitObj.value = {
      type: EventTypes.CommunicationEncounterEdit.toString(),
      data: {
        patientId: hesEncounterDto.patientId,
        patientCommunicationIds: hesEncounterDto.patientCommunicationIds,
        serviceType: TwoCModulesEnum.HES,
        encounterObj: hesEncounterDto
      },
    };
    this.eventBus.emit(emitObj);
  }
  resetEncounterValues(){
    this.addHesEncounterDto = new HesEncounterDto();
    this.disabledAddEncounterButton = true;
    this.IsaddingEncounterLoading = false;
    this.selectedPatientData.id = 0
  }
  addressedOptions(){
    if(this.addressedOptionIds?.length){
      this.healthScoreService.addressedOptions(this.addressedOptionIds).subscribe((res: any) => {
        this.toaster.success('Options Addressed')
      }, (err: HttpResError) => {
        this.toaster.error(err.error);
      })
    }
  }
  getPatientData() {
    if (this.addHesEncounterDto?.patientId) {
      this.subs.sink = this.patientService
        .getPatientDetail(this.addHesEncounterDto?.patientId)
        .subscribe(
          (res: any) => {
            if (res) {
              this.PatientData = res;
              this.addHesEncounterDto.monthlyStatus = this.PatientData.hesMonthlyStatus;
              this.PatientEncounterMonthlyStatusTExt = HESMonthlyStatusEnum[this.PatientData.hesMonthlyStatus];
            }
          },
          (err: HttpResError) => {
            this.toaster.error(err.error);
          }
        );
    }
  }
  callMethods(){
  }
  ResetStopWatch() {
    this.addHesEncounterDto.duration =  moment().startOf('day').seconds(this.stopWatchValue).minutes().toString();
    this.durationNO =  moment().startOf('day').seconds(this.stopWatchValue).minutes();
    if ((this.stopWatchValue % 60) > 0) {
      this.addHesEncounterDto.duration = (+this.addHesEncounterDto.duration + 1).toString();
      this.durationNO =  (+this.durationNO + 1)
      this.durationChanged(this.durationNO);
    }
    if (!+this.addHesEncounterDto.duration) {
      this.addHesEncounterDto.duration = null;
      this.durationNO = null;
    }
    clearInterval(this.stopWatchInterval);
    this.stopWatchInterval = null;
    document.getElementById('stopwatchFieldRPM1')?.setAttribute('value','');
  }  
  startStopWatch() {
    this.stopWatchValue = 0;
    this.stopWatchInterval  = setInterval(() => {
      ++this.stopWatchValue;
      const result = moment().startOf('day').seconds(this.stopWatchValue).format('HH:mm:ss');
      document.getElementById('stopwatchFieldRPM1').setAttribute('value',result);
    }, 1000);
  }
}
