<div class="not-found">
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" width="200" viewBox="0 0 124.04688 121.66602">
      <defs>
        <style>
          .cls-1 {
            fill: none;
          }

          .cls-2 {
            fill: #375a64;
          }
        </style>
      </defs>
      <title>Asset 2</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path class="cls-1"
            d="M31.10352,15.4541a2.43949,2.43949,0,0,0,.708-1.76025L31.64935,1.72546,17.76874,16.186H29.353A2.441,2.441,0,0,0,31.10352,15.4541Z" />
          <path class="cls-1"
            d="M102.61523,1H32.63971l.17181,12.68018A3.45875,3.45875,0,0,1,29.353,17.186H17.0957v99.144a4.34114,4.34114,0,0,0,4.33594,4.33594h81.18359a4.34124,4.34124,0,0,0,4.33643-4.33594V5.33594A4.34123,4.34123,0,0,0,102.61523,1ZM77.68506,30.646a2.75488,2.75488,0,1,1-2.75488,2.75488A2.758,2.758,0,0,1,77.68506,30.646ZM73.332,44.07031l-.45215.89258c-6.74463-3.417-12.38769-4.51074-21.25732-.001l-.45313-.89062C60.417,39.36523,66.29834,40.50879,73.332,44.07031ZM47.70654,30.82715A2.75488,2.75488,0,1,1,44.95166,33.582,2.758,2.758,0,0,1,47.70654,30.82715ZM33.543,74.44531H77.68506v1H33.543Zm0,10.168H90.15381v1H33.543Zm63.57275,11.168H33.71826v-1H97.11572Z" />
          <path class="cls-2"
            d="M72.87988,44.96289l.45215-.89258c-7.03369-3.56152-12.915-4.70508-22.1626.001l.45313.89062C60.49219,40.45215,66.13525,41.5459,72.87988,44.96289Z" />
          <circle class="cls-2" cx="47.70654" cy="33.58203" r="2.75488" />
          <circle class="cls-2" cx="77.68506" cy="33.40088" r="2.75488" />
          <rect class="cls-2" x="33.54297" y="74.44531" width="44.14209" height="1" />
          <rect class="cls-2" x="33.54297" y="84.61328" width="56.61084" height="1" />
          <rect class="cls-2" x="33.71826" y="94.78125" width="63.39746" height="1" />
          <path class="cls-2"
            d="M107.95166,46.36523V5.33594A5.34222,5.34222,0,0,0,102.61523,0H31.91992L16.0957,16.48486V46.3653A17.08247,17.08247,0,0,0,0,35.33691v1c8.875,0,16.0957,6.97657,16.0957,15.55274v64.44043a5.34181,5.34181,0,0,0,5.33594,5.33594h81.18359a5.34223,5.34223,0,0,0,5.33643-5.33594V51.88965c0-8.57617,7.22022-15.55274,16.09522-15.55274v-1A17.08215,17.08215,0,0,0,107.95166,46.36523ZM31.64935,1.72546l.16217,11.96839A2.45839,2.45839,0,0,1,29.353,16.186H17.76874Zm75.30231,114.60462a4.34124,4.34124,0,0,1-4.33643,4.33594H21.43164a4.34114,4.34114,0,0,1-4.33594-4.33594V17.186H29.353a3.45875,3.45875,0,0,0,3.45849-3.50586L32.63971,1h69.97552a4.34123,4.34123,0,0,1,4.33643,4.33594V116.33008Z" />
        </g>
      </g>
    </svg>
    <p class="text-dark align-items-center font-400 mt-3 text-center "> No record found !</p>
  </div>
</div>
