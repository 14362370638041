<div style="overflow-y:auto" mdbModal #rpmEncounterModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
id="AlertModal" aria-labelledby="myBasicModalLabel" aria-hidden="true" (open)="rpmEncounterModalOpened()" (closed)="ResetStopWatch()">
<div class="modal-dialog modal-dialog-scrollable modal-dialog-centered flat-modal p-4" style="max-width:650px ;" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="rpmEncounterModal.hide()">
        <span aria-hidden="true">×</span>
      </button>
      <h4 class="text-dynamic-2c modal-title font-500 mr-3">Add Rpm Encounter</h4>
    </div>
    <div class="modal-body">
      <div class="encounter-body">
        <div class="row">

          <div class="col-sm-6">
            <div class="form-group">
              <label>Service Name<sup class="text-danger">*</sup></label>
              <ng-select [searchable]="true"
                class="ng-select-small" placeholder="Select Service Type" [(ngModel)]="rpmEncounterDto.rpmServiceType" (click)="$event.stopPropagation();">
                <ng-option *ngFor="let item of rpmServiceTypesEnumList" [value]="item.value">{{item.name}}</ng-option>

              </ng-select>
            </div>
          </div>

          <div class="col-sm-6 pl-sm-0">
            <div class="input-group">
              <label class="w-100">Date<sup class="text-danger">*</sup></label>
              <input type="text" class="form-control form-control-sm" #dateDPicker="dpDayPicker"
                 [dpDayPicker]="datePickerConfig1" [(ngModel)]="rpmEncounterDto.encounterDate"
                [theme]="'dp-material ccm-date-picker'" [mode]="'daytime'" appDateTimeValidator="datetime" />
                <a class="input-group-append">
                  <span class="input-group-text text-white bg-dynamic-2c" id="category">
                    <i class="las la-calendar fa-lg"></i>
                  </span>
                </a>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 ">
                <div class="form-group">
                  <label class="w-100">Start Time<sup class="text-danger">*</sup></label>
                  <input type="text" [readOnly]="IsPatientEngagementManager" class="form-control form-control-sm" #dateDpTime="dpDayPicker"
                    [dpDayPicker]="timePickerConfig" [(ngModel)]="rpmEncounterDto.startTime"
                    [theme]="'dp-material ccm-date-picker'" [mode]="'time'" appDateTimeValidator="time"  />
                  <!-- <a (click)="dateDpTime.api.open()" class="input-group-append">
                <span class="input-group-text text-white bg-dynamic-2c" id="category"><i
                    class="las la-clock-o fa-lg"></i></span>
              </a> -->
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 pl-sm-0">
                <div class="form-group">
                  <label class="w-100">End Time<sup class="text-danger">*</sup></label>
                  <input type="text" readonly class="form-control form-control-sm" [(ngModel)]="rpmEncounterDto.endTime" >
                  <!-- <input type="text" readonly class="form-control form-control-sm" #dateDpEndTime="dpDayPicker"
                  [dpDayPicker]="timePickerConfig" formControlName="endTime" [theme]="'dp-material ccm-date-picker'"
                  [mode]="'time'" appDateTimeValidator="time"  />
                <a (click)="dateDpEndTime.api.open()" class="input-group-append">
                  <span class="input-group-text text-white bg-dynamic-2c" id="category"><i
                      class="las la-clock-o fa-lg"></i></span>
                </a> -->
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6 pl-sm-0">
            <div class="d-flex w-100">
              <div class="form-group flex-grow-1">
                <label class="w-100">Duration<sup class="text-danger">*</sup></label>
                <input #durationInput [readOnly]="IsPatientEngagementManager" type="text" placeholder="In Minutes" [(ngModel)]="rpmEncounterDuration" (input)="durationChanged($event.target.value)"
                  class="form-control form-control-sm" OnlyNumbers >
                <!-- <a (click)="dateDpEndTime.api.open()" class="input-group-append">
                <span class="input-group-text text-white bg-dynamic-2c" id="category"><i
                    class="las la-clock-o fa-lg"></i></span>
              </a> -->
              </div>
              <div *ngIf="!hideTimerForChatEncounter" class="form-group ml-3 flex-grow-1">
                <label for="endTime">Timer</label>
                <div class="d-flex">
                  <input type="text" id="stopwatchFieldRPM2"
                  class="form-control form-control-sm text-dynamic-secondary-2c" placeholder="00:00:00">
                  <button *ngIf="stopWatchInterval" class="btn btn-dynamic-2c my-0 mr-0 px-2 btn-sm " (click)="ResetStopWatch()">Stop </button>
                  <button *ngIf="!stopWatchInterval" class="btn btn-dynamic-2c my-0 mr-0 px-2 btn-sm " (click)="startStopWatch()">Start </button>

                </div>

              </div>
            </div>
          </div>

          <div class="col-sm-12">
            <div class="form-form form-group" *ngIf="rpmEncounterDto.patientId || patient?.id">
              <label for="form12">Notes<sup class="text-danger">*</sup></label>
              <!-- <textarea type="text" id="form12" rows="6" class="form-control" [(ngModel)]="rpmEncounterDto.note" ></textarea> -->
              <app-two-c-text-area #myFIeldRefRPM [value]="rpmEncounterDto.note" [height]="'140px'" [PatientId]="rpmEncounterDto.patientId || patient?.id" (valueChanged)="rpmEncounterDto.note=$event"></app-two-c-text-area>
          </div>
          <!-- <div class="d-flex align-items-center">
            <div class="custom-control custom-checkbox w-info-90">
              <input type="checkbox" [disabled]="!rpmEncounterDto.billingProviderId"
                [(ngModel)]="rpmEncounterDto.isProviderRpm" class="custom-control-input" id="cptcheck">
              <label class="custom-control-label" for="cptcheck">CPT 99091</label>
            </div>
            <a id="cpt99091" class="ml-3" container="body" [mdbPopover]="cpt99091" placement="bottom"
              triggers="focus"><i class="las la-exclamation-circle text-success fa-lg"></i></a>
            <ng-template #cpt99091>
              <div class="bg-dark text-white py-3 pl-3 pr-2">
                <div class="who-covered" (click)="$event.stopPropagation()" [innerHTML]="rpmEncounterCovered"
                  [style.overflow-y]="'auto'" style="max-height: 300px;"></div>
              </div>
            </ng-template>

          </div> -->
          </div>
        </div>
      </div>
      <div *ngIf="rpmEncounterDto.id === 0 && (!patientEncounterMonthlyStatusAcknowledge || (selectedPatient.rpmMonthlyStatus === rpmMonthlyStatusEnum['Not Started']))" class="d-flex justify-content-between alert alert-warning alert-dismissible animated fade show" role="alert">
        <strong >Acknowledge/update monthly status to save</strong>
      </div>
    </div>

    <div class="modal-footer d-flex justify-content-between align-items-center">
      <div>
        <div *ngIf="rpmEncounterDto.id === 0" class=" d-flex justify-content-between mb-2 align-items-center">
          <p class="m-0"><strong> Monthly Status: </strong></p>
          <div style="padding: 2px;" class=" ml-3 border rounded" [class.border-success]="patientEncounterMonthlyStatusAcknowledge && (PatientEncounterMonthlyStatus !== rpmMonthlyStatusEnum['Not Started'])"
          [class.border-warning]="!patientEncounterMonthlyStatusAcknowledge || (PatientEncounterMonthlyStatus === rpmMonthlyStatusEnum['Not Started'])">
            <div class="btn-group cursor-pointer"  mdbDropdown>
              <mdb-badge style="line-height: 14px;width: 120px;"  mdbDropdownToggle success="true" (click)="patientEncounterMonthlyStatusAcknowledge=true;">{{PatientEncounterMonthlyStatusTExt}}</mdb-badge>

              <div style="bottom: 50px;top: auto;right: -4px;" class="dropdown-menu dropdown-menu-right status-dropdown">
                <div>
                  <a (click)="patientEncounterMonthlyStatusAcknowledge=true;rpmEncounterDto.rpmMonthlyStatus = item.value;PatientEncounterMonthlyStatus=item.value;PatientEncounterMonthlyStatusTExt=item.name;" *ngFor="let item of rpmMonthlyStatusEnumList" class="dropdown-item">{{item.name}}</a>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
      <div>
        <button type="button" (click)="rpmEncounterModal.hide()" aria-label="Close" class="waves-light btn-sm btn-dynamic-secondary-2c btn" >Close</button>
        <button type="button" [disabled]="(!rpmEncounterDuration && !IsPatientEngagementManager) || !rpmEncounterDto.note || !patientEncounterMonthlyStatusAcknowledge ||!rpmEncounterDto.encounterDate || isAddEncounter || rpmEncounterDto.rpmServiceType == null" (click)="addRpmEncounter(rpmEncounterModal)" class="relative waves-light btn-sm btn-dynamic-2c btn">
          <span *ngIf="isAddEncounter" class="spinner-border spinner-border-sm" role="status"
          aria-hidden="true"></span>Add Encounter</button>
      </div>
    </div>
  </div>
</div>
</div>
