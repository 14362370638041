import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpErrorHandlerService } from '../shared/http-handler/http-error-handler.service';
import { catchError } from 'rxjs';
import { NCADtos } from '../model/nca.models';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    //
  })
};
@Injectable({
  providedIn: 'root'
})
export class NeurocognitiveService {
  private baseUrl = localStorage.getItem('switchLocal') ? environment.localBaseUrl : environment.baseUrl;
  constructor(private http: HttpClient, private httpErrorService: HttpErrorHandlerService) { }
  AddNeuroCognitiveSession(rData: NCADtos.AddNeuroCognitiveSessionDto) {
    return this.http.post(this.baseUrl + `NeuroCognitiveAssessments/AddNeuroCognitiveSession`, rData , httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  AddNeuroCognitiveTest(rData: NCADtos.AddNeuroCognitiveTestDto) {
    return this.http.put(this.baseUrl + `NeuroCognitiveAssessments/AddNeuroCognitiveTest`, rData , httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  updateNeuroCognitiveStatus(updateNeuroCognitiveStatusDto: NCADtos.UpdateNeuroCognitiveStatusDto){
    return this.http.put(this.baseUrl + `Patients/UpdateNeuroCognitiveStatus`,updateNeuroCognitiveStatusDto, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  getNeuroCognitivePatients(neuroCognitivePatientsDto: NCADtos.NeuroCognitiveFilterPatientsDto){
    return this.http.get(this.baseUrl + `NeuroCognitiveAssessments/GetNeuroCognitivePatients?facilityId=${neuroCognitivePatientsDto.facilityId}&sessionDateFrom=${neuroCognitivePatientsDto.sessionDateFrom || ''}&sessionDateTo=${neuroCognitivePatientsDto.sessionDateTo || ''}&customListId=${neuroCognitivePatientsDto.customListId || 0}&filterBy=${neuroCognitivePatientsDto.filterBy}&searchParam=${neuroCognitivePatientsDto.searchParam || ''}&cognitiveStatus=${neuroCognitivePatientsDto.cognitiveStatus}&sessionStatus=${neuroCognitivePatientsDto.sessionStatus}`, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  getCognitiveSessionsByPatientId(patientId: number){
    return this.http.get(this.baseUrl + `NeuroCognitiveAssessments/GetCognitiveSessionsByPatientId/${patientId}`, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  GetCognitiveSessionById(sessionId: number){
    return this.http.get(this.baseUrl + `NeuroCognitiveAssessments/GetCognitiveSessionById/${sessionId}`, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  addReportDownloadedDate(rData: NCADtos.AddReportDownloadedDateDto) {
    return this.http.put(this.baseUrl + `NeuroCognitiveAssessments/AddReportDownloadedDate`, rData , httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  getCognitiveSessionByFacilityId(facilityId: number, monthId: number, yearId: number){
    return this.http.get(this.baseUrl + `NeuroCognitiveAssessments/GetCognitiveSessionByFacilityId/${facilityId}?month=${monthId}&year=${yearId}`, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
}
