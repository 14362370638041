
<div class="panel mt-3">
  <div class="panel-header">
    <div class="d-flex align-items-center justify-content-between">
      <div>
        <h3 class="mb-0" style="font-size: 18px;font-weight: 600;">Modality Data</h3>
      </div>
      <div>
        <div class="d-flex justify-content-end flex-wrap align-items-center">
          <div class="flex-grow-0 mb-0" *ngIf="devicesList && devicesList.length">
            <div class="custom-control custom-checkbox w-info-90">
              <input type="checkbox" [(ngModel)]="includeEncounters" class="custom-control-input" id="includeEncounters">
              <label class="custom-control-label" style="white-space: nowrap;font-size: 13px;" for="includeEncounters">Include
                Encounters</label>
            </div>

          </div>

          <div class="flex-grow-0 ml-2 mb-0">
            <div class="month">
              <ng-select [searchable]="false" [appendTo]="'body'" [(ngModel)]="rpmMonthId"
                (ngModelChange)="getDeviceDisplayData('monthYearSelected')" class=" ng-select-small" [clearable]="false"
                placeholder="Select Month" (click)="$event.stopPropagation();" style="min-width: 110px;">
                <!-- <ng-option [value]="0">Default</ng-option> -->
                <ng-option [value]="1">January</ng-option>
                <ng-option [value]="2">February</ng-option>
                <ng-option [value]="3">March</ng-option>
                <ng-option [value]="4">April</ng-option>
                <ng-option [value]="5">May</ng-option>
                <ng-option [value]="6">June</ng-option>
                <ng-option [value]="7">July</ng-option>
                <ng-option [value]="8">August</ng-option>
                <ng-option [value]="9">September</ng-option>
                <ng-option [value]="10">October</ng-option>
                <ng-option [value]="11">November</ng-option>
                <ng-option [value]="12">December</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="month d-flex align-items-center ml-2 mb-0">
            <ng-select [searchable]="false" [appendTo]="'body'" class="flex-1 ng-select-small" placeholder="Select Year"
              [(ngModel)]="yearNum" [clearable]="false" (ngModelChange)="getDeviceDisplayData('monthYearSelected')"
              (click)="$event.stopPropagation();" style="min-width: 60px;">
              <ng-option *ngFor="let year of listOfYears" [value]="year">{{year}}</ng-option>
            </ng-select>
            <div *ngIf="devicesList && devicesList.length" class="ml-auto">
              <button class="btn px-3 my-0 collapse-btn btn-dynamic-2c btn-icon mr-0" title="copy" (click)="CopyModalitiesData()">
                <i *ngIf="!gettingRPMCopyData" class="las la-clipboard"></i>
                <span *ngIf="gettingRPMCopyData" class="spinner-border spinner-border-sm" role="status"
                  aria-hidden="true"></span>
              </button>
            </div>
            <div  class="ml-auto">
              <button class="btn px-1 mr-0 my-0 collapse-btn btn-dynamic-2c btn-icon" title="Download Careplan" (click)="downloadCarePlan()">
                <i *ngIf="!gettingCarePlan" class="las la-download fa-lg"></i>
                <span *ngIf="gettingCarePlan" class="spinner-border spinner-border-sm" role="status"
                  aria-hidden="true"></span>
              </button>
            </div>
          </div>
          <div class="d-flex justify-content-end mb-0 ml-2">
            <div class="device-status d-flex">
              <div class="total-reading d-flex" *ngIf="selectedModalityCode !== 'CGM'">
                <small class="text-nowrap mr-2 ">Total Reading:</small>
                <p style="font-size:14px;">
                  <strong *ngIf="selectedModalityCode == 'WT'"
                    [class.text-dynamic-2c]="wtReadingDayCount > 15">{{wtReadingDayCount ? wtReadingDayCount : '0'}}
                    Days</strong>
                  <strong *ngIf="selectedModalityCode == 'BP'"
                    [class.text-dynamic-2c]="bpReadingDayCount > 15">{{bpReadingDayCount ? bpReadingDayCount : '0'}}
                    Days</strong>
                  <strong *ngIf="selectedModalityCode == 'PO'"
                    [class.text-dynamic-2c]="pulseOximetryDataListForTotalReading && (pulseOximetryDataListForTotalReading.length > 15)">{{pulseOximetryDataListForTotalReading ? pulseOximetryDataListForTotalReading.length : '0'}} Days</strong>
                  <strong *ngIf="selectedModalityCode == 'AT'"
                    [class.text-dynamic-2c]="activityDataListForTotalReading && (activityDataListForTotalReading.length > 15)">{{activityDataListForTotalReading ?
                    activityDataListForTotalReading.length : '0'}} Days</strong>
                  <strong *ngIf="selectedModalityCode == 'BG'"
                    [class.text-dynamic-2c]="bgReadingDayCount > 15">{{bgReadingDayCount ? bgReadingDayCount : '0'}}
                    Days</strong>
                </p>
              </div>
              <div class="total-durations d-flex">
                <small class="text-nowrap mr-2">Total Time:</small>
                <p style="font-size:14px;"><strong [class.text-dynamic-2c]="encounterMinutes > 19"
                    *ngIf="encounterMinutes">{{encounterMinutes}} minutes</strong></p>
              </div>
            </div>
          </div>
          <div class="custom-control custom-switch ml-2">
            <input type="checkbox" class="custom-control-input" id="customSwitches" [(ngModel)]="isBluetoothEnabled" (ngModelChange)="checkBluetooth()">
            <label class="custom-control-label" for="customSwitches">Bluetooth</label>
          </div>
        </div>
      </div>

      <!-- <a (click)="Open4GDeviceModal(modalityAlertModal11)" title="Add 4G Device"
        class="btn btn-sm btn-dynamic-2c ml-2 m-0">Add 4G Device</a> -->
    </div>

  </div>
  <div class="panel-body py-3">
    <div class="row justify-content-center">

      <div class="col-lg-12">
        <div appCcmLoader class="loader-wrap" *ngIf="isLoading">
          <div class="spinner-border text-dynamic-secondary-2c ccm-loader" role="status">
          </div>
        </div>
        <!-- <p class="text-center" > -->
        <span class="alert alert-warning " *ngIf="!isLoading && (!devicesList || !devicesList.length)" role="alert">
          <strong> No Devices configured !</strong>
          <!-- No Data Found -->
        </span>
        <!-- </p> -->


        <div class="border-0">
          <div class="modality-info p-0">
            <div class="modality-list">
              <ng-container *ngFor="let item of devicesList" >
              <div *ngIf="item.lastReading || item.id !=0" title="Serial No.{{item.serialNo}}" class="item position-relative active my-2"
                [class.active]="selectedModalityCode === item.modality"
                (click)="selectedModalityCode = item.modality;selectGraph()">
                <span class="dot4g" *ngIf="item.id == 0" ></span>
                <span class="dot4gSuccess" *ngIf="item.id !=0" ></span>
                <div class="flex-1 d-flex align-items-center" style="margin: 0 10px;">
                  <img src="/assets/rpm-devices/mbp.svg" *ngIf="item.modality == 'BP'" alt="Blood Pressure">
                  <img src="/assets/rpm-devices/mw.svg" *ngIf="item.modality == 'WT'" alt="Weight">
                  <img src="/assets/rpm-devices/pulse.svg" *ngIf="item.modality == 'PO'" alt="Pulse Oximetry">
                  <img src="/assets/rpm-devices/mbg.svg" style="width: 32px;" *ngIf="item.modality == 'BG'"
                    alt="Blood Glucose">
                  <img src="/assets/rpm-devices/activity.svg" *ngIf="item.modality == 'AT'" alt="Activity">
                  <img src="/assets/rpm-devices/mcgm.svg" *ngIf="item.modality == 'CGM'" alt="Continuous Blood Glucose">
                  <p *ngIf="item.modalityName && item.modality !== 'CGM'"><strong>{{item.modalityName}}</strong></p>
                  <p *ngIf="item.modality == 'CGM'"><strong>Continuous Glucose Monitoring</strong></p>
                </div>
                <a *ngIf="item.modality !== 'CGM'" mdbTooltip="Alert Setting"
                  (click)="deviceClicked(item, modalityAlertModal)" class="device device-active"><i
                    class="las la-bell fa-1x"></i></a>
                <a *ngIf="item.modality !== 'CGM' && !hideModalitiesDetail" mdbTooltip="Modality Details"
                  (click)="GetRPMDeviceConfiguration(item.modality);CheckUnbilledDeviceConfigClaim();modalityAlertModal11.show()"
                  class="device device-active"><i class="las la-edit fa-1x"></i></a>
                  <p *ngIf="item.modality == 'CGM'" style="position: absolute;
                  font-size: 10px;
                  left: -16px;
                  bottom: -20px;
                  color: red;">*CGM single Glucose value may be daily average.</p>
              </div>
            </ng-container>
              <!-- <div class="item">
              <img src="/assets/rpm-devices/mbg.svg" alt="Blood Glucose">
              <p><strong> Blood Glucose</strong></p>
              <a (click)="modalityAlertModal.show();" class="ml-2 device device-alert" mdbTooltip="Alert Setting"
                placement="top"><i class="text-danger las laexclamation-triangle fa-1x"></i></a>
            </div>

            <div class="item">
              <img src="/assets/rpm-devices/mw.svg" alt="Weight">
              <p><strong>Weight</strong></p>
              <a (click)="modalityAlertModal.show();" class="ml-2 device device-alert" mdbTooltip="Alert Setting"
                placement="top"><i class="text-danger las laexclamation-triangle fa-1x"></i></a>
            </div> -->
            </div>



          </div>
        </div>
      </div>
      <!-- <div class="col-lg-5">
        <div class="d-flex justify-content-end flex-wrap align-items-center">
          <div class="flex-grow-0 mb-2" *ngIf="devicesList && devicesList.length">
            <div class="custom-control custom-checkbox w-info-90">
              <input type="checkbox" [(ngModel)]="includeEncounters" class="custom-control-input" id="includeEncounters">
              <label class="custom-control-label" style="white-space: nowrap;font-size: 13px;" for="includeEncounters">Include
                Encounters</label>
            </div>

          </div>

          <div class="flex-grow-0 ml-2 mb-2">
            <div class="month">
              <ng-select [searchable]="false" [appendTo]="'body'" [(ngModel)]="rpmMonthId"
                (ngModelChange)="getDeviceDisplayData()" class=" ng-select-small" [clearable]="false"
                placeholder="Select Month" (click)="$event.stopPropagation();" style="min-width: 110px;">
                <ng-option [value]="1">January</ng-option>
                <ng-option [value]="2">February</ng-option>
                <ng-option [value]="3">March</ng-option>
                <ng-option [value]="4">April</ng-option>
                <ng-option [value]="5">May</ng-option>
                <ng-option [value]="6">June</ng-option>
                <ng-option [value]="7">July</ng-option>
                <ng-option [value]="8">August</ng-option>
                <ng-option [value]="9">September</ng-option>
                <ng-option [value]="10">October</ng-option>
                <ng-option [value]="11">November</ng-option>
                <ng-option [value]="12">December</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="month d-flex align-items-center ml-2 mb-2">
            <ng-select [searchable]="false" [appendTo]="'body'" class="flex-1 ng-select-small" placeholder="Select Year"
              [(ngModel)]="yearNum" [clearable]="false" (ngModelChange)="getDeviceDisplayData()"
              (click)="$event.stopPropagation();" style="min-width: 60px;">
              <ng-option *ngFor="let year of listOfYears" [value]="year">{{year}}</ng-option>
            </ng-select>
            <div *ngIf="devicesList && devicesList.length" class="ml-auto">
              <button class="btn px-3 my-0 collapse-btn btn-dynamic-2c btn-icon" title="copy" (click)="CopyModalitiesData()">
                <i *ngIf="!gettingRPMCopyData" class="las la-clipboard"></i>
                <span *ngIf="gettingRPMCopyData" class="spinner-border spinner-border-sm" role="status"
                  aria-hidden="true"></span>
              </button>
            </div>
            <div  class="ml-auto">
              <button class="btn px-1 mx-0 my-0 collapse-btn btn-dynamic-2c btn-icon" title="Download Careplan" (click)="downloadCarePlan()">
                <i *ngIf="!gettingCarePlan" class="las la-download fa-lg"></i>
                <span *ngIf="gettingCarePlan" class="spinner-border spinner-border-sm" role="status"
                  aria-hidden="true"></span>
              </button>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end mt-2">
          <div class="device-status">
            <div class="total-reading" *ngIf="selectedModalityCode !== 'CGM'">
              <small class="text-nowrap">Total Reading</small>
              <p>
                <strong *ngIf="selectedModalityCode == 'WT'"
                  [class.text-dynamic-2c]="wtReadingDayCount > 15">{{wtReadingDayCount ? wtReadingDayCount : '0'}}
                  Days</strong>
                <strong *ngIf="selectedModalityCode == 'BP'"
                  [class.text-dynamic-2c]="bpReadingDayCount > 15">{{bpReadingDayCount ? bpReadingDayCount : '0'}}
                  Days</strong>
                <strong *ngIf="selectedModalityCode == 'PO'"
                  [class.text-dynamic-2c]="pulseOximetryDataList && (pulseOximetryDataList.length > 15)">{{pulseOximetryDataList ? pulseOximetryDataList.length : '0'}} Days</strong>
                <strong *ngIf="selectedModalityCode == 'AT'"
                  [class.text-dynamic-2c]="activityDataList && (activityDataList.length > 15)">{{activityDataList ?
                  activityDataList.length : '0'}} Days</strong>
                <strong *ngIf="selectedModalityCode == 'BG'"
                  [class.text-dynamic-2c]="bgReadingDayCount > 15">{{bgReadingDayCount ? bgReadingDayCount : '0'}}
                  Days</strong>
              </p>
            </div>
            <div class="total-durations">
              <small class="text-nowrap">Total Time</small>
              <p><strong [class.text-dynamic-2c]="encounterMinutes > 19"
                  *ngIf="rpmEncounterTime">{{rpmEncounterTime.duration}}</strong></p>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class=" mb-3 p-0 col custom-icon-divice" *ngFor="let item of devicesList">
        <div class="device-item" [class.active]="selectedModalityCode === item.modalityCode"
          (click)="selectedModalityCode = item.modalityCode;selectGraph()">

          <div class="device-check d-flex justify-content-between ">
            <mdb-checkbox [checked]="item.isGraphDisplay" (change)="deviceStatusChanged($event.checked, item)"
              [default]="true" [checkboxPosition]="'left'"></mdb-checkbox>
            <a (click)="openConfirmModal(item.id)" class="remove-device" mdbTooltip="Remove" data-placement="top"
              container="body"><i class="las la-trash"></i></a>
          </div>
          <div class="text-center">
            <div class="img-block-con">
              <img src="/assets/rpm-devices/bp.svg" *ngIf="item.modalityCode == 'BP'" alt="Blood Pressure">
              <img src="/assets/rpm-devices/weight.svg" *ngIf="item.modalityCode == 'WT'" alt="Weight">
              <img src="/assets/rpm-devices/pulse.svg" *ngIf="item.modalityCode == 'PO'" alt="Pulse Oximetry">
              <img src="/assets/rpm-devices/sugar.svg" style="width: 32px;" *ngIf="item.modalityCode == 'BG'"
                alt="Blood Glucose">
              <img src="/assets/rpm-devices/activity.svg" *ngIf="item.modalityCode == 'AT'" alt="Activity">
            </div>

            <div class="device-desc" *ngIf="item.deviceName">
              <strong>{{item.deviceName}}</strong>

            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div class="card mt-3">
      <div class="card-header">
        <div class="flex-grow-0">
          <div class="month">
            <ng-select [searchable]="false" [appendTo]="'body'" [(ngModel)]="selectedDuration" (ngModelChange)="getDeviceDisplayData('isDurationSelected');getRpmModalityStatistics()"
               class=" ng-select-small" [clearable]="false"
              placeholder="Select Duration"  style="min-width: 200px;width: 200px;margin-left: auto;">
              <ng-option [value]="30">30 Days</ng-option>
              <ng-option [value]="60">60 Days</ng-option>
              <ng-option [value]="90">90 Days</ng-option>
              <ng-option [value]="180">180 Days</ng-option>
              <ng-option [value]="360">360 Days</ng-option>
            </ng-select>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div *ngIf="selectedModalityCode == 'BP' && BPDeviceDataList && BPDeviceDataList.length" class="row">
          <div class="col-xl-4 pb-3 border-right border-left border-bottom border-top pt-3 mt-1" *ngIf="(selectedModalityCode == 'WT' && weightDataList && weightDataList.length)
          || (selectedModalityCode == 'BP' && BPDeviceDataList && BPDeviceDataList.length)
          || (selectedModalityCode == 'PO' && pulseOximetryDataList && pulseOximetryDataList.length)
          || (selectedModalityCode == 'AT' && activityDataList && activityDataList.length)
          || (selectedModalityCode == 'BG' && BGDeviceDataList && BGDeviceDataList.length)">
            <div >

              <div *ngIf="selectedModalityCode == 'BP'">
                <table class="table table-bordered table-hover mb-0 reading-rpm-table">
                  <colgroup>
                    <col width="40.33%">
                    <col width="33.33%">
                    <col width="27.33%">
                  </colgroup>
                  <thead class="bg-dynamic-2c text-white">
                    <tr>
                      <th>Date</th>
                      <th title="Blood Pressure">SYS / DIA</th>
                    <th title="Heart Rate">Heart Rate</th>
                    </tr>
                  </thead>

                </table>
              </div>
              <div style="max-height: 430px;" *ngIf="selectedModalityCode == 'BP'">
                <div  style="overflow-y: auto;max-height:430px;">
                  <table class="table table-bordered table-hover reading-rpm-table">
                    <colgroup>
                      <col width="40.33%">
                      <col width="33.33%">
                      <col width="27.33%">
                    </colgroup>
                    <tbody >
                      <tr *ngIf="!BPDeviceDataList || !BPDeviceDataList.length">
                        <td>No Record Found</td>
                      </tr>
                      <tr *ngFor="let bpitem of BPDeviceDataList">
                        <td>
                          <i *hasClaim="'CanModifyRPMEncounter'" mdbTooltip="Edit Date" (click)="OpenEditReadingDateModal(bpitem, editDateModal)" 
                          class="las la-calendar text-dynamic-secondary-2c mr-0" style="font-size: 14px;" placement="right"></i>
                            {{bpitem.measurementDate}}
                        </td>
                        <td>
                          <p class="text-center mb-0"><strong>{{bpitem.highPressure | number: '1.0-0'}}/{{bpitem.lowPressure | number: '1.0-0'}}</strong>&nbsp;<span style="font-size:10px;">(mmHg)</span><span class="rpm-condition-dot" 
                          [ngClass]="{
                            'bg-success': bpitem.lowPressure >= 60 && bpitem.lowPressure <= 90 && bpitem.highPressure >= 90 && bpitem.highPressure <= 140,
                            'bg-warning': bpitem.lowPressure >= 90 && bpitem.lowPressure <= 100 && bpitem.highPressure >= 140 && bpitem.highPressure <= 160,
                            'bg-danger': bpitem.lowPressure > 100 && bpitem.highPressure > 160
                          }"></span></p>
                        </td>
                        <td><p class="text-center mb-0"><strong>{{bpitem.heartRate}}</strong> <span style="font-size:10px;">(BPM)</span> <span class="rpm-condition-dot" 
                          [ngClass]="{
                            'bg-success': bpitem.heartRate >= 60 && bpitem.heartRate <= 100,
                            'bg-warning': bpitem.heartRate > 100 && bpitem.heartRate <= 120,
                            'bg-danger': bpitem.heartRate > 120
                          }"></span></p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>


              <!-- OLD -->
              <div *ngIf="!selectedModalityCode == 'BP'">
                <table class="table table-bordered table-hover mb-0 reading-rpm-table">
                  <colgroup>
                    <col width="50%">
                    <col width="50%">
                  </colgroup>
                  <thead class="bg-dynamic-2c text-white">
                    <tr>
                      <th>Date</th>
                      <th>Reading</th>
                    </tr>
                  </thead>
      
                </table>
              </div>
      
              <div style="max-height: 430px;"  *ngIf="!selectedModalityCode == 'BP'">
                <div  style="overflow-y: auto;max-height:430px;">
                  <table class="table table-bordered table-hover reading-rpm-table">
                    <colgroup>
                      <col width="50%">
                      <col width="50%">
                    </colgroup>
                    <tbody *ngIf="selectedModalityCode == 'WT'">
                      <tr *ngIf="!weightDataList || !weightDataList.length">
                        <td>No Record Found</td>
                      </tr>
                      <tr *ngFor="let witem of weightDataList">
                        <td>{{witem.measurementDate | dateFormatPipe: 'time'}}</td>
                        <td>Wt: {{witem.weightValue}}</td>
                      </tr>
                    </tbody>
                    <!-- <tbody *ngIf="selectedModalityCode == 'BP'">
                      <tr *ngIf="!BPDeviceDataList || !BPDeviceDataList.length">
                        <td>No Record Found</td>
                      </tr>
                      <tr *ngFor="let bpitem of BPDeviceDataList">
                        <td> <a class="" >
                          <i *hasClaim="'CanModifyRPMEncounter'" mdbTooltip="Edit Date" (click)="OpenEditReadingDateModal(bpitem, editDateModal)" class="las la-calendar text-dynamic-secondary-2c mx-2"
                              style="font-size: 18px;" 
                              placement="right"></i>
                            </a>{{bpitem.measurementDate}}
                        </td>
                        <td>Sys: {{bpitem.highPressure | number: '1.0-0'}}, dys: {{bpitem.lowPressure | number: '1.0-0'}}, HR: {{bpitem.heartRate}}</td>
                      </tr>
                    </tbody> -->
      
                    <tbody *ngIf="selectedModalityCode == 'PO'">
                      <tr *ngIf="!pulseOximetryDataList || !pulseOximetryDataList.length">
                        <td>No Record Found</td>
                      </tr>
                      <tr *ngFor="let poitem of pulseOximetryDataList">
                        <td>{{poitem.measurementDate | dateFormatPipe: 'time'}}</td>
                        <td>OS: {{poitem.bloodOxygen}}, HR: {{poitem.heartRate}}</td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="selectedModalityCode == 'AT'">
                      <tr *ngIf="!activityDataList || !activityDataList.length">
                        <td>No Record Found</td>
                      </tr>
                      <tr *ngFor="let aitem of activityDataList">
                        <td>{{aitem.measurementDate | dateFormatPipe: 'time'}}</td>
                        <td>Cal.: {{aitem.calories}}, Dist.: {{aitem.distanceTraveled}}, Steps: {{aitem.steps}}</td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="selectedModalityCode == 'BG'">
                      <tr *ngIf="!BGDeviceDataList || !BGDeviceDataList.length">
                        <td>No Record Found</td>
                      </tr>
                      <tr *ngFor="let bgitem of BGDeviceDataList">
                        <td>{{bgitem.measurementDate | dateFormatPipe: 'time'}}</td>
                        <td>BG: {{bgitem.bg | number: '1.0-0'}}</td>
                      </tr>
      
                    </tbody>
                  </table>
                </div>
      
              </div>
              <!-- <mdb-date-picker #datePicker class="myDate-111" name="mydate" [outlineInput]="false"  (dateChanged)="editRpmReading($event)" [options]="myDatePickerOptions" [placeholder]="'Selected date'"
              [(ngModel)]="selectedDate" required></mdb-date-picker> -->
            </div>
          </div>
          <div class="col-xl-8">
            <div class="row ">
              <div class="col-xl-6 pb-3 border-right border-left border-top border-bottom pt-3 mt-1" *ngIf="isBpDevice && BPDeviceDataList.length">
                <div *ngIf="isBpDevice && BPDeviceDataList.length"  >
                  <div *ngIf="isLoadingRpmModalityStatistics" class="d-flex justify-content-center text-center">
                    <mdb-progress-bar class="progress primary-color-dark-s mb-0" mode="indeterminate"></mdb-progress-bar>
                  </div>
                  <h5 class="bg-dynamic-2c p-2 mb-0" style="font-size: 18px;font-weight: 600;color:#fff;border-left:1px solid #fff;border-right:1px solid #fff;">Blood Pressure</h5>
                  <div class="table-responsive">
                    <table class="table reading-rpm-table">
                      <thead>
                        <tr>
                          <th>Description</th>
                          <th># of Reading</th>
                          <th>High</th>
                          <th>Low</th>
                          <th>Average</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of bloodPressureStatistics">
                          <td>{{item.description}}</td>
                          <td>{{item.readingCount}}</td>
                          <td>{{item.high}}</td>
                          <td>{{item.low}}</td>
                          <td>{{item.average}}</td>
                        </tr>
            
                      </tbody>
                      <tbody *ngIf="bloodPressureStatistics.length === 0">
                        <td class="bg-white p-3 text-danger" colspan="12">No Record Found</td>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-xl-6 pb-3 pt-3 border-left border-top border-right border-bottom mt-1">
                <div>
                  <div class="row">
                    <div class="col-sm-12 mt-1" *ngFor="let chart of patientDevicesDataList; let index=index;">
                      <div *ngIf="chart.chartType === 'line'">
                        <div class="sds" *ngIf="chart.deviceObj.modality === selectedModalityCode">
                        <h5 class="bg-dynamic-2c p-2 mb-2" style="font-size: 18px;font-weight: 600;color:#fff;border-left:1px solid #fff;border-right:1px solid #fff;">{{chart.deviceObj.modalityName}}</h5>
                          <div style="position: relative; height:200px; width:100%; min-height:150px">
                            <canvas mdbChart [chartType]="'line'" [datasets]="chart.deviceData.data"
                              [labels]="chart.deviceData.labels" [colors]="linechartColors" [options]="chartOptions"
                              [legend]="false">
                            </canvas>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6 pb-3 pt-3 border-left border-right border-top border-bottom mt-1" *ngIf="PatientTestData?.length" >
                <h5 class="bg-dynamic-2c p-2 mb-2" style="font-size: 18px;font-weight: 600;color:#fff;border-left:1px solid #fff;border-right:1px solid #fff;">Diurnal Graph</h5>
                  <div *ngIf="PatientTestData?.length"  class="panel-body p-0" style="display: block">
                    <div *ngIf="isLoadingEgvs">
                      <div class="d-flex justify-content-center text-center">
                        <mdb-progress-bar class="progress primary-color-dark-s mb-0" mode="indeterminate"></mdb-progress-bar>
                      </div>
                    </div>
                    <div style="position: relative;">
                      <canvas baseChart [type]="'line'" style="min-height: 185px;" [datasets]="longRangeChartDataSet" [labels]="longRangeChartLabels"
                        [options]="longRangeChartOptions" [legend]="true">
                    </canvas>
                    </div>
                    <div class="d-flex justify-content-between align-items-center"> 
                      <p class="font-12 mb-0" style="margin-left:13px">6AM</p>
                      <p class="font-12 mb-0" style="margin-right:13px">6PM</p>
                    </div>
                  </div>
              </div>
              <div class="col-xl-6 pb-3 border-left border-top border-bottom pt-3 border-right mt-1" *ngIf="PatientTestData?.length">
                <h5 class="bg-dynamic-2c p-2 mb-2" style="font-size: 18px;font-weight: 600;color:#fff;border-left:1px solid #fff;border-right:1px solid #fff;">Quartile Graph</h5>
                <div class="row" *ngIf="PatientTestData?.length">
                  <div class="col-md-6">
                    <h5 class="text-center" style="font-size: 12px;font-weight: bold;">Systolic</h5>
                    <div class="d-flex justify-content-center">
                      <div class="mr-1 -time-in-range-">
                        <h6 class="text-danger mb-0 font-12 d-inline-flex align-items-center" *ngIf="systolicPercentageVeryHigh" [style.height]="systolicPercentageVeryHigh + '%'">160 ></h6>
                        <h6 class="text-c-warning mb-0 font-12 d-inline-flex align-items-center" *ngIf="systolicPercentageHigh" [style.height]="systolicPercentageHigh + '%'">140-160</h6>
                        <h6 class="text-success mb-0 font-12 d-inline-flex align-items-center" *ngIf="systolicPercentageNormal" [style.height]="systolicPercentageNormal + '%'">90-140</h6>
                        <h6 class="text-info font-12 d-inline-flex align-items-center" *ngIf="systolicPercentageLow" [style.height]="systolicPercentageLow + '%'">90 </h6>
                      </div>
                      <div class="-time-in-range- mr-2">
                        <div class="-high- bg-danger" *ngIf="systolicPercentageVeryHigh" [style.height]="systolicPercentageVeryHigh + '%'"></div>
                        <div class="-low- bg-c-warning" *ngIf="systolicPercentageHigh" [style.height]="systolicPercentageHigh + '%'"></div>
                        <div class="-inrange- bg-success" *ngIf="systolicPercentageNormal" [style.height]="systolicPercentageNormal + '%'"></div>
                        <div class="-verylow- bg-info" *ngIf="systolicPercentageLow" [style.height]="systolicPercentageLow + '%'"></div>
                      </div>
                      <div class="ml-1 -time-in-range-">
                        <h6 class="text-danger mb-0 font-12 d-inline-flex align-items-center" *ngIf="systolicPercentageVeryHigh" [style.height]="systolicPercentageVeryHigh + '%'">{{systolicPercentageVeryHigh | number:'1.1-1'}}% </h6>
                        <h6 class="text-c-warning mb-0 font-12 d-inline-flex align-items-center" *ngIf="systolicPercentageHigh" [style.height]="systolicPercentageHigh + '%'">{{systolicPercentageHigh | number:'1.1-1'}}%</h6>
                        <h6 class="text-success mb-0 font-12 d-inline-flex align-items-center" *ngIf="systolicPercentageNormal" [style.height]="systolicPercentageNormal + '%'">{{systolicPercentageNormal | number:'1.1-1'}}% </h6>
                        <h6 class="text-info font-12 d-inline-flex align-items-center" *ngIf="systolicPercentageLow" [style.height]="systolicPercentageLow + '%'">{{systolicPercentageLow | number:'1.1-1'}}%</h6>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center">
                      <p class="font-12 mb-0" style="margin-right:100px">mmHg</p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <h5 class="text-center" style="font-size: 12px;font-weight: bold;">Diastolic</h5>
                    <div class="d-flex justify-content-center">
                      <div class="mr-1 -time-in-range-">
                        <h6 class="text-danger d-inline-flex align-items-center mb-0 font-12" *ngIf="diastolicPercentageVeryHigh" [style.height]="diastolicPercentageVeryHigh + '%'">100 ></h6>
                        <h6 class="text-c-warning d-inline-flex align-items-center mb-0 font-12" *ngIf="diastolicPercentageHigh" [style.height]="diastolicPercentageHigh + '%'">90-100
                        </h6>
                        <h6 class="text-success d-inline-flex align-items-center mb-0 font-12" *ngIf="diastolicPercentageNormal" [style.height]="diastolicPercentageNormal + '%'">60-90</h6>
                        <h6 class="text-info font-12 d-inline-flex align-items-center" *ngIf="diastolicPercentageLow" [style.height]="diastolicPercentageLow + '%'">60 </h6>
                      </div>
                      <div class="-time-in-range- mr-2">
                        <div class="-high- bg-danger" *ngIf="diastolicPercentageVeryHigh" [style.height]="diastolicPercentageVeryHigh + '%'"></div>
                        <div class="-low- bg-c-warning" *ngIf="diastolicPercentageHigh" [style.height]="diastolicPercentageHigh + '%'"></div>
                        <div class="-inrange- bg-success" *ngIf="diastolicPercentageNormal" [style.height]="diastolicPercentageNormal + '%'"></div>
                        <div class="-verylow- bg-info" *ngIf="diastolicPercentageLow" [style.height]="diastolicPercentageLow + '%'"></div>
                      </div>
                      <div class="ml-1 -time-in-range-">
                        <h6 class="text-danger d-inline-flex align-items-center mb-0 font-12" *ngIf="diastolicPercentageVeryHigh" [style.height]="diastolicPercentageVeryHigh + '%'">{{diastolicPercentageVeryHigh | number:'1.1-1'}}% </h6>
                        <h6 class="text-c-warning  d-inline-flex align-items-center mb-0 font-12" *ngIf="diastolicPercentageHigh" [style.height]="diastolicPercentageHigh + '%'">{{diastolicPercentageHigh | number:'1.1-1'}}% 
                        </h6>
                        <h6 class="text-success d-inline-flex align-items-center mb-0 font-12" *ngIf="diastolicPercentageNormal" [style.height]="diastolicPercentageNormal + '%'">{{diastolicPercentageNormal | number:'1.1-1'}}% </h6>
                        <h6 class="text-info font-12 d-inline-flex align-items-center" *ngIf="diastolicPercentageLow" [style.height]="diastolicPercentageLow + '%'">{{diastolicPercentageLow | number:'1.1-1'}}% </h6>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center">
                      <p class="font-12 mb-0" style="margin-right:100px">mmHg</p>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="custom-status-colors">
                      <ul>
                        <li><a class="pcm-status-badge bg-danger mr-2 rounded" style="min-width:25px; width: 25px;height:17px"></a>Very High</li>
                        <li><a class="pcm-status-badge bg-c-warning mr-2 rounded" style="min-width:25px; width: 25px;height:17px"></a>High</li>
                        <li><a class="pcm-status-badge bg-success mr-2 rounded" style="min-width:25px; width: 25px;height:17px"></a>Normal</li>
                        <li><a class="pcm-status-badge bg-info mr-2 rounded" style="min-width:25px; width: 25px;height:17px"></a>Low</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>


              
            </div>
          </div>

        </div>
        <div class="row" *ngIf="selectedModalityCode !== 'CGM'">
          <ng-container
            *ngIf="(selectedModalityCode == 'WT' && (!weightDataList || !weightDataList.length))
          || (selectedModalityCode == 'BP' && (!BPDeviceDataList || !BPDeviceDataList.length))
          || (selectedModalityCode == 'PO' && (!pulseOximetryDataList || !pulseOximetryDataList.length))
          || (selectedModalityCode == 'AT' && (!activityDataList || !activityDataList.length))
          || (selectedModalityCode == 'BG' && (!BGDeviceDataList || !BGDeviceDataList.length)) && !gettingBGData && !gettingBGData && !gettingWTData">
            <div class="col-lg-12 col-md-12 col-sm-12 px-1">
              <div class="alert alert-warning" role="alert">
                No Data Found
              </div>
            </div>
          </ng-container>
          <div class="col-md-4 pb-3 pt-3 border-left border-top border-right border-bottom mt-1" *ngIf="selectedModalityCode != 'BP' && BGDeviceDataList?.length">


    
            <ng-container >
              
            
            
            <div >
              <table class="table table-bordered table-hover reading-rpm-table mb-0" *ngIf="selectedModalityCode == 'WT'">
                <colgroup>
                  <col width="50%">
                  <col width="50%">
                </colgroup>
                <thead class="bg-dynamic-2c text-white">
                  <tr>
                    <th>Date</th>
                    <th>Weight</th>
                  </tr>
                </thead>
    
              </table>
            </div>
            <div >
              <table class="table table-bordered table-hover reading-rpm-table mb-0" *ngIf="selectedModalityCode == 'BG'">
                <colgroup>
                  <col width="50%">
                  <col width="50%">
                </colgroup>
                <thead class="bg-dynamic-2c text-white">
                  <tr>
                    <th>Date</th>
                    <th>Blood Glucose</th>
                  </tr>
                </thead>
    
              </table>
            </div>
            <div >
              <table class="table table-bordered table-hover reading-rpm-table mb-0" *ngIf="selectedModalityCode == 'PO'">
                <colgroup>
                  <col width="40%">
                  <col width="30%">
                  <col width="30%">
                </colgroup>
                <thead class="bg-dynamic-2c text-white">
                  <tr>
                    <th>Date</th>
                    <th>Pulse Oximetry</th>
                    <th>Heart Rate</th>
                  </tr>
                </thead>
    
              </table>
            </div>
            <div style="max-height: 200px;" *ngIf="selectedModalityCode == 'PO'">
              <div  style="overflow-y: auto;max-height:200px;">
                <table class="table table-bordered table-hover reading-rpm-table">
                  <colgroup>
                    <col width="40%">
                    <col width="30%">
                    <col width="30%">
                  </colgroup>
                  <tbody *ngIf="selectedModalityCode == 'PO'">
                    <tr *ngIf="!pulseOximetryDataList || !pulseOximetryDataList.length">
                      <td>No Record Found</td>
                    </tr>
                    <tr *ngFor="let poitem of pulseOximetryDataList">
                      <td>{{poitem.measurementDate | dateFormatPipe: 'time'}}</td>
                      <td><strong>{{poitem.bloodOxygen}}</strong></td>
                      <td><strong>{{poitem.heartRate}}</strong></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div style="max-height: 200px;" *ngIf="selectedModalityCode != 'PO'">
              <div  style="overflow-y: auto;max-height:200px;">
                <table class="table table-bordered table-hover reading-rpm-table">
                  <colgroup>
                    <col width="50%">
                    <col width="50%">
                  </colgroup>
                  <tbody *ngIf="selectedModalityCode == 'WT'">
                    <tr *ngIf="!weightDataList || !weightDataList.length">
                      <td>No Record Found</td>
                    </tr>
                    <tr *ngFor="let witem of weightDataList">
                      <td>{{witem.measurementDate | dateFormatPipe: 'time'}}</td>
                      <td><strong>{{witem.weightValue}}</strong></td>
                    </tr>
                  </tbody>
                  <tbody *ngIf="selectedModalityCode == 'BP'">
                    <tr *ngIf="!BPDeviceDataList || !BPDeviceDataList.length">
                      <td>No Record Found</td>
                    </tr>
                    <tr *ngFor="let bpitem of BPDeviceDataList">
                      <td> <a class="">
                        <!-- <i *hasClaim="'CanModifyRPMEncounter'" class="las la-calendar text-dynamic-secondary-2c mx-2"
                            style="font-size: 18px;" mdbTooltip="Edit Date"
                            placement="right"></i> -->
                          </a>{{bpitem.measurementDate}}</td>
                      <td>Sys: {{bpitem.highPressure | number: '1.0-0'}}, dys: {{bpitem.lowPressure | number: '1.0-0'}}, HR: {{bpitem.heartRate}}</td>
                    </tr>
                  </tbody>
    

                  <tbody *ngIf="selectedModalityCode == 'AT'">
                    <tr *ngIf="!activityDataList || !activityDataList.length">
                      <td>No Record Found</td>
                    </tr>
                    <tr *ngFor="let aitem of activityDataList">
                      <td>{{aitem.measurementDate | dateFormatPipe: 'time'}}</td>
                      <td>Cal.: <strong>{{aitem.calories}}</strong>, Dist.: <strong>{{aitem.distanceTraveled}}</strong>, Steps: <strong>{{aitem.steps}}</strong></td>
                    </tr>
                  </tbody>
                  <tbody *ngIf="selectedModalityCode == 'BG'">
                    <tr *ngIf="!BGDeviceDataList || !BGDeviceDataList.length">
                      <td>No Record Found</td>
                    </tr>
                    <tr *ngFor="let bgitem of BGDeviceDataList">
                      <td>{{bgitem.measurementDate | dateFormatPipe: 'time'}}</td>
                      <td><strong>{{bgitem.bg | number: '1.0-0'}}</strong></td>
                    </tr>
    
                  </tbody>
                </table>
              </div>
    
            </div>
          </ng-container>
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12 pb-3 pt-3 border-left border-top border-right border-bottom mt-1" *ngIf="(selectedModalityCode == 'WT' && weightDataList && weightDataList.length)
              || (selectedModalityCode == 'PO' && pulseOximetryDataList && pulseOximetryDataList.length)
              || (selectedModalityCode == 'AT' && activityDataList && activityDataList.length)
              || (selectedModalityCode == 'BG' && BGDeviceDataList && BGDeviceDataList.length)">
              <div *ngIf="selectedModalityCode === 'WT'">
                <div *ngIf="isLoadingRpmModalityStatistics" class="d-flex justify-content-center text-center">
                  <mdb-progress-bar class="progress primary-color-dark-s mb-0" mode="indeterminate"></mdb-progress-bar>
                </div>
                <h5 class="bg-dynamic-2c p-2 mb-0" style="font-size: 18px;font-weight: 600;color:#fff;border-left:1px solid #fff;border-right:1px solid #fff;">Weight</h5>
                <div class="table-responsive">
                  <table class="table reading-rpm-table">
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th># of Reading</th>
                        <th>High</th>
                        <th>Low</th>
                        <th>Average</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of weightStatistics">
                        <td>{{item.description}}</td>
                        <td>{{item.readingCount}}</td>
                        <td>{{item.high}}</td>
                        <td>{{item.low}}</td>
                        <td>{{item.average}}</td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="weightStatistics.length === 0">
                      <td class="bg-white p-3 text-danger" colspan="12">No Record Found</td>
                    </tbody>
                  </table>
                </div>
              </div>
                <div *ngIf="selectedModalityCode === 'BG' && BGDeviceDataList.length">
                  <div *ngIf="isLoadingRpmModalityStatistics" class="d-flex justify-content-center text-center">
                    <mdb-progress-bar class="progress primary-color-dark-s mb-0" mode="indeterminate"></mdb-progress-bar>
                  </div>
                  <h5 class="bg-dynamic-2c p-2 mb-0" style="font-size: 18px;font-weight: 600;color:#fff;border-left:1px solid #fff;border-right:1px solid #fff;">Blood Glucose</h5>
                  <div class="table-responsive">
                    <table class="table reading-rpm-table">
                      <thead>
                        <tr>
                          <th>Description</th>
                          <th># of Reading</th>
                          <th>High</th>
                          <th>Low</th>
                          <th>Average</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of bloodGlucoseStatistics">
                          <td>{{item.description}}</td>
                          <td>{{item.readingCount}}</td>
                          <td>{{item.high}}</td>
                          <td>{{item.low}}</td>
                          <td>{{item.average}}</td>
                        </tr>
                      </tbody>
                      <tbody *ngIf="bloodGlucoseStatistics.length === 0">
                        <td class="bg-white p-3 text-danger" colspan="12">No Record Found</td>
                      </tbody>
                    </table>
                  </div>
                </div>
                    
                <!-- <mdb-date-picker #datePicker class="myDate-111" name="mydate" [outlineInput]="false"  (dateChanged)="editRpmReading($event)" [options]="myDatePickerOptions" [placeholder]="'Selected date'"
                [(ngModel)]="selectedDate" required></mdb-date-picker> -->

                <ng-container *ngIf="selectedModalityCode == 'BG' && BGDeviceDataList.length">
    
                  <div class="mb-2" >
                    <table class="table table-bordered  table-responsive table-hover mb-0">
                      <thead class="bg-dynamic-2c text-white">
                        <tr>
                          <th title="BMI ">BMI</th>
                          <th title="HBA1c ">A1C</th>
                          <th title="Nephropathy value ">DN</th>
                          <th title="LDL Value ">LD</th>
                          <th title="Retinopathy status ">DE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <!-- <td>{{witem.measurementDate | dateFormatPipe}}</td>
                        <td>Wt: {{witem.weightValue}}</td> -->
                          <td>
                            <div class="d-flex align-items-center">
                              <p class="mb-0 text-center mr-1"
                                *ngIf="careGapsReadingsForRPMDto.bmi && careGapsReadingsForRPMDto.bmi.value">
                                <span class="badge badge-success"
                                  *ngIf="careGapsReadingsForRPMDto.bmi.valueInNumber > 16 && careGapsReadingsForRPMDto.bmi.valueInNumber <= 25">{{
                                  careGapsReadingsForRPMDto.bmi.valueInNumber
                                  }}</span>
                                <span class="badge badge-warning"
                                  *ngIf="careGapsReadingsForRPMDto.bmi.valueInNumber	> 25 && careGapsReadingsForRPMDto.bmi.valueInNumber <= 30">{{
                                  careGapsReadingsForRPMDto.bmi.valueInNumber
                                  }}</span>
                                <span class="badge badge-danger"
                                  *ngIf=" careGapsReadingsForRPMDto.bmi.valueInNumber <= 16 || (careGapsReadingsForRPMDto.bmi.valueInNumber	> 30 && careGapsReadingsForRPMDto.bmi.valueInNumber <= 40) || careGapsReadingsForRPMDto.bmi.valueInNumber	> 40">{{
                                  careGapsReadingsForRPMDto.bmi.valueInNumber }}</span>
                              </p>
                              <p class="mb-0 text-center"
                                *ngIf="careGapsReadingsForRPMDto.bmi && careGapsReadingsForRPMDto.bmi.lastReadingDate">
                                <!-- <span >{{ careGapsReadingsForRPMDto.bmi.lastReadingDate | dateFormatPipe }} </span> -->
                                <span *ngIf="careGapsReadingsForRPMDto.bmi.NoOfMonth > 6" class="badge badge-danger">{{
                                  careGapsReadingsForRPMDto.bmi.lastReadingDate | dateFormatPipe }} </span>
                                <span *ngIf="careGapsReadingsForRPMDto.bmi.NoOfMonth <= 6" class="badge badge-success">{{
                                  careGapsReadingsForRPMDto.bmi.lastReadingDate | dateFormatPipe }} </span>
                                <!-- <span class="badge badge-danger">{{ careGapsReadingsForRPMDto.bmi.lastReadingDate | dateFormatPipe }} </span>  -->
                              </p>
                            </div>
        
                          </td>
                          <td>
                            <div class="d-flex align-items-center">
                              <p class="mb-0 text-center mr-1"
                                *ngIf="careGapsReadingsForRPMDto.a1C && careGapsReadingsForRPMDto.a1C.value">
                                <span class="badge badge-success" *ngIf="careGapsReadingsForRPMDto.a1C.valueInNumber <= 7">{{
                                  careGapsReadingsForRPMDto.a1C.valueInNumber
                                  }}</span>
                                <span class="badge badge-warning"
                                  *ngIf="careGapsReadingsForRPMDto.a1C.valueInNumber > 7 && careGapsReadingsForRPMDto.a1C.valueInNumber <= 8 ">{{
                                  careGapsReadingsForRPMDto.a1C.valueInNumber
                                  }}</span>
                                <span class="badge badge-danger"
                                  *ngIf="(careGapsReadingsForRPMDto.a1C.valueInNumber > 8 && careGapsReadingsForRPMDto.a1C.valueInNumber <= 9) || careGapsReadingsForRPMDto.a1C.valueInNumber > 9">{{
                                  careGapsReadingsForRPMDto.a1C.valueInNumber }}</span>
                              </p>
                              <p class="mb-0 text-center "
                                *ngIf="careGapsReadingsForRPMDto.a1C && careGapsReadingsForRPMDto.a1C.lastReadingDate">
                                <span *ngIf="careGapsReadingsForRPMDto.a1C.NoOfMonth > 6" class="badge badge-danger">{{
                                  careGapsReadingsForRPMDto.a1C.lastReadingDate | dateFormatPipe }} </span>
                                <span *ngIf="careGapsReadingsForRPMDto.a1C.NoOfMonth <= 6" class="badge badge-success">{{
                                  careGapsReadingsForRPMDto.a1C.lastReadingDate | dateFormatPipe }} </span>
                              </p>
                            </div>
        
                            <!-- <span [ngClass]="{'badge badge-danger' : careGapsReadingsForRPMDto.a1C.NoOfMonth > 6}">{{ careGapsReadingsForRPMDto.a1C.lastReadingDate | dateFormatPipe }} </span> </p> -->
                          </td>
                          <td>
                            <div class="d-flex align-items-center">
                              <p class="mb-0 text-center mr-1"
                                *ngIf="careGapsReadingsForRPMDto.dn && careGapsReadingsForRPMDto.dn.value">
                                <span class="badge badge-success" *ngIf="careGapsReadingsForRPMDto.dn.valueInNumber <= 30 ">{{
                                  careGapsReadingsForRPMDto.dn.valueInNumber
                                  }}</span>
                                <span class="badge badge-warning"
                                  *ngIf="careGapsReadingsForRPMDto.dn.valueInNumber > 30 && careGapsReadingsForRPMDto.dn.valueInNumber <= 300 ">{{
                                  careGapsReadingsForRPMDto.dn.valueInNumber
                                  }}</span>
                                <span class="badge badge-danger" *ngIf="careGapsReadingsForRPMDto.dn.valueInNumber > 300">{{
                                  careGapsReadingsForRPMDto.dn.valueInNumber
                                  }}</span>
                              </p>
                              <p class="mb-0 text-center"
                                *ngIf="careGapsReadingsForRPMDto.dn && careGapsReadingsForRPMDto.dn.lastReadingDate">
                                <span *ngIf="careGapsReadingsForRPMDto.dn.NoOfMonth > 6" class="badge badge-danger">{{
                                  careGapsReadingsForRPMDto.dn.lastReadingDate | dateFormatPipe }} </span>
                                <span *ngIf="careGapsReadingsForRPMDto.dn.NoOfMonth <= 6" class="badge badge-success">{{
                                  careGapsReadingsForRPMDto.dn.lastReadingDate | dateFormatPipe }} </span>
                              </p>
                            </div>
        
                          </td>
                          <td>
                            <div class="d-flex align-items-center">
                              <p class="mb-0 text-center mr-1"
                                *ngIf="careGapsReadingsForRPMDto.ld && careGapsReadingsForRPMDto.ld.value">
                                <span class="badge badge-success" *ngIf="careGapsReadingsForRPMDto.ld.valueInNumber <= 100">{{
                                  careGapsReadingsForRPMDto.ld.valueInNumber
                                  }}</span>
                                <span class="badge badge-danger" *ngIf="careGapsReadingsForRPMDto.ld.valueInNumber > 100">{{
                                  careGapsReadingsForRPMDto.ld.valueInNumber
                                  }}</span>
                              </p>
                              <p class="mb-0 text-center"
                                *ngIf="careGapsReadingsForRPMDto.ld && careGapsReadingsForRPMDto.ld.lastReadingDate">
                                <span *ngIf="careGapsReadingsForRPMDto.ld.NoOfMonth > 6" class="badge badge-danger">{{
                                  careGapsReadingsForRPMDto.ld.lastReadingDate | dateFormatPipe }} </span>
                                <span *ngIf="careGapsReadingsForRPMDto.ld.NoOfMonth <= 6" class="badge badge-success">{{
                                  careGapsReadingsForRPMDto.ld.lastReadingDate | dateFormatPipe }} </span>
                                <!-- <span [ngClass]="{'badge badge-danger' : careGapsReadingsForRPMDto.ld.NoOfMonth > 6}">{{ careGapsReadingsForRPMDto.ld.lastReadingDate | dateFormatPipe}} </span> -->
                              </p>
                            </div>
        
                          </td>
                          <td>
                            <div class="d-flex align-items-center">
        
                              <p class="mb-0 text-center mr-1"
                                *ngIf="careGapsReadingsForRPMDto.de && careGapsReadingsForRPMDto.de.value">
                                <span class="badge badge-warning"
                                  *ngIf="careGapsReadingsForRPMDto.de.value == 'Mild Retinopathy'">{{
                                  careGapsReadingsForRPMDto.de.value
                                  }}</span>
                                <span class="badge badge-danger"
                                  *ngIf="careGapsReadingsForRPMDto.de.value == 'Severe Retinopathy '">{{
                                  careGapsReadingsForRPMDto.de.value
                                  }}</span>
                                <span class="badge badge-light-red"
                                  *ngIf="careGapsReadingsForRPMDto.de.value == 'Moderate Retinopathy '">{{
                                  careGapsReadingsForRPMDto.de.value
                                  }}</span>
                              </p>
        
                              <!-- <p class="mb-0 text-center mr-1" *ngIf="careGapsReadingsForRPMDto.de && careGapsReadingsForRPMDto.de.value"><span class="badge badge-primary">{{ careGapsReadingsForRPMDto.de.value }}</span></p> -->
                              <p class="mb-0 text-center"
                                *ngIf="careGapsReadingsForRPMDto.de && careGapsReadingsForRPMDto.de.lastReadingDate">
                                <!-- <span [ngClass]="{'badge badge-danger' : careGapsReadingsForRPMDto.dn.NoOfMonth > 12}">{{ careGapsReadingsForRPMDto.de.lastReadingDate | dateFormatPipe }} </span> -->
                                <span *ngIf="careGapsReadingsForRPMDto.de.NoOfMonth > 12" class="badge badge-danger">{{
                                  careGapsReadingsForRPMDto.de.lastReadingDate | dateFormatPipe }} </span>
                                <span *ngIf="careGapsReadingsForRPMDto.de.NoOfMonth <= 12" class="badge badge-success">{{
                                  careGapsReadingsForRPMDto.de.lastReadingDate | dateFormatPipe }} </span>
                              </p>
                            </div>
        
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
        
                </ng-container>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 pb-3 pt-3 border-left border-top border-right border-bottom mt-1" *ngIf="selectedModalityCode != 'BP'&& BGDeviceDataList?.length" >
    
                <div *ngIf="patientDevicesDataList?.length > 0">
                  <div class="row">
                    <div class="col-sm-12" *ngFor="let chart of patientDevicesDataList; let index=index;">
        
                      <div *ngIf="chart.chartType === 'bar'">
                        <div class="sds 123" *ngIf="chart.deviceObj.modality === selectedModalityCode">
                          <p><strong> {{chart.deviceObj.modalityName}}</strong></p>
                          <div style="position: relative; height:90%; width:100%; min-height:300px">
                            <canvas mdbChart [chartType]="'bar'" [datasets]="chart.deviceData.data"
                              [labels]="chart.deviceData.labels" [options]="chartOptions" [legend]="false">
                            </canvas>
                          </div>
                        </div>
                      </div>
        
                      <div *ngIf="chart.chartType === 'horizontalBar' && chart.deviceObj.modality === selectedModalityCode && selectedModalityCode === 'BG'">
                        <div class="sds 001">
                          <p><strong>{{chart.deviceObj.modalityName}}</strong></p>
                          <div style="position: relative; width:100%; ">
        
                            <div >
                              <canvas id="examChart"></canvas>
                            </div>
                          </div>
                        </div>
        
                      </div>
                      <div
                        *ngIf="chart.chartType === 'horizontalBar' && chart.deviceObj.modality === selectedModalityCode && chart.deviceObj.modality === 'PO'">
                        <div class="sds 112233">
                          <p><strong>{{chart.deviceObj.modalityName}}</strong></p>
                          <div style="position: relative; height:90%; width:100%; min-height:300px">
                            <canvas mdbChart [chartType]="'line'" [datasets]="chart.deviceData.data"
                              [labels]="chart.deviceData.labels" [colors]="linechartColors" [options]="chartOptions"
                              [legend]="false">
                            </canvas>
                          </div>
                        </div>
        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
        <ng-container *ngIf="selectedModalityCode == 'CGM'">
          <app-dexcom-dashboard #dexcomCompRef></app-dexcom-dashboard>
        </ng-container>
      </div>
    </div>


  </div>
</div>





<div mdbModal #modalityAlertModal11="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myBasicModalLabel11" [config]="{ignoreBackdropClick: true}" aria-hidden="true">
  <div class="modal-dialog modal-md modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalityAlertModal11.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100">Modality Details</h4>
      </div>
      <div class="modal-body p-4">
        <fieldset [disabled]="alreadySetupModality">
          <!-- <div class="row align-items-center mb-3" *ngIf="rpmDeviceResponseDto.isIotDevice">
            <div class="col-sm-4">
              <label class="mb-0">Device Vendor<sup class="text-danger">*</sup></label>
            </div>
            <div class="col-sm-8">
              <div class="form-group mb-0">

                <ng-select disabled [searchable]="false" [appendTo]="'body'" [(ngModel)]="selectedDeviceVendor"
                  [disabled]="!rpmDeviceResponseDto.isIotDevice" class=" ng-select-small" [clearable]="false"
                  placeholder="Device Vendor">
                  <ng-option [value]="'smartmeter'">Smart Meter
                  </ng-option>
                </ng-select>
              </div>
            </div>
          </div> -->
          <div class="row align-items-center mb-3">
            <div class="col-sm-4">
              <label class="mb-0">Installation Date<sup class="text-danger">*</sup></label>
            </div>
            <div class="col-sm-8">
              <div class="form-group mb-0 flex-grow-1">
                <!-- <input type="date" [(ngModel)]="rpmDeviceResponseDto.installationDate" name=""
                  class="form-control form-control-sm"> -->

                <input disabled type="text" [(ngModel)]="rpmDeviceResponseDto.installationDate"
                  class="form-control form-control-sm" #date='ngModel' #dateToDp="dpDayPicker"
                  [dpDayPicker]="onlyDatePickerConfig" [theme]="'dp-material ccm-date-picker'" [mode]="'day'" appDateTimeValidator="date" />

                <!-- <input type="text" [(ngModel)]="rpmDeviceResponseDto.installationDate" class="form-control form-control-sm"
                      #dateToDp="dpDayPicker" [dpDayPicker]="onlyDatePickerConfig"
                      [theme]="'dp-material ccm-date-picker'" [mode]="'day'" appDateTimeValidator="date"> -->
              </div>
            </div>
          </div>
          <div class="row align-items-center mb-3">
            <div class="col-sm-4">
              <label class="mb-0">Device Type<sup class="text-danger">*</sup></label>
            </div>
            <div class="col-sm-8">
              <div class="form-group mb-0">
                <ng-select disabled [searchable]="false" [appendTo]="'body'" [(ngModel)]="selectedDeviceType"
                   class=" ng-select-small" [clearable]="false"
                  placeholder="Select Modality">
                  <ng-option [value]="item.modalityCode" *ngFor="let item of rpmModalitEnumList">{{item.modalityName}}
                  </ng-option>
                </ng-select>
              </div>
            </div>
          </div>
          <div class="row align-items-center mb-3">
            <div class="col-sm-4">
              <label class="mb-0">Serial Number<sup class="text-danger">*</sup></label>
            </div>
            <div class="col-sm-8">

              <div class="form-group mb-0 flex-grow-1">
                <div class="input-group mb-3 ">



                  <div class="input-loader flex-1">
                    <input disabled type="text" [(ngModel)]="rpmDeviceResponseDto.serialNo" (input)="serialNumberChanged()"
                      name="" class="form-control form-control-sm" placeholder="Serial Number">
                    <span *ngIf="validatingDevice" class="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
                  </div>


                  <!-- <div class="input-group-append" *ngIf="rpmDeviceResponseDto.isIotDevice">
                    <div class="btn-group">
                      <button [disabled]="validatingDevice || !rpmDeviceResponseDto.serialNo"
                        class="btn btn-dynamic-2c btn-icon m-0 h-100 rounded-0 align-items-center d-flex justify-content-center"
                        (click)="ValidateSerial()">
                        <i class="las la-check fa-lg"></i>
                      </button>
                      <button [disabled]="!selectedDeviceType" *ngIf="rpmDeviceResponseDto.isIotDevice"
                        class="overflow-hidden help-button btn btn-dynamic-secondary-2c btn-icon m-0 h-100 align-items-center d-flex justify-content-center"
                        id="popTemplate" container="body" mdbPopoverHeader="Help" placement="left" triggers="click"
                        [mdbPopover]="popTemplate" #pop="bs-mdbPopover" (appIsOutside)="pop.isOpen=false"><i
                          class="las la-exclamation-circle" style="font-size: 1.75em;"></i></button>
                    </div>
                  </div> -->
                  <ng-template #popTemplate>
                    <div style="max-height: 400px;  width:calc(100% - 27px); padding: .70rem;"
                      (click)="$event.stopPropagation()" [style.overflow-y]="'auto'">
                      <p class="alert alert-warning">You can find Serial/Device ID/IMEI Number on the box or device as
                        shown in the following image.</p>
                      <img class="img-fluid" *ngIf="selectedDeviceType==='BG'"
                        src="/assets/rpm-devices/glucose-barcode.jpg" alt="blood-pressure">
                      <img class="img-fluid" *ngIf="selectedDeviceType==='BP'"
                        src="/assets/rpm-devices/blood-pressure-barcode.jpg" alt="blood-pressure">
                      <img class="img-fluid" *ngIf="selectedDeviceType==='PO'"
                        src="/assets/rpm-devices/iPulse2.jpg" alt="blood-pressure">
                      <img class="img-fluid" *ngIf="selectedDeviceType==='WT'"
                        src="/assets/rpm-devices/iScale.jpg" alt="blood-pressure">
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center mb-3">
            <div class="col-sm-4">
              <label class="mb-0">Device Model<sup class="text-danger">*</sup></label>
            </div>
            <div class="col-sm-8">
              <div class="form-group mb-0 flex-grow-1">
                <input type="text" [disabled]="rpmDeviceResponseDto.isIotDevice"
                  [(ngModel)]="rpmDeviceResponseDto.model" name="" class="form-control form-control-sm"
                  placeholder="Device Model">
              </div>
            </div>
          </div>
          <div class="row align-items-center mb-3">
            <div class="col-sm-4">
              <label class="mb-0">Device Mac/IMEI<sup class="text-danger">*</sup></label>
            </div>
            <div class="col-sm-8">
              <div class="form-group mb-0 flex-grow-1">
                <input type="text" [disabled]="rpmDeviceResponseDto.isIotDevice"
                  [(ngModel)]="rpmDeviceResponseDto.macAddress" name="" class="form-control form-control-sm"
                  placeholder="Device Mac/IMEI">
              </div>
            </div>
          </div>
          <!-- <div class="row align-items-center mb-3">
            <div class="col-sm-4">
              <label>Charge Device Installation</label>
            </div>
            <div class="col-sm-8">
              <div class="custom-control custom-checkbox w-info-90">
                <input type="checkbox" class="custom-control-input" [(ngModel)]="rpmDeviceResponseDto.cpT99453"
                  id="cpt1">
                <label class="custom-control-label" for="cpt1">CPT 99453</label>
              </div>
            </div>

          </div> -->
          <!-- <div class="row" *ngIf="alreadyPendingBillingMsg">
            <div class="col-sm-12">

              <div class="alert alert-danger" role="alert">

                <p class="mb-0" style="font-size: 14px;">
                  {{alreadyPendingBillingMsg}}
                </p>
              </div>

            </div>
          </div> -->
        </fieldset>
      </div>
      <div class="modal-footer">
        <!-- <button *ngIf="!alreadySetupModality" type="button" class="btn btn-sm btn-dynamic-2c"
          [disabled]="validatingDevice || !rpmDeviceResponseDto.serialNo || !selectedDeviceType || !rpmDeviceResponseDto.model || !rpmDeviceResponseDto.installationDate"
          (click)="SetupRPMDevice(modalityAlertModal11)">
          <span *ngIf="false" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Save
        </button> -->
        <button type="button" class="btn btn-sm btn-dynamic-secondary-2c" aria-label="Close"
          (click)="modalityAlertModal11.hide()">Close</button>
      </div>
    </div>
  </div>
</div>

<div mdbModal #editDateModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" *ngIf="selectedBpItem?.id">
      <div class="modal-header">
        <h4 class="modal-title w-100 text-center" id="myModalLabel">Reading Date
        </h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-12">
            <div class=" form-group mt-3" >
              <label class="w-100">Reading Date <small class="text-danger">*</small></label>
              <div class="input-group-append ">
                  <input type="text" [(ngModel)]="selectedBpItem.measurementDate" #rpmEditDate="ngModel" class="form-control form-control-sm"
                    #dateDPicker="dpDayPicker" [dpDayPicker]="assignedDatePickerConfig" placeholder="MM/DD/YYYY hh:mm A"
                    [theme]="'dp-material ccm-date-picker'" [mode]="'daytime'" appDateTimeValidator="datetime" required />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="editRpmReading(editDateModal);" [disabled]="savingDate || rpmEditDate?.invalid" class="waves-light btn btn-dynamic-2c btn-sm"
          aria-label="Close"  mdbWavesEffect> <span *ngIf="savingDate"
            class="mr-1 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Save</button>
        <button type="button" class="waves-light btn btn-sm btn-dynamic-secondary-2c" aria-label="Close"
          (click)="editDateModal.hide()" mdbWavesEffect>Close</button>
        <!-- <butmdbModalton type="button" mdbBtn color="primry" (click)="addRequiredData()" class="relative waves-light"
          mdbWavesEffect>Add Data</button> -->
      </div>
    </div>
  </div>
</div>
</div>

<div mdbModal #modalityAlertModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myBasicModalLabel" (close)="clearAlertObj()" [config]="{ignoreBackdropClick: true}"
  aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalityAlertModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100">Alert Setting</h4>
      </div>
      <div class="modal-body p-4">
        <div class="row">
          <div class="col-lg-12 col-sm-12">
            <div class="p-3 border" *ngIf="selectedDevice && selectedDevice.modality === 'BP'">
              <label>Blood Pressure</label>

              <div class="d-flex align-items-center justify-content-start flex-nowrap mb-4">
                <label class="mx-3">DBP Low</label>
                <div class="form-group mb-0 flex-grow-1">
                  <input type="number" min="0" oninput="validity.valid||(value='');"
                    [(ngModel)]="modalityAlertConfObj.threshold.bloodPressure.minDiastolic" (blur)="CheckMinMaxvalue()"
                    name="" class="form-control form-control-sm">
                </div>
                <label class="mx-3">DBP High</label>
                <div class="form-group mb-0 flex-grow-1">
                  <input type="number" min="0" oninput="validity.valid||(value='');"
                    [(ngModel)]="modalityAlertConfObj.threshold.bloodPressure.maxDiastolic" (blur)="CheckMinMaxvalue()"
                    name="" class="form-control form-control-sm">
                </div>

              </div>
              <div class="d-flex align-items-center justify-content-start flex-nowrap mb-4">
                <label class="mx-3">SBP Low</label>
                <div class="form-group mb-0 flex-grow-1">
                  <input type="number" min="0" oninput="validity.valid||(value='');"
                    [(ngModel)]="modalityAlertConfObj.threshold.bloodPressure.minSystolic" (blur)="CheckMinMaxvalue()"
                    name="" class="form-control form-control-sm">
                </div>
                <label class="mx-3">SBP High</label>
                <div class="form-group mb-0 flex-grow-1">
                  <input type="number" min="0" oninput="validity.valid||(value='');"
                    [(ngModel)]="modalityAlertConfObj.threshold.bloodPressure.maxSystolic" (blur)="CheckMinMaxvalue()"
                    name="" class="form-control form-control-sm">
                </div>
              </div>
            </div>
            <div class="p-3 border" *ngIf="selectedDevice && selectedDevice.modality === 'WT'">
              <label>Weight</label>
              <div class="d-flex align-items-center justify-content-start flex-nowrap mb-4">
                <label class="mx-3">Min</label>
                <div class="form-group mb-0 flex-grow-1">
                  <input type="number" min="0" oninput="validity.valid||(value='');"
                    [(ngModel)]="modalityAlertConfObj.threshold.weight.minWeight" (blur)="CheckMinMaxvalue()" name=""
                    class="form-control form-control-sm">
                </div>
                <label class="mx-3">Max</label>
                <div class="form-group mb-0 flex-grow-1">
                  <input type="number" min="0" oninput="validity.valid||(value='');"
                    [(ngModel)]="modalityAlertConfObj.threshold.weight.maxWeight" (blur)="CheckMinMaxvalue()" name=""
                    class="form-control form-control-sm">
                </div>
              </div>
            </div>
            <div class="p-3 border" *ngIf="selectedDevice && selectedDevice.modality === 'PO'">
              <label>Pulse</label>
              <div class="d-flex align-items-center justify-content-start flex-nowrap mb-4">
                <label class="mx-3">Min Blood Oxygen</label>
                <div class="form-group mb-0 flex-grow-1">
                  <input type="number" min="0" oninput="validity.valid||(value='');"
                    [(ngModel)]="modalityAlertConfObj.threshold.pulse.minBloodOxygen" (blur)="CheckMinMaxvalue()"
                    name="" class="form-control form-control-sm">
                </div>
                <label class="mx-3">Max Blood Oxygen</label>
                <div class="form-group mb-0 flex-grow-1">
                  <input type="number" min="0" oninput="validity.valid||(value='');"
                    [(ngModel)]="modalityAlertConfObj.threshold.pulse.maxBloodOxygen" (blur)="CheckMinMaxvalue()"
                    name="" class="form-control form-control-sm">
                </div>
              </div>
            </div>
            <div class="p-3 border" *ngIf="selectedDevice && selectedDevice.modality === 'BG'">
              <label>Blood Glucose</label>
              <div class="d-flex align-items-center justify-content-start flex-nowrap mb-4">
                <label class="mx-3">Min</label>
                <div class="form-group mb-0 flex-grow-1">
                  <input type="number" min="0" oninput="validity.valid||(value='');"
                    [(ngModel)]="modalityAlertConfObj.threshold.bloodGlucose.minGlucose" (blur)="CheckMinMaxvalue()"
                    name="" class="form-control form-control-sm">
                </div>
                <label class="mx-3">Max</label>
                <div class="form-group mb-0 flex-grow-1">
                  <input type="number" min="0" oninput="validity.valid||(value='');"
                    [(ngModel)]="modalityAlertConfObj.threshold.bloodGlucose.maxGlucose" (blur)="CheckMinMaxvalue()"
                    name="" class="form-control form-control-sm">
                </div>
              </div>
            </div>
            <div class="p-3 border" *ngIf="selectedDevice && selectedDevice.modality === 'AT'">
              <label>Activity</label>
              <div class="d-flex align-items-center justify-content-start flex-nowrap mb-4">
                <label class="mx-3">Min Steps</label>
                <div class="form-group mb-0 flex-grow-1">
                  <input type="number" min="0" oninput="validity.valid||(value='');"
                    [(ngModel)]="modalityAlertConfObj.threshold.activity.minSteps" (blur)="CheckMinMaxvalue()" name=""
                    class="form-control form-control-sm">
                </div>
                <label class="mx-3">Max Steps</label>
                <div class="form-group mb-0 flex-grow-1">
                  <input type="number" min="0" oninput="validity.valid||(value='');"
                    [(ngModel)]="modalityAlertConfObj.threshold.activity.maxSteps" (blur)="CheckMinMaxvalue()" name=""
                    class="form-control form-control-sm">
                </div>
              </div>
            </div>

            <div class="p-3 border">
              <label>Notify</label>
              <div class="d-flex align-items-center justify-content-start flex-nowrap">
                <label class="mx-3">Time Lapse</label>
                <div class="form-group mb-0">
                  <input type="number" min="0" [(ngModel)]="modalityAlertConfObj.notify.timeLapse" OnlyNumbers name=""
                    class="form-control form-control-sm">
                </div>
                <div class="ml-3">
                  <label class="mx-3">Time Unit</label>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" [(ngModel)]="modalityAlertConfObj.notify.timeUnit" [value]="'hours'"
                      class="custom-control-input" id="hours" name="inlineDefaultRadiosExample" mdbInput>
                    <label class="custom-control-label" for="hours">Hours</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" [(ngModel)]="modalityAlertConfObj.notify.timeUnit" [value]="'days'"
                      class="custom-control-input" id="days" name="inlineDefaultRadiosExample" mdbInput>
                    <label class="custom-control-label" for="days">Days</label>
                  </div>
                </div>
              </div>
              <div class="mt-5 ml-3">
                <div class="d-flex align-items-center">
                  <div class="custom-control custom-checkbox">
                    <input [disabled]="!(rcService?.rcPhoneInfo?.length)" [(ngModel)]="PatientNotifSetting.disableProviderSmsAlert" (ngModelChange)="EditPatientProviderSmsAlertConfig()" class="custom-control-input" type="checkbox" id="mandaory">
                    <label class="custom-control-label" for="mandaory">Send copy of out of range alert Notification to care team.</label>
                  </div>
                </div>
              </div>
              <div *ngIf="!(rcService?.rcPhoneInfo?.length)" class="col-12 mt-2">
                <div class="alert alert-danger">
                  This option requires telephony service enabled.  Please Contact Support.
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button [disabled]="savingModalityAlert" (click)="SavePateintModalityAlertData(modalityAlertModal)"
          type="button" class="btn btn-sm btn-dynamic-2c">
          <span *ngIf="savingModalityAlert" class="spinner-border spinner-border-sm" role="status"
            aria-hidden="true"></span>
          Save
        </button>
        <button type="button" class="btn btn-sm btn-dynamic-secondary-2c" aria-label="Close"
          (click)="modalityAlertModal.hide()">Close</button>
      </div>
    </div>

  </div>
</div>
