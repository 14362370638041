export enum BhiStatusEnum {
  "Not Started" = 0,
  //OnHOld = 1,
  Declined = 2,
  "Active CoCM" = 3,
  //'Active G-BHI' = 4,
  //Archive = 5,
  //"Active PCM" = 6,
  Deferred = 7,
  'Unable To Contact' = 8,
  'Changed Provider' = 9,
  'Not Eligible' = 10,
  Consented = 11,
  'Follow Up' = 12,
  'Declined Further Care' = 13,
}
export enum BhiMonthEnum {
  All = -1,
  UnKnown = 0,
  'First Month' = 1,
  'Subsequent Month' = 2,
}
export enum BhiEncounterTimeEnum {
  All = 0,
  "BHI Min 1-30" = 1,
  "BHI Min 31-60" = 2,
  "BHI Min 61-70" = 3,
  "BHI Min 71-90" = 4,
  "BHI Min 91-100" = 5,
  "BHI Min 100-Above" = 7,
  "BHI Min 0" = 8
}
export enum BhiMonthlyStatus {
  // "Not Started" = 0,
  // "Call not answered" = 1,
  // "Completed" = 0,
  // "Partially Completed" = 3,


  Completed = 0,
  'Partially Completed' = 1,
  'Call Not Answered' = 2,
  //UnableToContact = 3,
  'Not Started' = 4
}
