import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityService } from 'src/app/core/security/security.service';
import { HttpResError } from 'src/app/model/common/http-response-error';
import { AppUserAuth } from 'src/app/model/security/app-user.auth';

@Component({
  selector: 'app-direct-login',
  templateUrl: './direct-login.component.html',
  styleUrls: ['./direct-login.component.scss']
})
export class DirectLoginComponent implements OnInit {
  redirectPath: string;
  authKey: string;
  inProgress: boolean;
  alertReason: string;
  userId: string;

  constructor(private route: ActivatedRoute, private securityService: SecurityService, private router: Router) { }

  ngOnInit(): void {
    this.authKey = this.route.snapshot.queryParamMap.get('key');
    this.redirectPath = this.route.snapshot.queryParamMap.get('path');
    this.userId = this.route.snapshot.queryParamMap.get('userId');
    if (this.authKey && this.redirectPath) {
      this.LoginWithAuthKey()
    } else {
      this.router.navigateByUrl(`/login`)
    }
  }
  LoginWithAuthKey() {
    this.inProgress = true;
    this.securityService.LoginWithAuthKey(this.authKey, this.userId)
      .subscribe(
        (res: AppUserAuth) => {
          // this.facilityId = res.appUserAuth.
          if (res) {
            this.securityService.updateToken(res)
            this.router.navigateByUrl(`${this.redirectPath}`)
          }

          this.inProgress = false;
        },
        (err: HttpResError) => {
          this.alertReason = err?.error || err?.message || "Something went wrong.";
          if (err.error || err.message) {
          }
          // this.toaster.error( err.error, err.message);
          this.inProgress = false;
        }
      );
  }

}
