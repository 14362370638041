<div style="overflow-y:auto" mdbModal #prcmEncounterModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  id="AlertModal" aria-labelledby="myBasicModalLabel" aria-hidden="true" (close)="resetEncounterValues()">
  <div class="modal-dialog modal-dialog-scrollable modal-lg flat-modal p-4" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="prcmEncounterModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 *ngIf="addPRCMEncounterDto.id == 0" class="text-dynamic-2c modal-title font-500 mr-3">Add Encounter</h4>
        <h4 *ngIf="addPRCMEncounterDto.id != 0" class="text-dynamic-2c modal-title font-500 mr-3">Edit Encounter</h4>
      </div>
      <div class="modal-body">
        <div class="encounter-body px-3">
          <div class="row">

            <div class="col-sm-6">
              <div class="input-group form-group">
                <label class="w-100">Date <sup class="text-danger">*</sup> </label>
                <input type="text" class="form-control form-control-sm" #dateDPicker56="dpDayPicker"
                  [dpDayPicker]="datePickerConfig2" [(ngModel)]="addPRCMEncounterDto.encounterDate" [theme]="'dp-material ccm-date-picker'"
                  [mode]="'day'" appDateTimeValidator="date" />
                <a (click)="dateDPicker56.api.open();" class="input-group-append">
                  <span class="input-group-text text-white bg-dynamic-2c"><i class="las la-calendar fa-lg"></i></span>
                </a>
              </div>
            </div>
            <div class="col-lg-3 pr-0 col-md-6 col-sm-6">
              <div class="form-group">
                <label class="w-100">Start Time <sup class="text-danger">*</sup> </label>
                <input type="text" class="form-control form-control-sm" #dateDpTime="dpDayPicker"
                  [dpDayPicker]="timePickerConfig" [(ngModel)]="addPRCMEncounterDto.startTime" (onSelect)="StartTimeChanged()"
                  [theme]="'dp-material ccm-date-picker'" [mode]="'time'" appDateTimeValidator="time"  />
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="form-group">
                <label class="w-100">End Time <sup class="text-danger">*</sup> </label>
                <input type="text" readonly class="form-control form-control-sm"
                  [(ngModel)]="addPRCMEncounterDto.endTime">
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label class="w-100">Duration: <small class="text-info">Enter in Minutes</small></label>
                <input #durationInput [(ngModel)]="durationNO" OnlyNumbers type="number"  class="form-control form-control-sm" (input)="durationChanged($event.target.value)">

              </div>
            </div>

            <!-- <div class="col-sm-6">
              <div class="form-group">
                <label><span *ngIf="hasEndoServiceClaim()">Endo </span><span *ngIf="!hasEndoServiceClaim()">Care</span> Coordinator <sup class="text-danger">*</sup> </label>
                <ng-select [searchable]="true" [loading]="isLoadingPayersList" class="ng-select-small"
                  [(ngModel)]="addPRCMEncounterDto.prCMCareCoordinatorId" placeholder="Select coordinator"
                  (click)="$event.stopPropagation();">
                  <ng-option *ngFor="let item of (facilityUsersList | filterFUsersByRole: 'Care Coordinator')"
                    [value]="item.id">{{item.lastName}}, 
 {{item.firstName}}
                  </ng-option>
                </ng-select>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label><span *ngIf="hasEndoServiceClaim()">Endo </span><span *ngIf="!hasEndoServiceClaim()">Care</span> Specialist <sup class="text-danger">*</sup> </label>
                <ng-select [searchable]="true" [loading]="isLoadingPayersList" class="ng-select-small"
                  [(ngModel)]="addPRCMEncounterDto.end_PrCMSpecialistBillerId" placeholder="Select Specialist"
                  (click)="$event.stopPropagation();">
                  <ng-option [value]="item.id"
                    *ngFor="let item of (facilityUsersList | filterFUsersByRole: 'Biller')">{{
                    item.firstName }} {{ item.lastName }} </ng-option>
                </ng-select>
              </div>

            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label><span *ngIf="hasEndoServiceClaim()">Endo </span><span *ngIf="!hasEndoServiceClaim()">Care</span> Facilitator <sup class="text-danger">*</sup> </label>
                <ng-select [searchable]="true" [loading]="isLoadingPayersList" class="ng-select-small"
                  [(ngModel)]="addPRCMEncounterDto.end_PrCMCareFacilitatorId" placeholder="Select clinical Facilitator"
                  (click)="$event.stopPropagation();">
                  <ng-option *ngFor="let item of (facilityUsersList | filterFUsersByRole: 'Care Facilitator')"
                    [value]="item.id">{{item.lastName}}, 
 {{item.firstName}}
                  </ng-option>
                </ng-select>
              </div>
            </div> -->
            <div class="col-sm-6">
              <div class="form-group">
                <label>Service Name <sup class="text-danger">*</sup> </label>
                <ng-select [searchable]="true" class="ng-select-small"
                  [(ngModel)]="addPRCMEncounterDto.prCMServiceTypeId" placeholder="Select Service Type"
                  (click)="$event.stopPropagation();">

                  <ng-option [value]="item.value" *ngFor="let item of serviceTypes">{{item.name}}</ng-option>
                </ng-select>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-form form-group">
                <label for="form12">Notes <sup class="text-danger">*</sup> </label>
                <!-- <textarea type="text" style="min-height: 200px;" class="form-control form-control-sm" id="form12"
                  rows="6" [(ngModel)]="addPRCMEncounterDto.note"></textarea> -->
                  <app-two-c-text-area *ngIf="selectedPatientData?.id" #myFIeldRefPRCM [value]="addPRCMEncounterDto.note" [height]="'140px'" [PatientId]="selectedPatientData?.id" (valueChanged)="addPRCMEncounterDto.note=$event"></app-two-c-text-area>
                <!-- <div class="invalid invalid-text">
                    <small *ngIf="encounterTimeForm.get('note').invalid && encounterTimeForm.get('note')?.touched"
                      class="text-danger">Please write some note.</small>
                  </div> -->
              </div>
              <div #alert *ngIf="addPRCMEncounterDto.prCMMonthlyStatus === PrCMMonthlyStatus['Not Started']"
                class="alert alert-warning alert-dismissible animated fade show" role="alert">
                <strong >Acknowledge/update monthly status to save</strong>
              </div>
            </div>

            <!-- <div class="col-sm-12 mt-2"> -->
              <!-- <sup class="text-danger">*</sup>
              <div class="form-check pl-0 form-check-inline">
                <input [(ngModel)]="addPRCMEncounterDto.cptCode" name="TakeMedications" [value]="'G2064'" type="radio"
                  class="form-check-input" mdbInput id="TakeMedications1">
                <label class="form-check-label" for="TakeMedications1">G2064</label>
              </div>
              <div class="form-check pl-0 form-check-inline">
                <input [(ngModel)]="addPRCMEncounterDto.cptCode" name="TakeMedications" [value]="'G2065'" type="radio"
                  class="form-check-input" mdbInput id="TakeMedications2">
                <label class="form-check-label" for="TakeMedications2">G2065</label>
              </div> -->
              <!-- <div class="form-check pl-0 form-check-inline">
                <input [(ngModel)]="addPRCMEncounterDto.cptCode" name="TakeMedications"
                  [value]="'99484'" type="radio" class="form-check-input" mdbInput id="TakeMedications3">
                <label class="form-check-label" for="TakeMedications3">G PRCM (CPT 99484)</label>
              </div> -->
              <!-- <a id="tobaccoCessation" class="ml-3" container="body" [mdbPopover]="tobaccoCessation" placement="bottom"
              triggers="focus"><i class="las la-exclamation-circle text-success fa-lg"></i></a>
              <ng-template #tobaccoCessation>
                <div class="bg-dark text-white py-3 pl-3 pr-2">
                  <div class="who-covered" (click)="$event.stopPropagation()" [innerHTML]="PRCMINfoHtml"
                    [style.overflow-y]="'auto'" style="max-height: 300px;"></div>
                </div>
              </ng-template> -->
            <!-- </div> -->
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div>
          <div class="d-flex align-items-center  p-1">
            <p class="m-0">Monthly Status:</p>
            <div style="padding: 2px;" class=" ml-3 border rounded" [class.border-success]=" (addPRCMEncounterDto.prCMMonthlyStatus !== PrCMMonthlyStatus['Not Started'])"
            [class.border-warning]="(addPRCMEncounterDto.prCMMonthlyStatus === PrCMMonthlyStatus['Not Started'])">
              <div class="btn-group cursor-pointer"  mdbDropdown>
                <mdb-badge style="line-height: 14px;"  mdbDropdownToggle success="true" >{{PatientEncounterMonthlyStatusTExt}}</mdb-badge>
  
                <div class="dropdown-menu status-dropdown" style="bottom: 50px;top: unset;left: -3px;">
                  <div>
                    <!-- <span [ngStyle]="{'backgroupcmMOdelDatand': item.color}"></span> -->
                    <a [class.active]="1 === value" (click)="addPRCMEncounterDto.prCMMonthlyStatus=item.value;PatientEncounterMonthlyStatusTExt=item.name;" *ngFor="let item of PRCMMonthlyStatusEnumList" class="dropdown-item">{{item.name}}</a>
                  </div>
  
                </div>
              </div>
            </div>
  
          </div>

        </div>
        <div>
          <button *ngIf="addPRCMEncounterDto.id === 0"
            [disabled]="IsaddingEncounterLoading || (addPRCMEncounterDto.prCMMonthlyStatus === PrCMMonthlyStatus['Not Started']) || !durationNO  || !addPRCMEncounterDto.note || !addPRCMEncounterDto.startTime || !addPRCMEncounterDto.endTime
            || (!addPRCMEncounterDto.prCMServiceTypeId && addPRCMEncounterDto.prCMServiceTypeId != 0)"
            type="submit" (click)="addPRCMEncounter(prcmEncounterModal)" mdbBtn class="btn btn-dynamic-2c btn-sm m-o">
            <span *ngIf="IsaddingEncounterLoading" class="spinner-border spinner-border-sm" role="status"
              aria-hidden="true"></span>
            Add Encounter</button>
          <button *ngIf="addPRCMEncounterDto.id != 0"
            [disabled]="IsaddingEncounterLoading || (addPRCMEncounterDto.prCMMonthlyStatus === PrCMMonthlyStatus['Not Started']) || !durationNO ||  !addPRCMEncounterDto.note || !addPRCMEncounterDto.startTime || !addPRCMEncounterDto.endTime
            || (!addPRCMEncounterDto.prCMServiceTypeId && addPRCMEncounterDto.prCMServiceTypeId != 0)"
            type="submit" (click)="editPRCMEncounter(prcmEncounterModal)" mdbBtn class="btn btn-dynamic-2c btn-sm m-o">
            <span *ngIf="IsaddingEncounterLoading" class="spinner-border spinner-border-sm" role="status"
              aria-hidden="true"></span>
            Edit Encounter</button>
        </div>
      </div>
    </div>
  </div>
</div>
