import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [];

@NgModule({
schemas: [CUSTOM_ELEMENTS_SCHEMA],

  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PatientDetailRoutingModule { }
