export enum PHDeviceHistoryActionType {
  "Added To Inventory" = 0,
  "Assigned To Patient" = 1,
  "Back To Inventory" = 2,
  "Changed Facility" = 3,
  "Lost To Follow" = 4,
  "Returned To Vendor" = 5,
  "Sold To Facility" = 6,
  Activated = 7,
  Deactivated = 8,
  'Activation InProgress' = 9,
  'Deactivation InProgress' = 10,
}
export enum PHDeviceStatus {
  Active = 0,
  InActive = 1,
  ActivationInProgress = 2,
  DeactivationInProgress = 3,
}
export enum InventoryStatus
{
    InHand = 0,
    Assigned = 1,
    Lost = 2,
    Malfunctioned = 3,
    'Returned To Vendor' = 4,
    InRetrieval = 5,
    InReplacement = 6,
}
