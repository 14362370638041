export class PHSCareEpisodes {
  id: number;
  name: string;
  shortName: string;
}
export class PHSFormsList {
  criticalCount: number;
  followUpCount: number;
  id: number;
  patientId: number;
  status: number;
  submittedDate: string;
  title: string;
  progress: string;
  score: number;
  scoreableNote: string;
  scoreableScript: string;
  cptCode: string;
}
export class PHSForm {
  id: number;
  title: string;
  score: number;
  submittedDate: string;
  status: PHSFormStatusEnum;
  cptCode: string;
  icdCodes: string;
  encounterClaimNote: string;
  customFormId: number;
  customForm: any;
  patientId: number;
  patient: any;
  phsFormQuestionRecords: PhsFormQuestionRecord[];
  billingProviderId: number
  tenantId: string;
  createdOn: string;
  createdUser: any;
  updatedOn: string;
  updatedUser: string;
  isActiveState: boolean;
  isDeletedState: boolean;
  scoreableNote: string;
  scoreableScript: string;
}
export class PhsFormQuestionRecord {
  id: number;
  description: string;
  toolTip: string;
  isMandatory: boolean;
  questionType: number;
  sequenceNo: number;
  phsFormRecordId: number;
  phsFormOptionRecords: PhsFormOptionRecord[];
  radioOptionValue: number; //extended
  score: number; //extended
}
export class PhsFormOptionRecord {
  id: number;
  isSelected: boolean;
  text: string;
  weight: number;
  flag: number;
  sequenceNo: number;
  narration?: string;
  isAddressed: boolean;
  addressedDate: any;
  addressedById: any;
  addressedBy: any;
  phsFormQuestionRecordId: number;
}
export class EditPHSFormRecord {
  formId: number;
  isSubmitted: boolean;
  score?: number;
  scoreableNote: string
}
export class EditPHSFormRecordOption {
  optionId: number;
  isSelected: boolean;
  questionId: number;
  text: string
}
export class AddPHSFormEncounterClaimDto {
  formId: number
  isSubmitted: boolean
  icdCodes: string
  cptCode: string
  encounterClaimNote: string
  billingProviderId: number
  timeCompleted: string
  serviceDate: string
  encounterClaimDate: string
  score?: number;
  scoreableNote: string
}


export enum PHSFormStatusEnum {
  All = -1,
  Pending = 0,
  Submitted = 1,
  Saved = 2
}