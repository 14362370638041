<div class="logo-animation logo-animation-custom" *ngIf="showLoadingSpinner">
  <div>
    <!-- <svg style="width: 100px;height:100px" xmlns="http://www.w3.org/2000/svg" width="18" height="14.528"
      viewBox="0 0 18 14.528">
      <defs>showLoadingSpinner
        <style>
          .a {
            fill: #4eaf48;
          }

          .b {
            fill: #134389;
          }
        </style>
      </defs>
      <path class="b"
        d="M5.81,12.72a6.847,6.847,0,0,1-3.63-.52A7.914,7.914,0,0,1,0,10.68a5.81,5.81,0,0,0,6,3c3.3-.27,7.08-2.13,11.08-1l.55.17C13.66,9.52,9.78,12.31,5.81,12.72Z" />
      <path class="a" d="M4.38,14.42c3.6.65,8-1.87,13.23-1C13,11.6,7.64,14.49,4.38,14.42Z" />
      <path class="a p p1"
        d="M4.59,11.67c1.57,1,3-.57,5.31-.57a7.375,7.375,0,0,0-2.67-.47A3.472,3.472,0,0,0,4.59,11.67Z" />
      <path class="a p p2" d="M3.35,9c2,2.07,4.11.54,7,1.73A10.691,10.691,0,0,0,7.18,8.35,4.683,4.683,0,0,0,3.35,9Z" />
      <path class="a p p3"
        d="M4.08,3C5,7.16,8.24,6.83,10.66,10.48a17.525,17.525,0,0,0-2-5.31A5.671,5.671,0,0,0,4.08,3Z" />
      <path class="b p p4"
        d="M8.9,0c-1,5.21,1.45,3.79,2.28,10.63.33-1.37,1.3-4.22,1.11-6.19A6.859,6.859,0,0,0,8.9,0Z" />
      <path class="b p p5"
        d="M15.7,1.75c-3.11,1.92-1.85,5.08-4,8.88a16.423,16.423,0,0,0,3.24-4A5.912,5.912,0,0,0,15.7,1.75Z" />
      <path class="b p p6" d="M18,5.91c-2.46-.07-3,3-5.7,4.94a9.255,9.255,0,0,0,3.4-1.51A5.4,5.4,0,0,0,18,5.91Z" />
    </svg> -->
    <!-- <div class="loadingio-spinner-pulse-kroekw542st"><div class="ldio-8psa3che9fu">
      <div></div><div></div><div></div>
      </div>
    </div> -->
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  style="margin: auto; background:transperant; display: block; shape-rendering: auto;width:160px !important;" width="160px" height="160px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle cx="84" cy="50" r="10" fill="#4eaf48">
          <animate attributeName="r" repeatCount="indefinite" dur="0.4716981132075471s" calcMode="spline" keyTimes="0;1" values="10;0" keySplines="0 0.5 0.5 1" begin="0s"/>
          <animate attributeName="fill" repeatCount="indefinite" dur="1.8867924528301885s" calcMode="discrete" keyTimes="0;0.25;0.5;0.75;1" values="#4eaf48;#134389;#4eaf48;#134389;#4eaf48" begin="0s"/>
      </circle>
      <circle cx="16" cy="50" r="10" fill="#4eaf48">
        <animate attributeName="r" repeatCount="indefinite" dur="1.8867924528301885s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"/>
        <animate attributeName="cx" repeatCount="indefinite" dur="1.8867924528301885s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"/>
      </circle>
      <circle cx="50" cy="50" r="10" fill="#134389">
        <animate attributeName="r" repeatCount="indefinite" dur="1.8867924528301885s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.4716981132075471s"/>
        <animate attributeName="cx" repeatCount="indefinite" dur="1.8867924528301885s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.4716981132075471s"/>
      </circle>
      <circle cx="84" cy="50" r="10" fill="#4eaf48">
        <animate attributeName="r" repeatCount="indefinite" dur="1.8867924528301885s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.9433962264150942s"/>
        <animate attributeName="cx" repeatCount="indefinite" dur="1.8867924528301885s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.9433962264150942s"/>
      </circle>
      <circle cx="16" cy="50" r="10" fill="#134389">
        <animate attributeName="r" repeatCount="indefinite" dur="1.8867924528301885s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.4150943396226414s"/>
        <animate attributeName="cx" repeatCount="indefinite" dur="1.8867924528301885s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.4150943396226414s"/>
      </circle>
      <!-- [ldio] generated by https://loading.io/ --></svg>
  </div>
</div>
<ng-container *hasClaim="['IsAuthenticated']">
  <div *ngIf="appData.extensionPatient?.patientEmrId && appData.summeryViewPatient?.patientEmrId && appData.extensionPatient?.patientEmrId != appData.summeryViewPatient?.patientEmrId">
    <div class="my-window-alert shadow">
      <span class="closebtn" (click)="ClearExtensionPatient()">&times;</span>
      <div class="d-flex align-items-center px-2">
        <strong class="mx-2"><span class="las la-exclamation-triangle fa-3x"></span></strong>
        Selected patient ({{appData.summeryViewPatient.patientEmrId}}) is different from EMR ({{appData.extensionPatient.patientEmrId}})
        <!-- Ext: {{appData.extensionPatient?.patientEmrId || 'Empty'}}, App: {{appData.summeryViewPatient?.patientEmrId || 'Empty'}} -->
      </div>
    </div>
  </div>
</ng-container>
<div id="myOverlay" class="overlay" *ngIf="isScreenLocked">
  <!-- <span class="closebtn" title="Close Overlay">×</span> -->
  <div class="overlay-content">
    <ng-form autocomplete="off">
      <input #passwordKeyRef name="saaasasa" id="passwordKeyRef" [type]="passwordKey ? 'password' : 'text'" autofocus [(ngModel)]="passwordKey"
      (keyup.enter)="validateUser();" (keypress)="passwordKey ? '': passwordKey = null" placeholder="Password..." autocomplete="off">
      <button [disabled]="unlockingProcess" type="button" (click)="validateUser()" >
        <span *ngIf="unlockingProcess" class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
        </span>
        <i class="las la-unlock-alt"></i> <span class="d-none d-sm-inline">Unlock</span>
      </button>
    </ng-form>
  </div>
</div>

<div id="myBoundsId" #myBoundsId>
  <router-outlet></router-outlet>
</div>
<ng-template #container></ng-template>
<ng-template #lazyConsentConfirmContainer></ng-template>
<ng-template #lazyConfirmContainer></ng-template>
<ng-template #lazyAppMOdalComponent></ng-template>

<app-global-iframe></app-global-iframe>
<!-- <div class="signal-state" *ngIf="connectionState "><span class="{{colorForConnectionState}}"
    title="{{hubSateEnum[connectionState]}}"></span>
</div> -->
<!-- [lockAxis]="'y'" -->
    <!-- *ngIf="rcVIew!==rcVIewEnum.hidden" -->
<!-- <div *hasClaim="['TelephonyCommunication']" class="openRingCentral cusor-pointer" (click)="rcVIew===rcVIewEnum.minimize ? rcVIew=rcVIewEnum.expand : rcVIew=rcVIewEnum.minimize">
  <div class="expanded-view">
    <span class="fa " [class.fa-angle-right]="rcVIew===rcVIewEnum.minimize" [class.fa-angle-left]="rcVIew===rcVIewEnum.expand"  ></span>
  </div>
  <img class="img-fluid  cursor-pointer" src="https://netstorage.ringcentral.com/appext/logo/TXmRITezQwat3ioy9O0kiw~f5vX_dY7SpK-_EUsEkJdqA/fe64c15a-1442-4ebf-8d24-8454afa816e4.png">
</div> -->
<!-- <div class="ngDraggable-container" ngDraggable  [zIndex]="'9999'" [zIndexMoving]="'999999'"  [handle]="dragHandle" [position]="position" [bounds]="myBounds" [outOfBounds]="edge"> -->
<div class="ngDraggable-container" style="z-index: 999999999999 !important;" cdkDrag>

  <div class="app-ringcentral-container" [class.minimize]="rcVIew==rcVIewEnum.minimize">   <!--- .minimize for minimize container --> <!--- .close for close container -->
    <div class="header" #dragHandle>
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <img height="15" src="/assets/img/logo.svg" alt="Patient Engagement">
          <p class="mb-0 ml-2 position-beta">Patient Communication</p>
        </div>

        <div >
          <button><i class="las la-minus"
            (click)="rcVIew===rcVIewEnum.minimize ? rcVIew=rcVIewEnum.expand : rcVIew=rcVIewEnum.minimize"></i></button>
          <!-- <button (click)="rcVIew=rcVIewEnum.hidden"> <i class="las la-times"></i></button> -->
        </div>
      </div>
    </div>
    <div class="body">
      <!-- <iframe width="300" height="500" id="rc-widget" allow="microphone"
        src="https://ringcentral.github.io/ringcentral-embeddable/app.html"></iframe> -->
        <iframe *ngIf="true" width="300" height="500" allow="microphone" id="rc-widget-adapter-frame"
        src="https://ringcentral.github.io/ringcentral-embeddable/app.html?stylesUri=https://app.healthforcehub.link/assets/ringcentral/style.css&clientId=YKEPZLPBSXeApnHy7aW7Aw&response_type=code&appServer=https://platform.ringcentral.com&redirectUri=https://ringcentral.github.io/ringcentral-embeddable/redirect.html">
        </iframe>
        <iframe *ngIf="false" width="300" height="500" allow="microphone" id="rc-widget-adapter-frame"
        src="https://ringcentral.github.io/ringcentral-embeddable/app.html?stylesUri=https://app.healthforcehub.link/assets/ringcentral/style.css&clientId=rPXPh6U7QJyE8mQzru7lHg&response_type=code&appServer=https://platform.devtest.ringcentral.com&redirectUri=https://ringcentral.github.io/ringcentral-embeddable/redirect.html">
        </iframe>
        <!-- <iframe width="300" height="500" allow="microphone" id="rc-widget-adapter-frame"
        src="https://ringcentral.github.io/ringcentral-embeddable/app.html?clientId=KmFe0GlaSWeGFTR-sDFGyg&appServer=https://platform.devtest.ringcentral.com&redirectUri=https://ringcentral.github.io/ringcentral-embeddable/redirect.html">
        </iframe> -->
    </div>
  </div>
</div>
