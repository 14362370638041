import { Component, Inject,OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { EmitEvent, EventBusService, EventTypes } from 'src/app/core/event-bus.service';
import { PatientDto } from 'src/app/model/Patient/patient.model';
import { BulkCommunicationOption, CommunicationMethod } from 'src/app/model/PatientEngagement/communication.model';

@Component({
  selector: 'app-initiate-bulk-comm-widget',
  templateUrl: './initiate-bulk-comm-widget.component.html',
  styleUrls: ['./initiate-bulk-comm-widget.component.scss']
})
export class InitiateBulkCommWidgetComponent implements OnInit {
  consentCounts = {
    onApp: 0,
    onSms: 0
  }
  @ViewChild("HealthFormModal") HealthFormModal: ModalDirective;
  @ViewChild("ScoreHealthFormModal") ScoreHealthFormModal: ModalDirective;

  selectedPatients: PatientDto[];
  communicationMethod = CommunicationMethod.Telephony;
  bulkCommunicationOption = BulkCommunicationOption.Template;

  CommunicationMethod = CommunicationMethod;
  BulkCommunicationOption = BulkCommunicationOption;

  selectedService: number;
  constructor(private eventBus: EventBusService) { }

  ngOnInit(): void {
  }

  OpenBulkCommSelectionModal(selected: PatientDto[]) {
    this.selectedPatients = selected.deepClone();
    this.consentCounts.onApp = this.selectedPatients.filter(x => x.isActiveMobileUser).length;
    this.consentCounts.onSms = this.selectedPatients.filter(x => x.telephonyCommunication).length;
    this.HealthFormModal.show();
  }
  OpenBulkCommWindow() {
    const emitObj = new EmitEvent();
    emitObj.name = EventTypes.TriggerGlobalIframe;
    emitObj.value = `/communicationModal/bulk-communication?hideActionBtn=true&method=${this.communicationMethod}&option=${this.bulkCommunicationOption}&patientIds=${this.selectedPatients.map(x => x.id)}&FormModule=${this.selectedService}`;
    this.eventBus.emit(emitObj);
  }

}
