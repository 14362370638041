// string-helper.ts

/**
 * Replaces placeholders in the format @Variable.Path@ within the input string
 * with corresponding values from the data object.
 *
 * @param input - The string containing placeholders.
 * @param data - The data object containing replacement values.
 * @param defaultValue - (Optional) The default value to use if a placeholder's value is not found.
 * @returns The processed string with placeholders replaced by actual values.
 */
export function replacePlaceholders(
    input: string,
    data: Record<string, any>,
    defaultValue: number = 0
  ): string {
    if (!input || typeof input !== 'string') return input;
  
    // Regular expression to find placeholders like @F.Score@
    const regex = /@([A-Za-z0-9_.]+)@/g;
  
    return input.replace(regex, (match, p1) => {
      const keys = p1.split('.');
      let replacement: any = data;
  
      for (const key of keys) {
        if (replacement && key in replacement) {
          replacement = replacement[key];
        } else {
          // If the key path does not exist, return the default value or keep the placeholder
          return defaultValue !== undefined ? defaultValue : match;
        }
      }
  
      // If the final replacement is undefined or null, return the default value or the placeholder
      return replacement != null ? replacement : (defaultValue !== undefined ? defaultValue : match);
    });
  }
  