const _translations = {
  "t1": {
    en: "Over the last 2 weeks, how often have you been bothered by feeling nervous, anxious or on edge.",
    es: "Sentirse nervioso(a), ansioso(a) o al límite."
  },
  "t2": {
    en: "Over the last 2 weeks, how often have you been bothered by not being able to stop or control worrying.",
    es: "Ser incapaz de controlar o dejar de preocuparse."
  },
  "t3": {
    en: "Over the last 2 weeks, how often have you been bothered by little interest or pleasure in doing things.",
    es: "Poco interés o placer en hacer cosas."
  },
  "t4": {
    en: "Over the last 2 weeks, how often have you been bothered by feeling down, depressed, or hopeless.",
    es: "Sentirse decaído(a), deprimido(a), o sin esperanza."
  },
  "t5": {
    en: "Not at All",
    es: "Para nada"
  },
  "t6": {
    en: "Several day",
    es: "Varios días"
  },
  "t7": {
    en: "More than half the days",
    es: "Más de la mitad de los días"
  },
  "t8": {
    en: "Nearly every day",
    es: "Casi todos los días"
  },
  // GAD-7
  "t9": {
    en: "Feeling nervous, anxious, or on edge  ",
    es: "Sentirse nervioso/a, intranquilo/a o con los nervios de punta"
  },
  "t10": {
    en: "Not being able to stop or control worrying  ",
    es: "No poder dejar de preocuparse o no poder controlar la preocupación"
  },
  "t11": {
    en: "Worrying too much about different things  ",
    es: "Preocuparse demasiado por diferentes cosas"
  },
  "t12": {
    en: "Trouble relaxing",
    es: "Dificultad para relajarse "
  },
  "t13": {
    en: "Being so restless that it is hard to sit still",
    es: "Estar tan inquieto/a que es difícil permanecer sentado/a tranquilamente"
  },
  "t14": {
    en: "Becoming easily annoyed or irritable ",
    es: "Molestarse o ponerse irritable fácilmente"
  },
  "t15": {
    en: "Feeling afraid, as if something awful might happen",
    es: "Sentir miedo como si algo terrible pudiera pasar"
  },
  // PHQ-9
  "t16": {
    en: " Little interest or pleasure in doing things",
    es: "Poco interés o placer en hacer cosas "
  },
  "t17": {
    en: "Feeling down, depressed, or hopeless ",
    es: "Se ha sentido decaído(a), deprimido(a) o sin esperanzas"
  },
  "t18": {
    en: "Trouble falling or staying asleep, or sleeping too much",
    es: "Ha tenido dificultad para quedarse o permanecer dormido(a), o ha dormido demasiado "
  },
  "t19": {
    en: " Feeling tired or having little energy ",
    es: "Se ha sentido cansado(a) o con poca energía"
  },
  "t20": {
    en: "Poor appetite or overeating",
    es: "Sin apetito o ha comido en exceso"
  },
  "t21": {
    en: "Feeling bad about yourself — or that you are a failure or have let yourself or your family down",
    es: ". Se ha sentido mal con usted mismo(a) – o que es un fracaso o que ha quedado mal con usted mismo(a) o con su familia "
  },
  "t22": {
    en: " Trouble concentrating on things, such as reading the newspaper or watching television ",
    es: "Ha tenido dificultad para concentrarse en ciertas actividades, tales como leer el periódico o ver la televisión"
  },
  "t23": {
    en: "Moving or speaking so slowly that other people could have noticed?  Or the opposite — being so fidgety or restless that you have been moving around a lot more than usual",
    es: "¿Se ha movido o hablado tan lento que otras personas podrían haberlo notado? o lo contrario – muy inquieto(a) o agitado(a) que ha estado moviéndose mucho más de lo normal "
  },
  "t24": {
    en: " Thoughts that you would be better off dead or of hurting yourself in some way ",
    es: "Pensamientos de que estaría mejor muerto(a) o de lastimarse de alguna manera "
  },
  "t25": {
    en: "Previous",
    es: "Previa"
  },
  "t26": {
    en: "Finish",
    es: "Finalizar"
  },
  // lang phq4
  "t27": {
    en: "Please take a brief moment to answer these 4 quick questions about your feelings and experiences lately. It will only take about 2 minutes. Your honest responses will help us get a better understanding of your current emotional well-being, so we can ensure you receive the support you need",
    es: "Por favor, tómese un breve momento para responder estas 4 preguntas rápidas sobre sus sentimientos y experiencias recientes. Solo tomará unos 2 minutos. Sus respuestas honestas nos ayudarán a comprender mejor su bienestar emocional actual, para asegurarnos de que reciba el apoyo que necesita."
  },
  // lang gad7
  "t28": {
    en: "Please take a few moments to answer these 7 questions about how you've been feeling over the last two weeks. This will only take 3 to 5 minutes. Your responses are important to help us understand any worries or anxiety you may be experiencing, so we can offer the right support for you.",
    es: "Por favor, tome unos momentos para responder estas 7 preguntas sobre cómo se ha sentido durante las últimas dos semanas. Esto solo tomará de 3 a 5 minutos. Sus respuestas son importantes para ayudarnos a entender cualquier preocupación o ansiedad que pueda estar experimentando, así podemos ofrecerle el apoyo adecuado."
  },
  // lang phq9
  "t29": {
    en: "Please take a moment to answer these 9 short questions about your feelings and experiences over the past two weeks. This will only take 3 to 5 minutes. Your honest responses will help us understand how you're feeling and how we can best support you.",
    es: "Por favor, dedique un momento para responder estas 9 preguntas cortas sobre sus sentimientos y experiencias durante las últimas dos semanas. Esto solo tomará de 3 a 5 minutos. Sus respuestas honestas nos ayudarán a entender cómo se siente y cómo podemos apoyarlo mejor."
  },
  "t30": {
    en: "Start",
    es: "Comenzar"
  },
  "t31": {
    en: "Introduction",
    es: "Introducción"
  },
}
export class TransLations {
  static translations = _translations;
}


