import { Component, Inject,OnInit } from '@angular/core';

@Component({
  selector: 'app-communication-layout',
  templateUrl: './communication-layout.component.html',
  styleUrls: ['./communication-layout.component.scss']
})
export class CommunicationLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
