import { Component, Inject,OnInit } from '@angular/core';

@Component({
  selector: 'app-record-not-found',
  templateUrl: './record-not-found.component.html',
  styleUrls: ['./record-not-found.component.scss']
})
export class RecordNotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
