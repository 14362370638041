import { Pipe, PipeTransform } from '@angular/core';
import { LangService } from '../core/Tools/lang.service';
import { TransLations } from '../Date-Store/Translation/translation-data';

@Pipe({
  name: 'lang',
  pure: false
})
export class LangPipe implements PipeTransform {
  constructor(private lang: LangService) {

  }
  transform(value: string): string {
    // value = value.toLowerCase();
    type ObjectKey = keyof typeof TransLations.translations;
    if (TransLations.translations[value as ObjectKey]) {
      const result = TransLations.translations[value as ObjectKey];
      return this.lang.selectedLanguage === 'es' ? result.es : result.en;
    } else {
      return value;
    }
  }

}
