import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AddPHSFormEncounterClaimDto, EditPHSFormRecord, EditPHSFormRecordOption } from '../model/health-score/health-score.model';
import { HttpErrorHandlerService } from '../shared/http-handler/http-error-handler.service';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};
@Injectable({
  providedIn: 'root'
})
export class HealthScoreService {
  baseUrl = localStorage.getItem('switchLocal') ? environment.localBaseUrl:  environment.baseUrl;

  constructor(private http: HttpClient, private httpErrorService: HttpErrorHandlerService) { }

  phsCareEpisodes() {
    return this.http.get(this.baseUrl + `PHSForm/PHSCareEpisodes`, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  getAllPHSForms(patientId: number, status: number, serviceType?: number){
    const serviceTypeParam = serviceType ? `&serviceType=${serviceType}` : '';
  return this.http.get(this.baseUrl + `PHSForm/GetAllPHSFormRecords?status=${status}&patientId=${patientId}${serviceTypeParam}`, httpOptions)
    .pipe(
        catchError(this.httpErrorService.handleHttpError)
    );
  }
  getPHSFormById(id: number){
    return this.http.get(this.baseUrl + `PHSForm/GetPHSFormRecordById/${id}`, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  addressedOptions(optionsList){
    return this.http.put(this.baseUrl + `PHSForm/AddressedOptions?optionIds=${optionsList}`, optionsList, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  editPHSFormRecord(payload: EditPHSFormRecord){
    // let obj = {  formId: formId, isSubmitted: true}
    return this.http.put(this.baseUrl + `PHSForm/EditPHSFormRecord`, payload, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  AddPHSFormEncounterClaim(dataObj: AddPHSFormEncounterClaimDto){
    return this.http.put(this.baseUrl + `PHSForm/AddPHSFormEncounterClaim`, dataObj, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  editPHSFormRecordOption(editPHSFormRecordOption: EditPHSFormRecordOption){
    return this.http.put(this.baseUrl + `PHSForm/EditPHSFormRecordOption`, editPHSFormRecordOption, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  addPHSFormRecord(data){
    return this.http.post(this.baseUrl + `PHSForm/AddPHSFormRecord`, data, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  deletePHSFormRecord(id: number){
    return this.http.delete(this.baseUrl + `PHSForm/DeletePHSFormRecord/${id}`, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  getPHSFormRecordByIdPdf(id: number){
    return this.http.get(this.baseUrl + `PHSForm/GetPHSFormRecordByIdPdf/${id}`, { responseType: 'blob' }).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  getPHSFormRecordForCopy(id: number){
    return this.http.get(this.baseUrl + `PHSForm/GetPHSFormRecordForCopy/${id}`, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
}
