export enum HESMonthlyStatusEnum {
    // All = -1,
    'Not Started' = 0,
    'Call Not Answered' = 1,
    Completed = 2,
    'Partially Completed' = 3,
  }
  
  export enum HESStatusEnum {
    NotStarted = 0,
		NotEligible = 1,
		Declined = 2,
		Deferred = 3,
		Active = 4,
		InActive = 5,
		UnableToContact = 6,
		DeclinedFurtherCare = 7,
		UnenrolledDeath = 8,
		ChangeProvider = 9,
		PleaseSchedule = 10,
		HHCDeferred = 11,
		Consented = 12,
  }
  export enum HESTypeEnum {
    CHI = 0,
    PIN = 1,
    PINPEER = 2
  }
  
  export enum HESEncounterTimeEnum {
    // All = 0,
    "HES Min 1-59" = 1,
    // "HES Min 60" = 2,
    "HES Min 60-89" = 3,
    "HES Min 90-Above" = 4
  }