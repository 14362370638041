export class NewUser {
  id = 0;
  firstName = '';
  lastName = '';
  middleName = '';
  title = '';
  email = '';
  password = '';
  confirmPassword = '';
  facilityUserId: number;
}

