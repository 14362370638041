import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { SecurityService } from 'src/app/core/security/security.service';
import { TwoCModulesEnum } from 'src/app/model/AppModels/app.model';
import { FilterPreferenceDto } from 'src/app/model/common/filter-preferences.model';
import { HttpResError } from 'src/app/model/common/http-response-error';
import { FilterPreferencesService } from './../../core/common/filter-preferences.service';

@Component({
  selector: 'app-filter-preferences',
  templateUrl: './filter-preferences.component.html',
  styleUrls: ['./filter-preferences.component.scss']
})
export class FilterPreferencesComponent implements OnInit {
  @Output() applyUserFilter = new EventEmitter<Object>();
  @ViewChild("SaveFilterModal") SaveFilterModalRef: ModalDirective;
  @Input() module: TwoCModulesEnum;

  filterName = ''
  selectedFilter = new FilterPreferenceDto()
  filterPreferences: FilterPreferenceDto[] = [];
  filterStr: string;
  addingFilters: boolean;
  gettingFilters: boolean;

  constructor(private filterPreferenceService: FilterPreferencesService, private securityService: SecurityService, private toaster: ToastService) { }

  ngOnInit() {
    this.getFilterPreferences()
  }

  getFilterPreferences() {
    this.gettingFilters = true;
    this.filterPreferenceService.getFilterPreferences(this.module, this.securityService?.securityObject?.id)
      .subscribe(
        (res: any) => {
          this.filterPreferences = res;
          this.gettingFilters = false;
        },
        (error: HttpResError) => {
          this.gettingFilters = false;
          this.toaster.error(error.error, error.message);
        }
      );
  }

  SaveFiltersData() {
    if (this.selectedFilter?.id) {
      this.UpdateFilterPreferences()
    } else {
      this.addFilterPreferences()
    }
  }

  addFilterPreferences() {
    const newFilter = new FilterPreferenceDto();
    newFilter.filter = this.filterStr;
    newFilter.name = this.filterName;
    newFilter.facilityUserId = this.securityService?.securityObject?.id;
    newFilter.service = this.module;
    newFilter.is2cDefault = false
    this.addingFilters = true;
    this.filterPreferenceService.createFilterPreference(newFilter)
      .subscribe(
        (res: any) => {
          this.addingFilters = false;
          this.getFilterPreferences()
          this.SaveFilterModalRef.hide();
        },
        (error: HttpResError) => {
          this.addingFilters = false;
          this.toaster.error(error.error, error.message);
        }
      );
  }
  UpdateFilterPreferences() {
    const newFilter = new FilterPreferenceDto();
    newFilter.filter = this.filterStr;
    newFilter.name = this.filterName;
    newFilter.facilityUserId = this.securityService?.securityObject?.id;
    newFilter.service = this.module;
    newFilter.is2cDefault = false
    this.addingFilters = true;
    this.filterPreferenceService.updateFilterPreference(this.selectedFilter?.id , newFilter)
      .subscribe(
        (res: any) => {
          this.addingFilters = false;
          this.getFilterPreferences()
          this.SaveFilterModalRef.hide();
        },
        (error: HttpResError) => {
          this.addingFilters = false;
          this.toaster.error(error.error, error.message);
        }
      );
  }

  OpenModal(filters: Object) {
    if (this.selectedFilter?.id) {
      this.filterName = this.selectedFilter.name
    } else {
      this.filterName = '';
    }
    this.filterStr = JSON.stringify(filters)
    this.SaveFilterModalRef.show();
  }

  ApplyFilter() {
    if (this.selectedFilter?.filter) {
      this.applyUserFilter.emit(JSON.parse(this.selectedFilter?.filter))
    } else {
      this.applyUserFilter.emit(null)
    }
  }

}
