import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'ng-uikit-pro-standard';
import { NgxSkltnModule } from 'ngx-skltn';
import { CarePlanViewComponent } from '../admin/patient/care-plan-view/care-plan-view.component';
import { DiagnoseComponent } from '../admin/patient/diagnose/diagnose.component';
import { MasterCarePlanComponent } from '../admin/patient/master-care-plan/master-care-plan.component';
import { MedicationComponent } from '../admin/patient/medication/medication.component';
import { ProvidersComponent } from '../admin/patient/providers/providers.component';
import { DexcomModule } from '../dexcom/dexcom.module';
import { PreviousCommunicationModalComponent } from '../patient-communication/previous-communication-modal/previous-communication-modal.component';
import { StickyNotesComponent } from '../patient/sticky-notes/sticky-notes/sticky-notes.component';
import { RpmInterventionsComponent } from '../rpm/rpm-interventions/rpm-interventions.component';
import { SharedDirectivesModule } from '../shared/shared-directives/shared-directives.module';
import { SharedModule } from '../shared/shared.module';
import { UtilityModule } from '../utility/utility.module';
import { AllergiesComponent } from './allergies/allergies.component';
import { BhiQualityCheckComponent } from './bhi-quality-check/bhi-quality-check.component';
import { BhiQuickEncounterComponent } from './bhi-quick-encounter/bhi-quick-encounter.component';
import { CcmQualityCheckComponent } from './ccm-quality-check/ccm-quality-check.component';
import { CcmQuickEncounterComponent } from './ccm-quick-encounter/ccm-quick-encounter.component';
import { HesQualityCheckComponent } from './hes-quality-check/hes-quality-check.component';
import { HesQuickEncounterComponent } from './hes-quick-encounter/hes-quick-encounter.component';
import { ImunizationComponent } from './imunization/imunization.component';
import { PatientNotificationSettingComponent } from './patient-notification-setting/patient-notification-setting.component';
import { PatientRpmAlertsComponent } from './patient-rpm-alerts/patient-rpm-alerts.component';
import { PatientRpmModalitiesComponent } from './patient-rpm-modalities/patient-rpm-modalities.component';
import { PatientSettingLayoutComponent } from './patient-setting/patient-setting-layout.component';
import { PatientSharedRoutingModule } from './patient-shared-routing.module';
import { PatientSmsvoiceConsentComponent } from './patient-smsvoice-consent/patient-smsvoice-consent.component';
import { PatientStatusChangeComponent } from './patient-status-change/patient-status-change.component';
import { PrcmQualityCheckComponent } from './prcm-quality-check/prcm-quality-check.component';
import { PrcmQuickEncounterComponent } from './prcm-quick-encounter/prcm-quick-encounter.component';
import { RpmQualityCheckComponent } from './rpm-quality-check/rpm-quality-check.component';
import { RpmQuickEncounterComponent } from './rpm-quick-encounter/rpm-quick-encounter.component';
// import { CarePlanComponent } from '../admin/patient/care-plan/care-plan.component';
// import { EditCarePlanComponent } from '../admin/patient/edit-care-plan/edit-care-plan.component';
// import { QuestionnaireComponent } from '../admin/questionnaire/questionnaire.component';
// import { ReOrderQuestionsComponent } from '../admin/re-order-questions/re-order-questions.component';

@NgModule({
schemas: [CUSTOM_ELEMENTS_SCHEMA],

  declarations: [
    DiagnoseComponent,
    // EditCarePlanComponent,
    // CarePlanComponent,
    ProvidersComponent,
    MasterCarePlanComponent,
    CarePlanViewComponent,
    MedicationComponent,
    ImunizationComponent,
    AllergiesComponent,
    StickyNotesComponent,
    PatientRpmModalitiesComponent,
    CcmQualityCheckComponent,
    RpmQualityCheckComponent,
    CcmQuickEncounterComponent,
    RpmQuickEncounterComponent,
    PatientStatusChangeComponent,
    PatientRpmAlertsComponent,
    PatientNotificationSettingComponent,
    PatientSmsvoiceConsentComponent,
    PatientSettingLayoutComponent,
    BhiQuickEncounterComponent,
    BhiQualityCheckComponent,
    PreviousCommunicationModalComponent,
    PrcmQuickEncounterComponent,
    PrcmQualityCheckComponent,
    HesQualityCheckComponent,
    HesQuickEncounterComponent,
    RpmInterventionsComponent
    // ReOrderQuestionsComponent
  ],
  imports: [CommonModule, PatientSharedRoutingModule,UtilityModule, FormsModule, SharedDirectivesModule,
    TableModule, SharedModule, DexcomModule, NgxSkltnModule],
  exports: [
    ProvidersComponent,
    DiagnoseComponent,
    // CarePlanViewComponent,
    MasterCarePlanComponent,
    MedicationComponent,
    ImunizationComponent,
    AllergiesComponent,
    StickyNotesComponent,
    PatientRpmModalitiesComponent,
    CcmQualityCheckComponent,
    RpmQualityCheckComponent,
    CcmQuickEncounterComponent,
    RpmQuickEncounterComponent,
    PatientStatusChangeComponent,
    PatientRpmAlertsComponent,
    PatientNotificationSettingComponent,
    PatientSmsvoiceConsentComponent,
    PatientSettingLayoutComponent,
    BhiQuickEncounterComponent,
    BhiQualityCheckComponent,
    PreviousCommunicationModalComponent,
    PrcmQuickEncounterComponent,
    PrcmQualityCheckComponent,
    HesQualityCheckComponent,
    HesQuickEncounterComponent,
    RpmInterventionsComponent
    // CarePlanComponent,
    // EditCarePlanComponent,
    // QuestionnaireComponent,
    // ReOrderQuestionsComponent
  ]
})
export class PatientSharedModule {}
