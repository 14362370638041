import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import moment from 'moment';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { CommunicationService } from 'src/app/core/communication.service';
import { DataFilterService } from 'src/app/core/data-filter.service';
import { EventBusService, EventTypes } from 'src/app/core/event-bus.service';
import { SecurityService } from 'src/app/core/security/security.service';
import { TwoCTimezoneService } from 'src/app/core/two-ctime-zone.service';
import { TwoCModulesEnum } from 'src/app/model/AppModels/app.model';
import { PatientDto } from 'src/app/model/Patient/patient.model';
import { CommunicationDirection, PatientCommunicationHistoryDto } from 'src/app/model/PatientEngagement/communication.model';
import { HttpResError } from 'src/app/model/common/http-response-error';

@Component({
  selector: 'app-previous-communication-modal',
  templateUrl: './previous-communication-modal.component.html',
  styleUrls: ['./previous-communication-modal.component.scss']
})
export class PreviousCommunicationModalComponent implements OnInit {
  @ViewChild("previousCommunicationModal") previousCommunicationModal: ModalDirective;
  patient = new PatientDto();
  isLoadingUnusedMessages= false;
  communicationDirectionEnum = CommunicationDirection;
  previousMonthMessages = new Array<PatientCommunicationHistoryDto>();
  currentMonthMessages = new Array<PatientCommunicationHistoryDto>();
  serviceType: number;
  isMarkingAsArchived= false;
  constructor(private toaster: ToastService,
    private eventBus: EventBusService,private commService: CommunicationService, private cdr: ChangeDetectorRef, private securityService: SecurityService,
    private dataFilterService: DataFilterService) { }

  ngOnInit(): void {
    this.subscribeOpenModalRequest();
  }
  subscribeOpenModalRequest() {
    this.eventBus.on(EventTypes.openPreviousCommunicationModal).subscribe((res: { type: string, data: { patient: PatientDto, serviceType: TwoCModulesEnum}}) => {
      if (res.type == EventTypes.openPreviousCommunicationModal.toString()) {
        this.serviceType = res.data.serviceType;
       this.patient = res.data.patient;
        this.openPreviousCommunicationModal()
      }
    });
  }
  openPreviousCommunicationModal(){
    this.previousCommunicationModal.show();
    this.getUnusedMessagesByPatientId();
  }
  getUnusedMessagesByPatientId(){
    this.isLoadingUnusedMessages = true;
    this.commService.getUnusedMessagesByPatientId(this.patient?.id).subscribe((res: {communicationDtos: any, transcripts: any}) => {
      if(res.communicationDtos.length){
        // res.communicationDtos = res.communicationDtos.filter((c) => (c.ringCentralTelephonySessionId == null && c.callRecordingTranscriptId == null) || (c.ringCentralTelephonySessionId && c.callRecordingTranscriptId !== null) )
        res.communicationDtos.forEach(element =>{
          element['isArchive'] = false;
          element['isEncounter'] = false;
          if(element.callRecordingTranscriptId){
            const callTranscription = res.transcripts.find((e) => e.id == element.callRecordingTranscriptId);
            element.message = callTranscription.transcript;
          }
        })
        this.currentMonthMessages = this.getMessagesByMonth(res.communicationDtos).currentMonthMessages;
        this.previousMonthMessages = this.getMessagesByMonth(res.communicationDtos).previousMonthMessages;
          this.currentMonthMessages?.forEach((element) => {element['isEncounter'] = true})
          this.previousMonthMessages?.forEach((element) => {element['isArchive'] = true})
          this.cdr.detectChanges();
      }
      this.isLoadingUnusedMessages = false;
    }, (err: HttpResError) => {
      this.toaster.error(err.error);
      this.isLoadingUnusedMessages = false;
    })
  }
  getMessagesByMonth(messages: PatientCommunicationHistoryDto[]): { previousMonthMessages: PatientCommunicationHistoryDto[], currentMonthMessages: PatientCommunicationHistoryDto[] } {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = new Date().getFullYear();
    const previousMonthMessages: any = [];
    const currentMonthMessages: any = [];

    messages.forEach((message) => {
      const messageDate = new Date(message.timeStamp);
      const messageMonth = messageDate.getMonth();
      const messageYear = messageDate.getFullYear();

      if (messageMonth === currentMonth && messageYear === currentYear) {
        currentMonthMessages.push(message);
      } else if (messageMonth < currentMonth && messageYear === currentYear || messageYear < currentYear) {
        previousMonthMessages.push(message);
      }
    });

    return { previousMonthMessages, currentMonthMessages };
  }
  resetMessageLists(){
    this.previousMonthMessages = new Array<PatientCommunicationHistoryDto>();
    this.currentMonthMessages = new Array<PatientCommunicationHistoryDto>();
  }
  markPreviousMessagesArchived(messagesIds: number[]){
    this.isMarkingAsArchived = true;
    this.commService.markPreviousMessagesArchived(messagesIds).subscribe((res: any) => {
      this.toaster.success('Messages marked as archived.');
      this.dataFilterService.refreshPatientsList.next('');
      this.isMarkingAsArchived = false;
    }, (err: HttpResError) => {
      this.toaster.error(err.error);
      this.isMarkingAsArchived = false;
    })
  }
  proceedToMarkAsArchived(){
    let archivedMessagesIds: number[] = [];
    const archivedPrevious = this.previousMonthMessages.filter((element) => element['isArchive'] == true);
    if(archivedPrevious.length){
      archivedPrevious.forEach((element) => archivedMessagesIds.push(element?.id));
    }
    const archivedCurrent = this.currentMonthMessages.filter((element) => element['isArchive'] == true);
    if(archivedCurrent.length){
      archivedCurrent.forEach((element) => archivedMessagesIds.push(element?.id));
    }
    if(archivedMessagesIds.length > 0){
      this.markPreviousMessagesArchived(archivedMessagesIds);
    }
  }
  proceedToMarkAsEncounter(){
    let encounterMessages: PatientCommunicationHistoryDto[] = [];
    const encounterPrevious = this.previousMonthMessages.filter((element) => element['isEncounter'] == true);
    if(encounterPrevious.length){
      encounterPrevious.forEach((element: PatientCommunicationHistoryDto) => encounterMessages.push(element));
    }
    const previousCurrent = this.currentMonthMessages.filter((element) => element['isEncounter'] == true);
    if(previousCurrent.length){
      previousCurrent.forEach((element: PatientCommunicationHistoryDto) => encounterMessages.push(element));
    }
    this.previousCommunicationModal.hide();
    if(encounterMessages.length > 0){
      encounterMessages = encounterMessages.reverse();
      encounterMessages = encounterMessages.filter(x => x.serviceType == null)
      encounterMessages.forEach(item => {
      item.shortCode = item.senderName?.getShortCode();
      item.timeStampFormatted = moment.utc(item.timeStamp).tz(TwoCTimezoneService?.currentTimeZone || moment.tz.guess()).format('MMM DD,\\ hh:mm A');
      item.selected = true
    });
    let copyText = ``;
    let facilityName = this.securityService?.getClaim('FacilityName')?.claimValue
    encounterMessages.forEach(x => {
      if (x.selected) {
        const nTime = moment(x.timeStamp).format('DD MMM')
        copyText += `${nTime} ${x.senderName}@${facilityName || ''}:  ${x.message || ''} \n`;
      }
    });
    navigator.clipboard.writeText(copyText);
    if(this.serviceType == TwoCModulesEnum.CCM){
      this.commService.OpenCCMEncounterModel(this.patient, copyText, encounterMessages, 'previousChatModal')
    }
    if(this.serviceType == TwoCModulesEnum.RPM){
      this.commService.OpenRPMEncounterModel(this.patient, copyText, encounterMessages, 'previousChatModal')
    }
    if(this.serviceType == TwoCModulesEnum.BHI){
      this.commService.OpenBHIEncounterModel(this.patient, copyText, encounterMessages, 'previousChatModal')
    }
    if(this.serviceType == TwoCModulesEnum.HES){
      this.commService.OpenHESEncounterModel(this.patient, copyText, encounterMessages, 'previousChatModal')
    }
    }
  }
  onPreviousArchiveCheckboxChange(item, isArchiveChecked: boolean) {
    if(isArchiveChecked){
      item.isEncounter = !isArchiveChecked;
    }
  }
  onPreviousEncounterCheckboxChange(item, isEncounterChecked: boolean) {
    if(isEncounterChecked){
      item.isArchive = !isEncounterChecked;
    }
  }
}
