import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BulkCampaignsComponent } from './bulk-campaigns/bulk-campaigns.component';
import { BulkCommunicationLogsComponent } from './bulk-communication-logs/bulk-communication-logs.component';
import { CommunicationDetailComponent } from './communication-detail/communication-detail.component';
import { CommunicationLayoutComponent } from './communication-layout/communication-layout.component';
import { CommunicationTemplatesComponent } from './communication-templates/communication-templates.component';
import { CommunicationsListComponent } from './communications-list/communications-list.component';
import { NewBulkCommunicationComponent } from './new-bulk-communication/new-bulk-communication.component';


const routes: Routes = [
  { path: '', redirectTo: 'list', pathMatch: 'full' },
  { path: 'list', component: CommunicationsListComponent },
  { path: 'detail', component: CommunicationDetailComponent },
  { path: 'layout', component: CommunicationLayoutComponent },
  { path: 'bulk-communication', component: NewBulkCommunicationComponent },
  { path: 'bulk-communication-logs', component: BulkCommunicationLogsComponent },
  { path: 'bulk-communication-campaigns', component: BulkCampaignsComponent },
  { path: 'manage-template', component: CommunicationTemplatesComponent },

];

@NgModule({
schemas: [CUSTOM_ELEMENTS_SCHEMA],

  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PatientCommunicationRoutingModule { }
