export enum CustomFormQuestionType {
  "Yes/No" = 0,
  "Linear Scale" = 1,
  "Single Select" = 2,
  "Multi Select" = 3,
  Descriptive = 4
}
export enum CustomFormQuestionOptionFlag {
  "Not Required" = 0,
  Satisfactory = 1,
  FollowUp = 2,
  Critical = 3,
}
// export enum CustomFormStatus {
//   "In Process" = 0,
//   Published = 1,
// }
export enum CustomFormStatus {
  All = -1,
  Pending = 0,
  Submitted = 1,
}
export enum CustomFormType {
  "Patient Health Score" = 0,
  "Advance Care Planning" = 1,
  "NeuroCog Assessment" = 2,
}
