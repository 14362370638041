import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpErrorHandlerService } from "../shared/http-handler/http-error-handler.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs";
import {
  AccessorySaleOrderDto,
  AddFacilityAccessoryPricingDto,
  EditAccessoryPricing,
  EditFacilityAccessoryPricingDto,
} from "../model/accessories.model";
import { AccessoryRequestDto, DeviceRequestStatus, FilterDeviceRequestDto, ShippingServiceEnum } from "../model/Inventory/rpm-inventory.model";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class AccessoriesService {
  private baseUrl = localStorage.getItem("switchLocal")
    ? environment.localBaseUrl
    : environment.baseUrl;
  constructor(
    private httpErrorService: HttpErrorHandlerService,
    private http: HttpClient
  ) {}
  GetAccessories() {
    return this.http
      .get(this.baseUrl + `Accessories/GetAccessories`, httpOptions)
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  EditAccessoryPricing(obj: EditAccessoryPricing) {
    return this.http
      .put(this.baseUrl + `Accessories/EditAccessoryPricing`, obj, httpOptions)
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  GetAccessoryPricingsByFacilityId(facilityId: number) {
    return this.http
      .get(
        this.baseUrl +
          `Accessories/GetAccessoryPricingsByFacilityId/${facilityId}`,
        httpOptions
      )
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  EditFacilityAccessoryPricing(obj: EditFacilityAccessoryPricingDto) {
    return this.http
      .put(
        this.baseUrl + `Accessories/EditFacilityAccessoryPricing`,
        obj,
        httpOptions
      )
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  AddFacilityAccessoryPricing(obj: AddFacilityAccessoryPricingDto) {
    return this.http
      .post(
        this.baseUrl + `Accessories/AddFacilityAccessoryPricing`,
        obj,
        httpOptions
      )
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  CreateAccessoryRequest(obj: AccessoryRequestDto) {
    return this.http
      .post(
        this.baseUrl + `AccessoryRequest/CreateAccessoryRequest`,
        obj,
        httpOptions
      )
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  getAccessoryRequests(reqParm: FilterDeviceRequestDto) {
    let rQ = '';
    if (reqParm.FacilityId) {
      rQ += `FacilityId=${reqParm.FacilityId}&`
    }
    if (reqParm.RequestStatus || reqParm.RequestStatus.some(x => x == DeviceRequestStatus.Open)) {
      rQ += `RequestStatus=${reqParm.RequestStatus}&`
    }
    if (reqParm.StartDate) {
      rQ += `StartDate=${reqParm.StartDate}&`
    }
    if (reqParm.EndDate) {
      rQ += `EndDate=${reqParm.EndDate}&`
    }
    if (reqParm.Assignee) {
      rQ += `Assignee=${reqParm.Assignee}&`
    }
    return this.http
      .get(
        this.baseUrl + `AccessoryRequest?${rQ}`,
        httpOptions
      )
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  getAccessoryRequestById(id: number) {
    return this.http
      .get(
        this.baseUrl + `AccessoryRequest/GetAccessoryRequestBy/${id}`,
        httpOptions
      )
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  assignRequestToUser(obj: any) {
    return this.http
      .put(
        this.baseUrl + `AccessoryRequest/AssignRequestToUser?requestId=${obj.requestId}&userId=${obj.userId}`,
        httpOptions
      )
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  getTrackingIdShippingDate(trackingId: string, shippingService: ShippingServiceEnum){
    return this.http
      .get(
        this.baseUrl + `AccessoryRequest/GetTrackingIdShippingDate/${trackingId}?shippingService=${shippingService}`,
        httpOptions
      )
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  createAccessorySaleOrder(saleOrderDto: AccessorySaleOrderDto){
    return this.http
      .post(
        this.baseUrl + `AccessoryRequest/CreateAccessorySaleOrder`, saleOrderDto,
        httpOptions
      )
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  EditSaleOrderTrackingId(orderId: number, trackingId: string) {
    return this.http.put(this.baseUrl + `AccessoryRequest/EditSaleOrderTrackingId/${orderId}?trackingId=${trackingId}`, {} , httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
}
