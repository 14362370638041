import moment from "moment";
import {
  BhiEncounterTimeEnum,
  BhiMonthEnum,
  BhiMonthlyStatus,
  BhiStatusEnum,
} from "src/app/Enums/bhi.enum";
import { SortOrder } from "src/app/Enums/filterPatient.enum";

export class SectionEqual30 {
  totalCount: number;
  count_1_9: number;
  count_10_19: number;
  count_20_29: number;
}

export class SectionAbove30 {
  totalCount: number;
  count_30_39: number;
  count_40_49: number;
  count_50_59: number;
}

export class BhiDashboardDto {
  activePatients: number;
  activeInitialPatients: number;
  activeSubsequentPatients: number;
  notStartedPatients: number;
  partiallyCompletedPatients: number;
  callNotAnsweredPatients: number;
  completedPatients: number;
  initial_Count_1_30: number;
  initial_Count_31_70: number;
  initial_Count_71_100: number;
  initial_Count_100_plus: number;
  subsequent_Count_1_30: number;
  subsequent_Count_31_60: number;
  subsequent_Count_61_90: number;
  subsequent_Count_90_plus: number;
}
export class AssignDateToMultiplePatientsDto {
  patientIds = new Array<number>();
  // careProviderIdsToAssign = new Array<number>();
  // careProviderIdsToRemove = new Array<number>();
  // careFacilitatorId = 0;
  dateAssigned = "";
}
export class BhiPatientsScreenParams {
  pageNumber = 1;
  searchParam = "";
  pageSize = 10;
  customListId = 0;
  bhiStatus = [BhiStatusEnum["Active CoCM"], BhiStatusEnum["Not Started"], BhiStatusEnum.Consented, BhiStatusEnum["Follow Up"]];
  bhiMonth = BhiMonthEnum.All;
  bhiCareManagerId = 0;
  PsychiatristId = 0;
  facilityId = 0;
  facilityUserId: number;
  serviceMonth = (moment().month()) + 1;
  serviceYear = moment().year();
  diseaseId = 0;
  sortBy = "";
  showAll = false;
  sortOrder: SortOrder = 0;
  BhiEncounterTime: BhiEncounterTimeEnum[] = [BhiEncounterTimeEnum.All];

  conditionsIds = ["0"];
  chronicDiseasesIds: any = [""];
  Assigned = 0;
  bhiMonthlyStatus = [""];
  billingProviderId = 0;
  careCoordinatorId = 0;
  DateAssignedFrom = "";
  DateAssignedTo = "";
  departmentIds = [""];
  // insurancePlanId = 0;
  // measureIds: number[] = [];
  // status: GapStatus = -1;
  // lastDoneFrom: Date | string | null = '';
  // lastDoneTo: Date | string | null = '';
  // nextDueFrom: Date | string | null = '';
  // nextDueTo: Date | string | null = '';
  // chronicConditionIds: number[] = [];
}

export class BhiDashboardFilter {
  facilityId = 0;
  careProviderId = 0;
  billingProviderId = 0;
  careFacilitatorId = 0;
  bhiStatus = -1;
}

export class EditBhiData {
  patientId = 0;
  bhiCareCoordinatorIds = new Array<number>();
  bhiCareManagerId = 0;
  bhiPsychiatristId = 0;
  gbhiPsychiatrist = "";
  bhiStatus = 0;
  billingProviderId: number;
}
export class BhiStatusDto {
  patientId = 0;
  bhiStatus: number;
  reason: string;
}
export class BhiMonthlyStatusDto {
  patientId: number;
  newStatusValue: number;
  appUserId: string;
}
export class BhiEncounterDto {
  id = 0;
  startTime: string;
  endTime: string;
  encounterDate: string;
  duration: string;
  note = "";
  bhiServiceTypeId = 0;
  patientId = 0;
  cptCode = "";
  bhiCareManagerId: number;
  psychiatristId: number;
  bhiCareCoordinatorId: number;
  gbhiPsychiatrist = "";
  isProviderEncounter = false;
  bhiMonthlyStatus = 4;
  patientCommunicationIds: number[] = [];
}
export class BhiPatientsListDto {
  isBHIRevoked: boolean;
  isBhiConsentTaken: boolean;
  id: number;
  patientEmrId: string;
  profileStatus: boolean;
  checked: boolean;
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  city: string;
  lastStatusChangedByNameAndDate: string;
  bhiMsQualityChecked: boolean;
  bhiStatus: BhiStatusEnum;
  bhiCareManagerId: number;
  bhiCareManagerName?: any;
  bhiCareManagerAbbreviation?: any;
  psychiatristId: number;
  psychiatristName?: any;
  psychiatristAbbreviation?: any;
  chronicDiseasesIds: number[];
  currentMonthCompletedTime: number;
  currentMonthCompletedTimeString: string;
  chronicDiseases = new Array<CodeDetailModel>();
  bhiCareCoordinators = new Array<BhiCoordinatorsDto>();
  facilityId: number;
  gbhiPsychiatrist = "";
  dateAssigned: string;
  lastBhi: string;
  billingProviderAbbreviation: string;
  billingProviderName: string;
  billingProviderId: number;
  telephonyCommunication: boolean;
  unAssociatedCommunication: number;
  bhiMonthlyStatus: BhiMonthlyStatus;
  bhiMonth: BhiMonthEnum;
  qualityCheckStatus: number;
  bhiMsQualityCheckedByName: string;
  bhiMsQualityCheckedDate: string;
}
export class BhiCoordinatorsDto {
  bhiCareCoordinatorId: number;
  fullName: string;
  nameAbbreviation: string;
}
export class CodeDetailModel {
  code = "";
  detail = "";
}
export class BHIUploadDocDto {
  title: string;
  note: string;
  dateCreated: string | Date;
}
export class DocListDto {
  id: number;
  title: string;
  path: string;
  dateCreated: string | Date;
  note: string;
  patientId: number;
}
export class BhiEncountersListDto {
  id: number;
  startTime: string;
  endTime: string;
  duration: string;
  encounterDate: string;
  note: string;
  bhiServiceType: number;
  claimGenerated: boolean;
  bhiCareManagerId: number;
  bhiCareManagerName: string;
  bhiCareCoordinatorId: number;
  bhiCareCoordinatorName: string;
  psychiatristId: number;
  cptCode: string;
  psychiatristName: string;
  patientId: number;
  isProviderEncounter: boolean;
  gbhiPsychiatrist = "";
  bhiMonthlyStatus: number;
}

export class DownloadBHIPdfZipDto {
  facilityId = 0;
  monthId = new Date().getMonth();
  yearId = new Date().getFullYear();
  patientIds = new Array<number>();
  includeCarePlan = false;
  includeLogsHistory = true;
  includeScreenTool = false;
  isActive = false;
}

export class BHIEncounterListDto {
  bhiEncountersList: BhiEncounterDto;
  bhiTimeCompleted: string;
}

export enum BHIServiceTypeEnum {
  "Evaluation Counselling" = 0,
  "Initial Assessment" = 1,
}
export class BHIQualityCheckMOdalDto {
  patientId: number;
  isPrDashboard = false;
  serviceMonth = moment().month() + 1;
  serviceYear = moment().year()
}
export class PsychiatristNotes {
  id: number;
  notes: string;
  noteDate: string;
  psychiatristId: number;
}
export class PsychiatristNotesList {
  id: number;
  notes: string;
  noteDate: string;
  psychiatristId: number;
  psychiatristName: string;
}
export class BhiStatusHistoryDto {
  id: number;
  status: number;
  name: string;
  dateModified: string;
  reason: string;
}