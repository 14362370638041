export class ToDoNoteDto {
  id = 0;
  description = '';
  patientName = '';
  dateCreated = '';
  status = '';
  userId = '';
  patientId = 0;
  title = '';
}

export class AddToDoNoteDto {
         id = 0;
         description = "";
         userId = "";
         patientId = 0;
         title = "";
         dateCreated = '';
       }
