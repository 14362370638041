import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import moment from "moment";
import { ModalDirective, ToastService } from "ng-uikit-pro-standard";
import { ECalendarValue, IDatePickerDirectiveConfig } from "ng2-date-picker";
import { UserType } from "src/app/Enums/UserType.enum";
import { BhiMonthEnum, BhiMonthlyStatus } from "src/app/Enums/bhi.enum";
import { SubSink } from "src/app/SubSink";
import { BhiService } from "src/app/core/bhi.service";
import { DataFilterService } from "src/app/core/data-filter.service";
import { EmitEvent, EventBusService, EventTypes } from "src/app/core/event-bus.service";
import { FacilityService } from "src/app/core/facility/facility.service";
import { SecurityService } from "src/app/core/security/security.service";
import { TwoCModulesEnum } from "src/app/model/AppModels/app.model";
import { BhiEncounterDto } from "src/app/model/Bhi/bhi.model";
import { CreateFacilityUserDto } from "src/app/model/Facility/facility.model";
import { PatientDto } from "src/app/model/Patient/patient.model";
import { HttpResError } from "src/app/model/common/http-response-error";
import { TwoCTextAreaComponent } from "src/app/utility/two-c-text-area/two-c-text-area.component";

@Component({
  selector: "app-bhi-quick-encounter",
  templateUrl: "./bhi-quick-encounter.component.html",
  styleUrls: ["./bhi-quick-encounter.component.scss"],
})
export class BhiQuickEncounterComponent implements OnInit, OnDestroy {
  @ViewChild('bhiMonthStatusModal') bhiMonthStatusModal: ModalDirective;
  @ViewChild("bhiEncounterModal") bhiEncounterModal: ModalDirective;
  @ViewChild('myFIeldRefBHI') myFIeldRefBHI: TwoCTextAreaComponent;
  @Output() encounterSaved = new EventEmitter();
  public datePickerConfig1: IDatePickerDirectiveConfig = {
    allowMultiSelect: false,
    returnedValueType: ECalendarValue.StringArr,
    format: "MM/DD/YYYY",
    // appendTo: "body",
    drops: "down",
  };
  public timePickerConfig: IDatePickerDirectiveConfig = {
    allowMultiSelect: false,
    returnedValueType: ECalendarValue.StringArr,
    format: "hh:mm A",
  };
  bhiMonthStatus: BhiMonthEnum;
  bhiMonthlyStatusEnum = BhiMonthlyStatus;
  bhiMonthlyStatusList = this.filterDataService.getEnumAsList(BhiMonthlyStatus);
  PatientEncounterMonthlyStatusTExt =
    BhiMonthlyStatus[BhiMonthlyStatus["Not Started"]];
  bhiEncounterDto = new BhiEncounterDto();
  psychiatristFacilityUserList = new Array<CreateFacilityUserDto>();
  facilityUsersList = new Array<CreateFacilityUserDto>();
  loadingPsy = false;
  facilityId: number;
  psychiatristId: any;
  durationNO: number;
  patientDetails = new PatientDto();
  isAddingEncounterLoading = false;
  private subs = new SubSink();
  constructor(
    private eventBus: EventBusService,
    private bhiService: BhiService,
    private toaster: ToastService,
    private facilityService: FacilityService,
    private securityService: SecurityService,
    private filterDataService: DataFilterService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.facilityId = +this.securityService.getClaim("FacilityId").claimValue;
    this.subscribeOpenModalRequest();
    this.getFacilityUsers();
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
  subscribeOpenModalRequest() {
    this.subs.sink = this.eventBus
      .on(EventTypes.OpenBHIQuickEncounter)
      .subscribe(
        (res: {
          type: string;
          data: { patient: PatientDto; encounterObj: BhiEncounterDto };
        }) => {
          if (res.type == EventTypes.OpenBHIQuickEncounter.toString()) {
            var isPsychiatrist = false;
            var isCareProvider = false;
            this.patientDetails = res.data.patient;
            this.GetBhiCocmMonth()

            const time = moment().format("hh:mm A");
            this.bhiEncounterDto.startTime = time;
            const date1 = moment().format("MM/DD/YYYY");
            this.bhiEncounterDto.encounterDate = date1;
            this.bhiEncounterDto.duration = res.data.encounterObj.duration;
            this.durationNO = +res.data.encounterObj.duration;
            this.durationChanged(this.durationNO);
            this.bhiEncounterDto.patientCommunicationIds = res.data.encounterObj.patientCommunicationIds || [];
            this.bhiEncounterDto.note = res.data.encounterObj.note;
            this.FillNoteText(res.data.encounterObj.note);
            if(this.securityService.getClaim('IsCareCoordinator')?.claimValue){
              this.bhiEncounterDto.bhiCareCoordinatorId = this.securityService.securityObject.id;
            }
            if(this.securityService.getClaim('IsPsychiatrist')?.claimValue){
              this.bhiEncounterDto.psychiatristId = this.securityService.securityObject.id;
            }
            this.cdr.detectChanges();
            this.OpenAddEncounterModal(
              res.data?.patient,
              res.data?.encounterObj
            );
          }
        }
      );
  }
  OpenAddEncounterModal(patientData, encounterObj) {
    this.bhiEncounterModal.show();
  }
  getFacilityUsers() {
    this.loadingPsy = true;
    this.facilityService.getFacilityUserList(this.facilityId).subscribe(
      (res: []) => {
        this.facilityUsersList = res;
        if(this.securityService.securityObject.userType == UserType.CareProvider){
          this.bhiEncounterDto.bhiCareCoordinatorId = this.securityService.securityObject.id;
          this.bhiEncounterDto.psychiatristId = this.securityService.securityObject.id;
        }
        this.loadingPsy = false;
      },
      (error: HttpResError) => {
        this.loadingPsy = false;
        this.toaster.error(error.error, error.message);
      }
    );
  }
  durationChanged(minsToAdd: number) {
    const startTime = this.bhiEncounterDto.startTime;
    let newTime = moment(startTime, 'hh:mm A').add(minsToAdd, 'minutes').format('hh:mm A');
    this.bhiEncounterDto.endTime = newTime;
  }
  resetBhiEncounter() {
    this.bhiEncounterDto = new BhiEncounterDto();
    this.durationNO = null;
    this.FillNoteText('');
  }
  addBhiEncounter() {
    this.isAddingEncounterLoading = true;
    this.bhiEncounterDto.gbhiPsychiatrist = "";
    this.bhiEncounterDto.patientId = this.patientDetails.id;
    this.bhiService.AddBhiEncounter(this.bhiEncounterDto).subscribe(
      (res: []) => {
        this.toaster.success("Encounter added successfully");
        this.isAddingEncounterLoading = false;
        this.refreshBhiPatientsList(this.bhiEncounterDto);
        this.bhiEncounterModal.hide();
        this.resetBhiEncounter();
        this.encounterSaved.emit();
      },
      (error: HttpResError) => {
        this.isAddingEncounterLoading = false;
        this.toaster.error(error.error, error.message);
      }
    );
  }
  FillNoteText(text: string) {
    if (this.myFIeldRefBHI?.FillValue) {
      this.myFIeldRefBHI.FillValue(text);
    } else {
      setTimeout(() => {
        if (this.myFIeldRefBHI?.FillValue) {
          this.myFIeldRefBHI.FillValue(text);
        }
      }, 1000);
    }
  }
  refreshBhiPatientsList(bhiEncounterDto: BhiEncounterDto){
    const emitObj = new EmitEvent();
    emitObj.name = EventTypes.CommunicationEncounterEdit;
    emitObj.value = {
      type: EventTypes.CommunicationEncounterEdit.toString(),
      data: {
        patientId: bhiEncounterDto.patientId,
        patientCommunicationIds: bhiEncounterDto.patientCommunicationIds,
        serviceType: TwoCModulesEnum.BHI,
        encounterObj: bhiEncounterDto
      },
    };
    this.eventBus.emit(emitObj);
  }
  GetBhiCocmMonth() {
    this.bhiService.GetBhiCocmMonth(this.patientDetails.id).subscribe((res: {bhiMonth: BhiMonthEnum}) => {
        if (res) {
          this.bhiMonthStatus = res.bhiMonth;
          if (this.bhiMonthStatus === BhiMonthEnum.UnKnown) {
            this.bhiMonthStatusModal.show();
          }
          if (this.bhiMonthStatus === BhiMonthEnum['First Month']) {
            this.bhiEncounterDto.cptCode = '99492';
          }
          if (this.bhiMonthStatus === BhiMonthEnum['Subsequent Month']) {
            this.bhiEncounterDto.cptCode = '99493';
          }

        }
      },
      (error: HttpResError) => {
        this.toaster.error(error.error, error.message);
      }
    );
  }
  SetBhiCocmMonth() {
    this.bhiService.SetBhiCocmMonth(this.patientDetails?.id, this.bhiMonthStatus).subscribe((res: any) => {
        if (res) {
        }
        this.bhiMonthStatusModal.hide();
      },
      (error: HttpResError) => {
        this.toaster.error(error.error, error.message);
      }
    );
  }
}
