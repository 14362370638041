<div class="login">
  <div class="login-body">
    <div class="w-100">
      <div class="text-center ccm-logo">
        <img src="{{brandingService.logoPath}}logo-1024-265.png" style="max-width: 300px" alt="{{brandingService.appTitle}}">
      </div>
      <!-- <h3 class="text-dynamic-2c text-center site-name">2C health Solutions</h3> -->
      <div class="login-box">
        <!-- <strong>Sign In</strong> -->
        <p>Welcome Back! Please Sign in to continue.</p>
        <form (ngSubmit)="Login()" [formGroup]="logInForm">
          <div class="form-group">
            <label for="defaultLoginFormEmail">UserName</label>
            <input type="email" id="defaultLoginFormEmail" formControlName="email" class="form-control"
              placeholder="UserName" [ngClass]="{'is-invalid':(logInForm.get('email')?.touched ||
                                    logInForm.get('email')?.dirty) &&
                                    !logInForm.get('email')?.valid }">

            <span class="invalid-feedback">
              <span *ngIf="!!logInForm.get('email').errors?.['required']">
                Please enter your UserName.
              </span>

            </span>
          </div>
          <div class="form-group position-relative">
            <label for="defaultLoginFormPassword">Password</label>
            <input [type]="show ? 'text' : 'password'" id="defaultLoginFormPassword" formControlName="password"
              class="form-control" placeholder="Password" [ngClass]="{'is-invalid': (logInForm.get('password')?.touched
                                          || logInForm.get('password')?.dirty)
                                          && !logInForm.get('password')?.valid }">
            <a *ngIf="logInForm.get('password')?.value" (click)="password();" class="input-group-append custom-append">
              <span class="input-group-text text-white bg-dynamic-2c" id="category"><i class="las la-eye"></i></span>
            </a>
            <span class="invalid-feedback">
              <span *ngIf="!!logInForm.get('password').errors?.['required']">
                Enter your password.
              </span>
            </span>
          </div>
          <div class="d-flex justify-content-between mb-3">
            <div>
            </div>
            <div>
              <a (click)="goToForgetPW()" routerLinkActive="router-link-active">Forgot password?</a>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <button type="submit" [disabled]="loggingIn || !logInForm.valid" class="btn btn-md btn-dynamic-2c w-100 m-0">
              <span *ngIf="loggingIn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Login</button>
          </div>

        </form>
        <!-- <button  (click)="loginAttemptWarningModal.show()" >ABCDß†</button> -->
      </div>

    </div>
  </div>

</div>
<div mdbModal #twoFactorConfModal="mdbModal" (close)="codeString='';sendCodeMethod = null" class="modal fade" tabindex="-1" role="dialog"
  [config]="{ignoreBackdropClick: true}" aria-labelledby="mytwoFactorConfModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="twoFactorConfModal.hide()">
          <span aria-hidden="true">×</span>
        </button> -->
        <h4 class="modal-title w-100 text-center" id="myModalLabel">Verify your identity</h4>
      </div>
      <div class="modal-body" *ngIf="twoFactorConfModal.isShown">
        <!-- <div class="alert alert-info p-2 pb-3">
          <a class="close font-weight-normal initialism" data-dismiss="alert" href="#"><samp>&times;</samp></a>
          CVC code is required.
        </div> -->
        <div *ngIf="twoFactorLoginProcess" class="d-flex justify-content-center text-center">
          <mdb-progress-bar class="progress primary-color-dark-s mb-0" mode="indeterminate"></mdb-progress-bar>
        </div>
        <form (ngSubmit)="LoginWithTwoFA()">
          <ng-container *ngIf="showSendEmailPasswordView">
            <br>
            <div>
              <label for="12qs">Select the option below to request a verification code.</label>
            </div>
            <div class="form-check form-check-inline mb-2">
              <input [disabled]="!isPhoneVerified" type="radio" class="form-check-input" id="materialInline1" name="sms"
                [(ngModel)]="sendCodeMethod" [value]="0" mdbInput>
              <label class="form-check-label" [ngClass]="{rowBackgroundColorDiv: !isPhoneVerified}" for="materialInline1">SMS</label>
            </div>

            <!-- Material inline 2 -->
            <div class="form-check form-check-inline mb-2">
              <input [disabled]="!isEmailVerified" type="radio" class="form-check-input" id="materialInline2" name="email"
                [(ngModel)]="sendCodeMethod" [value]="1" mdbInput>
              <label class="form-check-label" [ngClass]="{rowBackgroundColorDiv: !isEmailVerified}" for="materialInline2">Email</label>
            </div>
            <div>
              <small class="text-danger" *ngIf="!isEmailVerified && isPhoneVerified">Your Email is not verified.</small>
              <small class="text-danger" *ngIf="!isPhoneVerified && isEmailVerified">Your Phone Number is not verified.</small>
              <small class="text-danger" *ngIf="!isEmailVerified && !isPhoneVerified">Your Email and Phone Number is not verified.</small>
            </div>
            <!-- <button type="button" mdbBtn color="secndary" class="waves-light btn-sm" aria-label="Close"
              (click)="SendCode()" mdbWavesEffect>Send</button> -->
          </ng-container>

          <!-- <div class="form" *ngIf="qrCOdeString">
            <div class="form-group">
              <div class="qrcode d-flex justify-content-center">
                <qrcode [qrdata]="qrCOdeString" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
              </div>
            </div>
          </div> -->
          <ng-container *ngIf="!showSendEmailPasswordView">
            <div class="form">
              <div class="form-group mb-5">
                <label for="twofacCOdeid">Enter Authentication Code</label>
                <input type="text" class="form-control" [(ngModel)]="codeString" name="auth" id="twofacCOdeid" required="" autocomplete="off">
                <label for="sasdaas" class="pull-left text-dynamic-2c c-pointer mt-2" (click)="showSendEmailPasswordView=true;isEmailVerified = TwoFASecurityObject.isEmailVerified; isPhoneVerified = TwoFASecurityObject.isPhoneVerified">Don't have authentication code?</label>
              </div>
            </div>
          </ng-container>
          

          <div class="modal-footer">
            <button type="button" class="waves-light btn btn-sm btn-dynamic-secondary-2c" aria-label="Close"
              (click)="twoFactorConfModal.hide()" mdbWavesEffect>Close</button>
            <button type="button" *ngIf="showSendEmailPasswordView" [disabled]="(!isEmailVerified && !isPhoneVerified) || (sendCodeMethod !== 0 && sendCodeMethod !== 1) " class="waves-light btn btn-sm btn-dynamic-2c" aria-label="Close"
              (click)="SendCode()" mdbWavesEffect>Send</button>
            <button type="submit" *ngIf="!showSendEmailPasswordView" [disabled]="twoFactorLoginProcess || !codeString"
              class="relative waves-light btn btn-sm btn-dynamic-2c" mdbWavesEffect>
              <span *ngIf="twoFactorLoginProcess" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
              Proceed</button>
            <!-- <button *ngIf="qrCOdeString" type="submit" [disabled]="twoFactorLoginProcess || !codeString" mdbBtn color="priary"
            class="relative waves-light btn-sm" (keyup.enter)="RegisterAuthenticator(twoFactorConfModal)" mdbWavesEffect>
            <span *ngIf="twoFactorLoginProcess" class="spinner-border spinner-border-sm" role="status"
              aria-hidden="true"></span>
            Proceed</button> -->
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div mdbModal #changePasswordModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  [config]="{ignoreBackdropClick: true}" aria-labelledby="mychangePasswordModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" >
      <div class="modal-header">
        <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="changePasswordModal.hide()">
          <span aria-hidden="true">×</span>
        </button> -->
        <h4 class="modal-title w-100 text-center" id="myModalLabel">Change Password</h4>
      </div>
      <div class="modal-body" *ngIf="changePasswordModal.isShown">
        <!-- <div class="alert alert-info p-2 pb-3">
          <a class="close font-weight-normal initialism" data-dismiss="alert" href="#"><samp>&times;</samp></a>
          CVC code is required.
        </div> -->
        <form class="form" role="form" autocomplete="off" [formGroup]="changePasswordForm">
          <div class="form-group">
            <label for="inputPasswordOld">Current Password</label>
            <div class="input-group form-group mb-0">
              <input type="password" class="form-control form-control-sm" formControlName="oldPassword" id="inputPasswordOld" required="">
              <a class="input-group-append">
                <span  class="input-group-text text-white bg-dynamic-2c" id="category" *ngIf="true">
                  <i class="las la-eye"></i>
                </span>
                <span class="input-group-text text-white bg-dynamic-2c" id="category" *ngIf="false">
                  <i class="las la-eye-slash"></i>
                </span>
              </a>
          </div>
            
          </div>
          <div class="form-group">
            <label for="inputPasswordNew">New Password</label>
            <div class="input-group form-group mb-0">
              <input type="password" class="form-control form-control-sm" formControlName="newPassword" id="inputPasswordNew" required="">
              <a class="input-group-append">
                <span  class="input-group-text text-white bg-dynamic-2c" id="category" *ngIf="true">
                  <i class="las la-eye"></i>
                </span>
                <span class="input-group-text text-white bg-dynamic-2c" id="category" *ngIf="false">
                  <i class="las la-eye-slash"></i>
                </span>
              </a>
          </div>
            <span class="form-text small text-muted">
              The password must be 8-20 characters, and must <em>not</em> contain spaces.
            </span>
          </div>
          <div class="form-group">
            <label for="inputPasswordNewVerify">Verify</label>
            <div class="input-group form-group mb-0">
              <input type="password" class="form-control form-control-sm" formControlName="verifyPassword" id="inputPasswordNewVerify"
              required="">
              <a class="input-group-append">
                <span  class="input-group-text text-white bg-dynamic-2c" id="category" *ngIf="true">
                  <i class="las la-eye"></i>
                </span>
                <span class="input-group-text text-white bg-dynamic-2c" id="category" *ngIf="false">
                  <i class="las la-eye-slash"></i>
                </span>
              </a>
          </div>
            
            <span class="form-text small text-muted">
              To confirm, type the new password again.
            </span>
            <small *ngIf="changePasswordForm.get('newPassword')?.value && changePasswordForm.get('verifyPassword')?.value &&
            changePasswordForm.get('newPassword')?.value !== changePasswordForm.get('verifyPassword')?.value &&
            changePasswordForm.get('verifyPassword')?.touched" class="text-danger">
              password mismatch
            </small>
          </div>
          <!-- <div class="form-group">
                  <button type="submit" class="btn btn-success btn-lg float-right">Save</button>
              </div> -->
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="waves-light btn btn-sm btn-dynamic-secondary-2c" aria-label="Close"
          (click)="changePasswordModal.hide()" mdbWavesEffect>Close</button>
        <button
          [disabled]="!changePasswordForm.get('newPassword')?.value || !changePasswordForm.get('verifyPassword')?.value ||
        changePasswordForm.get('newPassword')?.value !== changePasswordForm.get('verifyPassword')?.value || !changePasswordForm.get('oldPassword')?.value"
          type="button" class="relative btn btn-sm btn-dynamic-2c waves-light " mdbWavesEffect
          (click)="changePasswordModal.hide();changePassword()">Update</button>
      </div>
    </div>
  </div>
</div>