import { Injectable } from '@angular/core';
import moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class TwoCTimezoneService {
  currentTimeInterval = null;
  static currentTimeZone = moment.tz.guess();
  currentTime = '';

  constructor() {
    // this.calculateCurrentTime()
   }

  //  const timezone = this.securityService.getClaim('TimeZone')?.claimValue || '';
  //     this.timeZoneService.changeMomentDefaultTimeZone(timezone);
  changeMomentDefaultTimeZone(timeZone: string) {
    if (this.isValidTimezone(timeZone)) {
      TwoCTimezoneService.currentTimeZone = timeZone;
      moment.tz.setDefault(timeZone);
    } else {
      moment.tz.setDefault();
      TwoCTimezoneService.currentTimeZone = moment.tz.guess();
    }
    this.calculateCurrentTime();
  }

  public isValidTimezone(timezone: string): boolean {
    return moment.tz.zone(timezone) != null;
  }

  calculateCurrentTime() {
    if (this.currentTimeInterval) {
      return;
    }
    this.currentTime = moment().format(`ddd DD, MMM hh:mm A`)
    this.currentTimeInterval = setInterval(() => {
      this.currentTime = moment().format(`ddd DD, MMM hh:mm A`)
      // DDD DD,MMM hh:mm:ss AA
    }, 10000);
  }

  timeZoneTest() {
    
    // moment.utc(element.measurementDate).tz(TwoCTimezoneService?.currentTimeZone || moment.tz.guess()).format('D MMM YY,\\ h:mm a')
    const nowTime = moment().format(`YYYY-MM-DD hh:mm:ss a`)
    const sdfsd = `
        Current TimeZone = ${moment().tz()} <br>
    Current Time to UTC = ${moment(nowTime).utc().format(`YYYY-MM-DD hh:mm:ss a`)} <br>
Current Time to UTC usingTZ = ${moment.tz(nowTime, TwoCTimezoneService?.currentTimeZone).utc().format(`YYYY-MM-DD hh:mm:ss a`)} <br>
    `
  }
}
