import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ModalModule,
  WavesModule,
  SelectModule, TimePickerModule, BadgeModule, IconsModule,
  InputsModule,
  ButtonsModule,
  SidenavModule,
  AccordionModule,
  NavbarModule,
  TabsModule,
  TooltipModule,
  CheckboxModule,
  DropdownModule,
  ChartsModule,
  PreloadersModule,
  CollapseModule,
  StepperModule,
  DatepickerModule,
  FileInputModule,
  PopoverModule,
  // TableModule,
  StickyContentModule,
  ChipsModule,
  ChartSimpleModule,
  RangeModule 
} from 'ng-uikit-pro-standard';
import { NgChartsModule } from 'ng2-charts';
@NgModule({
schemas: [CUSTOM_ELEMENTS_SCHEMA],

  declarations: [],
  imports: [
    CommonModule,
    DropdownModule,
    TabsModule,
    TimePickerModule,
    ModalModule,
    WavesModule,
    SelectModule,
    InputsModule,
    ButtonsModule,
    SidenavModule,
    AccordionModule,
    ChipsModule,
    BadgeModule,
    IconsModule,
    NavbarModule,
    TooltipModule,
    CheckboxModule,
    ChartsModule,
    PreloadersModule,
    CollapseModule,
    StepperModule,
    DatepickerModule,
    FileInputModule,
    PopoverModule,
    StickyContentModule,
    ChartSimpleModule,
    RangeModule,
    NgChartsModule
  ],
  exports: [
    CommonModule,
    DropdownModule,
    TabsModule,
    TimePickerModule,
    ModalModule,
    WavesModule,
    SelectModule,
    InputsModule,
    ButtonsModule,
    SidenavModule,
    AccordionModule,
    ChipsModule,
    BadgeModule,
    IconsModule,
    NavbarModule,
    TooltipModule,
    CheckboxModule,
    ChartsModule,
    PreloadersModule,
    CollapseModule,
    StepperModule,
    DatepickerModule,
    FileInputModule,
    PopoverModule,
    StickyContentModule,
    ChartSimpleModule,
    RangeModule,
    NgChartsModule
  ]
})
export class MdbSharedModule { }
