
<!-- <label class="m-0" for="21212112">Text</label> -->
<div id="twocInputArea" class="input-area">
  <div #TCFieldRef type="textarea" [id]="'twocBox'+id" [style.height]="height" style="overflow-y: auto;resize:vertical;" rows="4" (click)="saveCursorPosition()" placeholder="Enter text..." class="form-control" contenteditable="true"
  (keyup)="triggerPopOverCheck($event);" (blur)="saveCursorPosition();stopSpeech()"
    (keyup.enter)="EnterPressed()" (paste)="OnPaste($event)">
  </div>
  <!-- <div class="speech-text-" *ngIf="interimResult && speechState">
    {{interimResult}}
  </div> -->
  <div class="speech-text-" *ngIf="interimResult && speechState">
    <span>{{interimResult}}</span>
  </div>
  <span *ngIf="speechState" (click)="stopSpeech()" class="las la-microphone speechIcon">
    <span id="blink"></span>
  </span>
  <span *ngIf="!speechState" (click)="startSpeech()" class="las la-microphone-slash speechIcon"></span>
</div>

