import { Injectable } from '@angular/core';
import { DataStorageType } from '../Enums/data-storage.enum';
import { DataStorage, DSQuickNote } from '../model/data-storage/data-storage.model';

@Injectable({
  providedIn: 'root'
})
export class DataStorageService {

  // dataList = new Array<DataStorage>();
  dataList : Array<DataStorage> = [];

  constructor() { }

  saveData(dataStorageDto: DataStorage ){
    this.dataList = JSON.parse(localStorage.getItem('StorageData'));
    if (!this.dataList?.length) {
      this.dataList = []
    }
    this.dataList.push(dataStorageDto);
    localStorage.setItem('StorageData',JSON.stringify(this.dataList));
  }
  getData(userId: string, entityId: number,dataStorageType: DataStorageType ){
    this.dataList = JSON.parse(localStorage.getItem('StorageData'));
    if(this.dataList?.length){
      const dataList = this.dataList.filter(data => (data.userId == userId) && (data.entityId == entityId)&& (data.dataStorageType == dataStorageType))
      return dataList || []
    }
    else {
      return null
    }
  }
  deleteData(userId: string, entityId: number,dataStorageType: DataStorageType){
    this.dataList = JSON.parse(localStorage.getItem('StorageData'));
    if(this.dataList?.length){
      this.dataList = this.dataList.filter(data => !(data.userId == userId && data.entityId == entityId && data.dataStorageType == dataStorageType));
      localStorage.setItem('StorageData',JSON.stringify(this.dataList || []));
    }
  }
}
