export class AddEditDepartment {
  facilityId: number;
  name: string;
  departmentId: number;
  emrDepartmentId: number;
  departmentCode: number;
  colorCode: string;
}
export class Department {
  id: number;
  name: string;
  isDefault: boolean;
  facilityId: number;
  facilityName: string;
  emrDepartmentId: number;
  departmentCode: number;
  colorCode: string;
}
export class DeleteDepartment {
  departmentId: number;
  shiftPatientToDepartmentId: number;
}
export class EditPatientDepartment {
  newDepartmentId: number;
  patientId: number;
}
