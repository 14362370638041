<ng-container >
  <label for="namea" class="mr-3 mb-0">Filters</label>
  <ng-select [(ngModel)]="selectedFilter" (ngModelChange)="ApplyFilter()" style="min-width: 200px;" class="ng-select-small"  appendTo="body"
    [closeOnSelect]="true" [multiple]="false" [searchable]="false" >
    <ng-option *ngFor="let item of filterPreferences" [value]="item">{{item.name}}</ng-option>
  </ng-select>
</ng-container>


<div mdbModal #SaveFilterModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
   aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title w-100 text-center" id="sadsadasd">Filter Name
        </h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-12">
            <div class=" form-group mt-3">
              <!-- <label class="w-100">Filter Assigne Name<small class="text-danger">*</small></label> -->
              <input [(ngModel)]="filterName" type="text" autofocus class="form-control form-control-sm"/>

            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button [disabled]="!filterName || addingFilters" type="button" (click)="SaveFiltersData()" class="waves-light btn btn-sm btn-dynamic-2c"
          aria-label="Close" mdbWavesEffect> <span *ngIf="addingFilters"
            class="mr-1 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> 
            <span *ngIf="selectedFilter?.id">Update</span>
            <span *ngIf="!selectedFilter?.id">Add</span>
          </button>
        <button type="button" class="waves-light btn btn-sm btn-dynamic-secondary-2c" aria-label="Close"
          (click)="SaveFilterModal.hide()" mdbWavesEffect>Close</button>
        <!-- <butmdbModalton type="button" mdbBtn color="prmary" (click)="addRequiredData()" class="relative waves-light"
          mdbWavesEffect>Add Data</button> -->
      </div>
    </div>
  </div>
</div>