import { Directive, ElementRef, HostListener } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appEmailValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: EmailValidatorDirective, multi: true }]
})
export class EmailValidatorDirective implements Validator {
  
  private regex: RegExp = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/);

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: any) {
    let initialValue = this.el.nativeElement.value;

    // Restrict characters based on the regex
    this.el.nativeElement.value = initialValue.replace(/[^a-zA-Z0-9@._-]/g, '');

    if (initialValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    let controlValue = control?.value as string
    if (controlValue) {
      controlValue = controlValue.trim()
    }
    const isValid = this.regex.test(control.value);
    const inputElement: HTMLInputElement = this.el.nativeElement;
    if (control.value && !isValid && inputElement.nodeName == 'INPUT' || (!controlValue && inputElement.required)) {
      inputElement.classList.add('border-danger')
    }
    if (control.value && isValid && inputElement.nodeName == 'INPUT') {
      inputElement.classList.remove('border-danger')
    }
    return isValid ? null : { invalidEmail: true }
  }
}
