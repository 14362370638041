import { Component, OnInit, ViewChild } from '@angular/core';
import moment from 'moment';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { SubSink } from 'src/app/SubSink';
import { PatientsService } from 'src/app/core/Patient/patients.service';
import { AppDataService } from 'src/app/core/app-data.service';
import { AppUiService } from 'src/app/core/app-ui.service';
import { PRCMService } from 'src/app/core/prcm.service';
import { SecurityService } from 'src/app/core/security/security.service';
import { PatientDto } from 'src/app/model/Patient/patient.model';
import { End_PrCMServiceType, PRCMEncounterDto, PRCMQualityCheckModalDto } from 'src/app/model/Prcm/Prcm.model';
import { HttpResError } from 'src/app/model/common/http-response-error';
import { AppUserAuth } from 'src/app/model/security/app-user.auth';

@Component({
  selector: 'app-prcm-quality-check',
  templateUrl: './prcm-quality-check.component.html',
  styleUrls: ['./prcm-quality-check.component.scss']
})
export class PrcmQualityCheckComponent implements OnInit {
  @ViewChild("monthlyReview") monthlyReview: ModalDirective;
  currentUser: AppUserAuth = null;
  private subs = new SubSink();
  isPrDashboardQC= false;
  selectedPatient = new PatientDto();
  IsEncounterLoading= false;
  prcmEncountersList: PRCMEncounterDto[];
  monthId: number;
  yearNum: number;
  completedTime: string;
  End_PrCMServiceTypeEnum = End_PrCMServiceType;
  isCheckingPrcmQualityCheck= false;
  constructor(private appUi: AppUiService, private patientService: PatientsService, private prcmService: PRCMService,private appData: AppDataService,
    private toaster: ToastService, private securityService: SecurityService) { }

  ngOnInit(): void {
    if (
      this.securityService.securityObject &&
      this.securityService.securityObject.isAuthenticated
    ) {
      this.currentUser = this.securityService.securityObject;
    }
    this.monthId = this.appData.currentMonth;
    this.yearNum = this.appData.currentYear;
    this.appUi.openPRCMQualityCheckModal.subscribe((res: PRCMQualityCheckModalDto) => {
      this.monthlyReview.config = { backdrop: false, ignoreBackdropClick: false };
      this.isPrDashboardQC = res.isPrDashboard;
      this.getPatientById(res.patientId);
      setTimeout(() => {
        this.getPrcmEncountersByPatientId(res.patientId);
      }, 1000);
      this.monthlyReview.show();
    });
  }
  async getPatientById(patientId){
    this.patientService.getPatientDetail(patientId).subscribe((res: any)=>{
      this.selectedPatient =  res;
    }, (err: HttpResError) => {
      this.toaster.error(err.error);
    })
  }
  getPrcmEncountersByPatientId(patientId){
    this.IsEncounterLoading = true;
    this.prcmEncountersList = new Array<PRCMEncounterDto>();
    this.prcmService.getPrCMEncountersByPatientId(patientId, this.monthId, this.yearNum).subscribe((res: any) => {
      this.completedTime = res?.prcmTimeCompleted;
      this.prcmEncountersList = res?.prcmEncountersList;
      this.IsEncounterLoading = false;
    }, (err: HttpResError) => {
      this.IsEncounterLoading = false;
      this.toaster.error(err.error);
    })
  }
  resetEncountersList(){
    this.prcmEncountersList = new Array<PRCMEncounterDto>();
    this.completedTime = '';
  }
  setPrcmQualityChecked(encounter: PRCMEncounterDto){
    this.isCheckingPrcmQualityCheck = true;
    this.subs.sink = this.prcmService.setPrCMQualityChecked(encounter.id, true)
      .subscribe(
        (res: any) => {
          var qualityCheckedEncounter = this.prcmEncountersList.find((en: PRCMEncounterDto) => en.id == encounter.id);
          qualityCheckedEncounter.qualityChecked = true;
          qualityCheckedEncounter.qualityCheckedByName = this.currentUser.fullName
          qualityCheckedEncounter.qualityCheckedDate = moment().format("MM/DD/YYYY hh:mm A");

          this.updatePrcmQualityCheckStatus();
          this.isCheckingPrcmQualityCheck = false;
        },
        (err: HttpResError) => {
          this.isCheckingPrcmQualityCheck = false;
          this.toaster.error(err.error, err.message);
        }
      );
  }
  updatePrcmQualityCheckStatus(){
    if(this.prcmEncountersList.length){
      const notQualityChecked = this.prcmEncountersList.filter(encounter => encounter.qualityChecked == false);
      if(!notQualityChecked.length){
        this.patientService.refreshQualityCheckStatusOfPRCM.next(0);
      }else{
        const qualityChecked = this.prcmEncountersList.filter((encounter) => encounter.qualityChecked == true);
        if(qualityChecked.length == this.prcmEncountersList.length){
          this.patientService.refreshQualityCheckStatusOfPRCM.next(0);
        }else{
          this.patientService.refreshQualityCheckStatusOfPRCM.next(2);
        }
      }
    }
  }
  copyPRCMData() {
    let copyStr = "";
    copyStr += `PRCM Time : ${this.completedTime} \n`;
    copyStr += `Notes \n`;
    this.prcmEncountersList.forEach((log) => {
      copyStr += `Service Type : ${this.End_PrCMServiceTypeEnum[log['end_PrCMServiceType']]}, Created By : ${
        log['end_PrCMCareFacilitatorName']
      } , Date : ${moment(log.encounterDate).format('MMM DD, YY, hh:mm A')} , Start Time: ${log.startTime}, End Time : ${
        log.endTime
      } , Duration : ${log.duration} \n`;
      copyStr += `Note: ${log.note} \n`;
    });
    const textArea = document.createElement("textarea");
    textArea.value = copyStr;
    document.body.appendChild(textArea);
    textArea.select();
    textArea.setSelectionRange(0, 99999);
    document.execCommand("copy");
    textArea.remove();
    this.toaster.success("Content Copied");
  }
}
