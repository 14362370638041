import { HttpBackend, HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpErrorHandlerService } from "../shared/http-handler/http-error-handler.service";
import { environment } from "src/environments/environment";
import { catchError } from "rxjs";
import { AICallLogsDto, AddAICallLogsAndGeneratedDataDto, ClinicalNotesDataDto } from "../model/cura-ai-generator.model";
import { OpenAiCompletionsPostDto } from "../model/AzureOpenAiModel/azure-open-ai.model";
const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};
@Injectable({
  providedIn: "root",
})
export class CuraAiService {
  private http2: HttpClient;
  private baseUrl = localStorage.getItem("switchLocal")
    ? environment.localBaseUrl
    : environment.baseUrl;
  constructor(
    private httpErrorService: HttpErrorHandlerService,
    private http: HttpClient,
    private handler: HttpBackend
    ) {
      this.http2 = new HttpClient(handler);
    }
  getClinicalNotesDataByPatientId(clinicalNotesDataDto: ClinicalNotesDataDto) {
    return this.http
      .get(
        this.baseUrl + `CuraAI/GetClinicalNotesDataByPatientId/${clinicalNotesDataDto.patientId}?From=${clinicalNotesDataDto.from}&To=${clinicalNotesDataDto.to}&MessageState=${clinicalNotesDataDto.messageState}&IncludeMessages=${clinicalNotesDataDto.includeMessages}&IncludeDeviceData=${clinicalNotesDataDto.includeDeviceData}&IncludeRpmCarePlan=${clinicalNotesDataDto.includeRpmCarePlan}&IncludeBhiCarePlan=${clinicalNotesDataDto.includeBhiCarePlan}&IncludeBhiScreeningTool=${clinicalNotesDataDto.includeBhiScreeningTool}`,
        httpOptions
      )
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  OpenAiCompletions(postData: OpenAiCompletionsPostDto) {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'api-key': 'd5941d7ebaf74549bd281fbb91619df6'
        // 'api-key': '2367e363550d41cc8a160513e4161114'
      })
    };
    const OPENAI_API_BASE = `https://2c-openai.openai.azure.com`  // `https://healthforce.openai.azure.com`;
    const COMPLETIONS_MODEL = `gpt35T-16k` //  `GPT35-Testing`
    return this.http2.post(`${OPENAI_API_BASE}/openai/deployments/${COMPLETIONS_MODEL}/chat/completions?api-version=2023-07-01-preview`, postData ,
        httpOptions1
      )
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  getAICallLogs(aiCallLogsDto: AICallLogsDto) {
    return this.http
      .get(
        this.baseUrl + `CuraAI/GetAICallLogs?from=${aiCallLogsDto.from}&to=${aiCallLogsDto.to}&serviceType=${aiCallLogsDto.serviceType}&searchParam=${aiCallLogsDto.searchParam || ''}&filterBy=1&facilityId=${aiCallLogsDto.facilityId}`,
        httpOptions
      )
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  getAIClinicalNoteById(id){
    return this.http
      .get(
        this.baseUrl + `CuraAI/GetAIClinicalNoteById/${id}`,
        httpOptions
      )
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  addAICallLogsAndGeneratedData(addAICallLogsAndGeneratedDataDto: AddAICallLogsAndGeneratedDataDto){
    return this.http
      .post(
        this.baseUrl + `CuraAI/AddAICallLogsAndGeneratedData`, addAICallLogsAndGeneratedDataDto,
        httpOptions
      )
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
}
