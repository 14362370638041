import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { ModalDirective, PageScrollService, ToastService } from 'ng-uikit-pro-standard';
import { ECalendarValue, IDatePickerDirectiveConfig } from 'ng2-date-picker';
import { PatientsService } from 'src/app/core/Patient/patients.service';
import { AppDataService } from 'src/app/core/app-data.service';
import { CustomeListService } from 'src/app/core/custome-list.service';
import { DataFilterService } from 'src/app/core/data-filter.service';
import { EmitEvent, EventBusService, EventTypes } from 'src/app/core/event-bus.service';
import { FacilityService } from 'src/app/core/facility/facility.service';
import { HealthScoreService } from 'src/app/core/health-score.service';
import { InsuranceService } from 'src/app/core/insurance.service';
import { PRCMService } from 'src/app/core/prcm.service';
import { SecurityService } from 'src/app/core/security/security.service';
import { TwoCModulesEnum } from 'src/app/model/AppModels/app.model';
import { CreateFacilityUserDto } from 'src/app/model/Facility/facility.model';
import { End_PrCMServiceType, PRCMEncounterDto, PRCMPatientsListDto, PrCMEncounterForListDto, PrCMMonthlyStatus } from 'src/app/model/Prcm/Prcm.model';
import { HttpResError } from 'src/app/model/common/http-response-error';
import { enumToArray } from 'src/app/shared-functions/enumFunction';

@Component({
  selector: 'app-prcm-quick-encounter',
  templateUrl: './prcm-quick-encounter.component.html',
  styleUrls: ['./prcm-quick-encounter.component.scss']
})
export class PrcmQuickEncounterComponent implements OnInit {
  @Input() doNotSubscribe: boolean;
  @Output() encounterSaved = new EventEmitter<PrCMEncounterForListDto>();
  @ViewChild("prcmEncounterModal") prcmEncounterModal: ModalDirective;
  addPRCMEncounterDto = new PRCMEncounterDto();
  durationNO: number;
  isLoadingPayersList: boolean;

  public datePickerConfig2: IDatePickerDirectiveConfig = {
    allowMultiSelect: false,
    returnedValueType: ECalendarValue.StringArr,
    format: "MM/DD/YYYY",
   // appendTo: "body",
  };
  public timePickerConfig: IDatePickerDirectiveConfig = {
    allowMultiSelect: false,
    returnedValueType: ECalendarValue.StringArr,
    format: "hh:mm A"
  };

  facilityUsersList = new Array<CreateFacilityUserDto>();
  serviceTypes = enumToArray(End_PrCMServiceType)
  IsaddingEncounterLoading: boolean;
  facilityId: number;
  selectedPatientData= new PRCMPatientsListDto();
  PrCMMonthlyStatus = PrCMMonthlyStatus
  PRCMMonthlyStatusEnumList = this.datafilterService.getEnumAsList(PrCMMonthlyStatus);
  PatientEncounterMonthlyStatusTExt = PrCMMonthlyStatus[PrCMMonthlyStatus['Not Started']];
  addressedOptionIds=[];
  constructor(
    private datafilterService: DataFilterService,
    private patientService: PatientsService,
    private facilityService: FacilityService,
    private insuranceService: InsuranceService,
    private securityService: SecurityService,
    private PRCMService: PRCMService,
    @Inject(ToastService) private toaster: ToastService,
    private appData: AppDataService,
    private router: Router,
    private eventBus: EventBusService,
    private cdr: ChangeDetectorRef,
    private customListService: CustomeListService,
    private pageScrollService: PageScrollService,
    private route: ActivatedRoute,
    private healthScoreService: HealthScoreService
  ) {}

  ngOnInit() {
    this.facilityId = +this.securityService.getClaim('FacilityId')?.claimValue;
    this.getPRCMFacilityUsers()
    this.subscribeOpenModalRequest()
  }
  subscribeOpenModalRequest() {
    if (this.doNotSubscribe) {
      return
    }
    this.eventBus
      .on(EventTypes.OpenPRCMQuickEncounter)
      .subscribe(
        (res: {
          type: string;
          data: { patient: PRCMPatientsListDto; encounterObj: PRCMEncounterDto, addressedOptionIds?: any}, config:{hideTimer: boolean}
        }) => {
          if (res.type == EventTypes.OpenPRCMQuickEncounter.toString()) {
            this.addPRCMEncounterDto = new PRCMEncounterDto();
            if(res.data?.addressedOptionIds?.length){
              this.addressedOptionIds = res.data?.addressedOptionIds;
              console.log(this.addressedOptionIds)
            }
            
            // this.addPRCMEncounterDto.startTime = res.data.encounterObj.startTime;
            // this.addPRCMEncounterDto.endTime = res.data.encounterObj.endTime;
            // const date1 = moment().format("MM/DD/YYYY");
            // this.addPRCMEncounterDto.encounterDate = date1;
            // this.durationChanged(+res.data.encounterObj.duration);
            this.addPRCMEncounterDto.duration = res.data.encounterObj.duration;
            this.addPRCMEncounterDto.patientCommunicationIds = res.data.encounterObj.patientCommunicationIds || [];
            this.addPRCMEncounterDto.note = res.data.encounterObj.note;
            // this.StartTimeChanged()
            this.cdr.detectChanges();
            this.OpenEncounterModal(res.data.patient);
          }
        }
      );
  }
  getPRCMFacilityUsers() {
    // let roleName = "PRCM Care Manager";
    this.isLoadingPayersList = true;
    this.facilityService.getFacilityUserList(this.facilityId).subscribe(
      (res: []) => {
        this.facilityUsersList = res;
        this.isLoadingPayersList = false;
      },
      (error: HttpResError) => {
        this.isLoadingPayersList = false;
        this.toaster.error(error.error, error.message);
      }
    );
  }
  OpenEncounterModal(patient: PRCMPatientsListDto) {
    this.selectedPatientData = patient;
    this.addPRCMEncounterDto.encounterDate = moment().format("MM/DD/YYYY");
    const time = moment().format("hh:mm A");
    this.addPRCMEncounterDto.endTime = time;
    this.addPRCMEncounterDto.prCMServiceTypeId = End_PrCMServiceType['Initial Assessment'];
    // this.addPRCMEncounterDto.end_PrCMCareFacilitatorId = this.securityService.securityObject.id;
    // this.addPRCMEncounterDto.end_PrCMSpecialistBillerId = this.selectedPatientData.end_PrCMSpecialistBillerId;
    // this.addPRCMEncounterDto.cptCode = "G2065";
    const prcmEndoServiceClaim = this.securityService.getClaim("PrcmEndoService");
    const prcmGeneralServiceClaim = this.securityService.getClaim("PrcmGeneralService");

    if ((prcmEndoServiceClaim?.claimValue !== undefined) || (prcmGeneralServiceClaim?.claimValue !== undefined)) {
      this.addPRCMEncounterDto.prCMCareCoordinatorId = this.securityService.securityObject.id;
    }
    this.durationChanged(+this.addPRCMEncounterDto.duration)
    this.cdr.detectChanges();
    // this.addPRCMEncounterDto
    this.prcmEncounterModal.show();
  }
  OpenEditEncounterModal(encounterDetails: any){
    this.selectedPatientData.id = encounterDetails?.patientId;
    this.addPRCMEncounterDto = encounterDetails;
    this.addPRCMEncounterDto.prCMServiceTypeId = encounterDetails.end_PrCMServiceType
    this.cdr.detectChanges();
    this.prcmEncounterModal.show();
  }
  addPRCMEncounter() {
    this.IsaddingEncounterLoading = true;
    this.addPRCMEncounterDto.patientId = this.selectedPatientData?.id;
    this.PRCMService.AddPRCMEncounter(this.addPRCMEncounterDto).subscribe(
      (res: PrCMEncounterForListDto) => {
        if(this.addressedOptionIds?.length){
          this.addressedOptions()
        }
        //  this.psyfacilityUserList = res;
        this.toaster.success('Encounter added successfully');
        this.prcmEncounterModal.hide();
      //  this.GetMonthlyPatientsPRCMData();
      //  this.filterPatients();
        this.IsaddingEncounterLoading = false;
        this.encounterSaved.emit(res)
        this.refreshPrcmPatientsList(this.addPRCMEncounterDto);
      },
      (error: HttpResError) => {
       this.IsaddingEncounterLoading = false;
        this.toaster.error(error.error, error.message);
      }
    );
  }
  editPRCMEncounter() {
    this.IsaddingEncounterLoading = true;
    if(this.selectedPatientData?.id)
    this.addPRCMEncounterDto.patientId = this.selectedPatientData?.id;
    this.PRCMService.EditPRCMEncounter(this.addPRCMEncounterDto).subscribe(
      (res: PrCMEncounterForListDto) => {
        //  this.psyfacilityUserList = res;
        this.toaster.success('Encounter edited successfully');
        this.prcmEncounterModal.hide();
      //  this.GetMonthlyPatientsPRCMData();
      //  this.filterPatients();
        this.IsaddingEncounterLoading = false;
        this.encounterSaved.emit(res)
        this.refreshPrcmPatientsList(this.addPRCMEncounterDto);
        this.resetEncounterValues()
      },
      (error: HttpResError) => {
       this.IsaddingEncounterLoading = false;
        this.toaster.error(error.error, error.message);
      }
    );
  }
  durationChanged(minsToSubtract: number) {
    const endTime = this.addPRCMEncounterDto.endTime;
    let startTime = moment(endTime, 'hh:mm A').subtract(minsToSubtract, 'minutes').format('hh:mm A');
    this.addPRCMEncounterDto.startTime = startTime;
  }
  StartTimeChanged() {
    const startTime = moment(this.addPRCMEncounterDto.startTime, "hh:mm A");
    const endTime = moment(this.addPRCMEncounterDto.endTime, "hh:mm A");
    this.durationNO = endTime.diff(startTime, 'minutes') > 0 ? endTime.diff(startTime, 'minutes') : null;
  }
  refreshPrcmPatientsList(rpmEncounterDto: PRCMEncounterDto){
    const emitObj = new EmitEvent();
    emitObj.name = EventTypes.CommunicationEncounterEdit;
    emitObj.value = {
      type: EventTypes.CommunicationEncounterEdit.toString(),
      data: {
        patientId: rpmEncounterDto.patientId,
        patientCommunicationIds: rpmEncounterDto.patientCommunicationIds,
        serviceType: TwoCModulesEnum.PrCM,
        encounterObj: rpmEncounterDto
      },
    };
    this.eventBus.emit(emitObj);
  }
  resetEncounterValues(){
    this.addPRCMEncounterDto = new PRCMEncounterDto();
    this.selectedPatientData.id = 0;
  }
  hasEndoServiceClaim(): boolean {
    return this.securityService.hasClaim('PrcmEndoService'); 
  }
  addressedOptions(){
    if(this.addressedOptionIds?.length){
      this.healthScoreService.addressedOptions(this.addressedOptionIds).subscribe((res: any) => {
        this.toaster.success('Options Addressed')
      }, (err: HttpResError) => {
        this.toaster.error(err.error);
      })
    }
  }
}
