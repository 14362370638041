import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, Injectable, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { SecurityService } from './security.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private securityService: SecurityService, private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isAccessTokenExpired = this.securityService.isAccessTokenExpired();
    const isRefreshTokenExpired = this.securityService.isRefreshTokenExpired();
    const isLoggedIn = this.securityService.isLoggedIn();
    
    if (isLoggedIn && isRefreshTokenExpired) {
      console.log(this.securityService.GetTokensExpiry());
      this.router.navigate(['/login'], { queryParams: { reason: 'Refresh Token Expired' } });
      this.securityService.logout();
      return throwError(() => new Error('Refresh token expired.'));
    }
    
    if (isLoggedIn && isAccessTokenExpired && !isRefreshTokenExpired) {
      if (!this.isRefreshing) {
        this.isRefreshing = true;
        this.refreshTokenSubject.next(null);
        
        return this.securityService.RefreshToken().pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;
            this.refreshTokenSubject.next(token);
            return this.handleTheRequest(req, next);
          }),
          catchError((err) => {
            this.isRefreshing = false;
            if (err?.url?.includes("RefreshToken")) {
              console.log(this.securityService.GetTokensExpiry());
              this.router.navigate(['/login'], { queryParams: { reason: 'Unable to Refresh Token: ' + err.error } });
              this.securityService.logout();
            }
            return throwError(() => err);
          })
        );
      } else {
        // If a refresh is already in progress, wait for it to complete.
        return this.refreshTokenSubject.pipe(
          filter(token => token != null), // wait until new token is emitted
          take(1),                          // take only the first emission after refresh
          switchMap(() => this.handleTheRequest(req, next))
        );
      }
    }

    return this.handleTheRequest(req, next);
  }

  private handleTheRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('bearerToken');
    const PagePath = location.href || '';

    if (token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          version: this.securityService.appVersion,
          ClientType: 'web',
          PagePath: PagePath
        }
      });
    }

    return next.handle(req).pipe(
      catchError((httpError: HttpErrorResponse) => {
        if (httpError.status === 426) {
          window.location.reload();
        }
        return throwError(() => httpError);
      })
    );
  }
}
@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  
    providers: [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpRequestInterceptor,
        multi: true
      }
    ]
  })
  export class HttpInterceptorModule {}