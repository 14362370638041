import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LangService {
  selectedLanguage: 'en' | 'es' | string = 'en' ;
  public renderer: Renderer2;
  /** CONSTRUCTOR */
  constructor(
    private _renderer: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.renderer = _renderer.createRenderer(null, null);
    const cLang = localStorage.getItem('cLang');
    if (cLang) {
      this.selectedLanguage = cLang as 'en' | 'es';
      // this.changeDir();
      // document.body, 'dir', this.selectedLanguage === 'ar' ? 'rtl': 'ltr'
      // $("html").children().css("direction","rtl");
    }
   }
  changeDir() {
    // this.renderer.setAttribute(document.body, 'dir', this.selectedLanguage === 'ar' ? 'rtl': 'ltr');
    // if (this.selectedLanguage === 'ar') {
    //   this.renderer.addClass(this.document.body, 'arabiclanguage');
    // } else {
    //   this.renderer.removeClass(this.document.body, 'arabiclanguage');
    // }
  }
  ChangeToArabic() {
    this.selectedLanguage = 'es';
    localStorage.setItem('cLang', this.selectedLanguage);
    // this.changeDir();
  }
  ChangeToEnglish() {
    this.selectedLanguage = 'en';
    localStorage.setItem('cLang', this.selectedLanguage);
    // this.changeDir();
  }
  
}
