<div class="header navbar fixed-top navbar-toggler navbar-expand-sm double-nav bg-white rounded-0">
  <div class="brand-logo">
    <!-- <img src="/assets/img/logo3.svg" alt="2C Health Solutions"> -->
    <img src="{{brandingService.logoPath}}logo-1024-265.png" alt="{{brandingService.appTitle}}">

  </div>
</div>

<div class="container" style="padding-top: 100px;">
  <router-outlet></router-outlet>
</div>
<footer class="footer bg-white">
  <p>2C Health Solutions</p>
</footer>
