<div class="modal-content">
  <div class="modal-header">
    <h4 class="text-dynamic-2c modal-title font-500 mr-3">RPM Notification</h4>
  </div>
  <div class="modal-body">
    <div>
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-6">
              <h5 style="font-size: 18px;font-weight: 600;" class="mb-0">Notification</h5>
            </div>
            <div class="col-3">
              <h5 style="font-size: 18px;font-weight: 600;" class="mb-0">SMS</h5>
            </div>
            <div class="col-3">
              <h5 style="font-size: 18px;font-weight: 600;" class="mb-0">Mobile</h5>
            </div>
          </div>
          <div class="card-body px-0">
            <div class="row mb-2">
              <div class="col-6">
                <h5 style="font-size: 16px;font-weight: 500;" class="mb-0">Missing Alerts</h5>
              </div>
              <div class="col-3">
                <mdb-checkbox [disabled]="!SetFacilityServiceCOnfigDto.enableNotifications" [(ngModel)]="PatientNotifSetting.missedAlertSms" [default]="true" title="SMS"></mdb-checkbox>
              </div>
              <div class="col-3">
                <mdb-checkbox [(ngModel)]="PatientNotifSetting.missedAlertPush" [default]="true" title="Mobile"></mdb-checkbox>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-6">
                <h5 style="font-size: 16px;font-weight: 500;" class="mb-0">Reading Acknowledgement</h5>
              </div>
              <div class="col-3">
                <mdb-checkbox [disabled]="!SetFacilityServiceCOnfigDto.enableNotifications" [(ngModel)]="PatientNotifSetting.readingAckSms" [default]="true" title="SMS"></mdb-checkbox>
              </div>
              <!-- <div class="col-3">
                <mdb-checkbox [(ngModel)]="PatientNotifSetting.missedAlertPush" [default]="true" title="Mobile"></mdb-checkbox>
              </div> -->
            </div>
            
          </div>
          <!-- <div class="row mb-2">
            <div class="col-6">
              <h5 style="font-size: 16px;font-weight: 500;" class="mb-0">Reading Acknowledgement</h5>
            </div>
            <div class="col-3">
              <mdb-checkbox [(ngModel)]="PatientNotifSetting.readingAckSms" [default]="true" title="SMS"></mdb-checkbox>
            </div>
            <div class="col-3">
            </div>
          </div> -->
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6">
              <div class="form-group">
                <label class="w-100 font-500">Notification Time</label>
                <input type="text" readonly class="form-control form-control-sm bg-white" #dateDpTime="dpDayPicker"
                  [dpDayPicker]="timePickerConfig" [(ngModel)]="PatientNotifSetting.notificationTime"
                  [theme]="'dp-material ccm-date-picker'" [mode]="'time'" appDateTimeValidator="time"  />
              </div>
            </div>
            <!-- <div class="form-group">
              <label>Launch patient Time</label>
                <ng-select class="ng-select-small" appendTo="body" [multiple]="false"
                  [searchable]="false"  placeholder="patient Time">
                  <ng-option [value]="0">10 Min</ng-option>
                  <ng-option [value]="1">20 Min</ng-option>
                  <ng-option [value]="2">30 Min</ng-option>
                  <ng-option [value]="3">40 Min</ng-option>
                  <ng-option [value]="4">50 Min</ng-option>
                  <ng-option [value]="5">60 Min</ng-option>
                </ng-select>
              </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer d-flex justify-content-between align-items-center">
    <div>
      <button type="button" [disabled]="isLoadingConfig" type="button" (click)="EditPatientNotificationConfig()" class="relative waves-light btn-sm btn-dynamic-2c btn">
        <span *ngIf="isLoadingConfig" class="spinner-border spinner-border-sm" role="status"
        aria-hidden="true"></span>Save</button>
    </div>
  </div>
</div>


<!-- <div mdbModal #alertsConfigration="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myalertsConfigrationLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header py-2">
        <button type="button" class="close pull-right" aria-label="Close" (click)="alertsConfigration.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" style="font-size: 20px;" id="myModalLabel"> Alerts Configration</h4>
      </div>
      <div class="modal-body">

      </div>
      <div class="modal-footer py-2">
        <button type="button" class="btn btn-sm btn-dynamic-secondary-2c" aria-label="Close" (click)="alertsConfigration.hide()" >Close</button>
        <button type="button" class="btn btn-sm btn-dynamic-2c" aria-label="Close" >Save</button>
      </div>
    </div>
  </div>
</div> -->
