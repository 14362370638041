import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpErrorHandlerService } from '../shared/http-handler/http-error-handler.service';
import { AddEditCustomForm, AddEditCustomFormQuestion, AddEditCustomFormQuestionOption, FilterCustomForm } from '../model/custom-forms/custom-form.model';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};
@Injectable({
  providedIn: 'root'
})
export class CustomFormsService {
  baseUrl = localStorage.getItem('switchLocal') ? environment.localBaseUrl:  environment.baseUrl;

  constructor(private http: HttpClient, private httpErrorService: HttpErrorHandlerService) { }

  getAllCustomForms(filterCustomFormDto: FilterCustomForm){
    return this.http.get(this.baseUrl + `CustomForm/GetAllCustomForms?moduleIds=${filterCustomFormDto.moduleIds}&careEpisodeIds=${filterCustomFormDto.careEpisodeIds}&chronicConditionIds=${filterCustomFormDto.chronicConditionIds}&searchParam=${filterCustomFormDto.searchParam}&formType=${filterCustomFormDto.formType}&status=${filterCustomFormDto.status}`, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  getCustomForm(id: number){
    return this.http.get(this.baseUrl + `CustomForm/${id}`, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  CustomFormQuestionById(id: number){
    return this.http.get(this.baseUrl + `CustomForm/CustomFormQuestionById/${id}`, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  CustomFormQuestionOptionsByQuestionId(questionId: number){
    return this.http.get(this.baseUrl + `CustomForm/CustomFormQuestionOptionsByQuestionId/${questionId}`, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  addEditCustomForm(addEditCustomFromDto: AddEditCustomForm) {
    return this.http.put(this.baseUrl + `CustomForm/AddEditCustomForm`, addEditCustomFromDto, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  addEditCustomFormQuestion(addEditCustomFormQuestionDto: AddEditCustomFormQuestion) {
    return this.http.put(this.baseUrl + `CustomForm/AddEditCustomFormQuestion`, addEditCustomFormQuestionDto, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  addEditCustomFormQuestionOption(addEditCustomFormQuestionOptionDto: AddEditCustomFormQuestionOption) {
    return this.http.put(this.baseUrl + `CustomForm/AddEditCustomFormQuestionOption`, addEditCustomFormQuestionOptionDto, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  deleteCustomForm(id: number){
    return this.http.delete(this.baseUrl + `CustomForm/DeleteCustomForm?id=${id}`, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  deleteCustomFormQuestion(id: number){
    return this.http.delete(this.baseUrl + `CustomForm/DeleteCustomFormQuestion?id=${id}`, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  deleteCustomFormQuestionOption(id: number){
    return this.http.delete(this.baseUrl + `CustomForm/DeleteCustomFormQuestionOption?id=${id}`, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  editCustomFormQuestionSequence(questionsSequenceList){
    return this.http.put(this.baseUrl + `CustomForm/EditCustomFormQuestionSequence`, questionsSequenceList, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  editCustomFormQuestionOptionSequence(questionOptionsSequenceList){
    return this.http.put(this.baseUrl + `CustomForm/EditCustomFormQuestionOptionSequence`, questionOptionsSequenceList, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  setCustomFormForFacility(customFormId: number, facilityId: number){
    return this.http.put(this.baseUrl + `Facility/SetCustomFormForFacility?customFormId=${customFormId}&facilityId=${facilityId}`, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
}
