<div class="d-flex align-items-center flex-wrap justify-content-between mb-2">
    <h1 class="page-title flex-sm-grow-1 mb-0">Churn Analysis</h1>
    <div class="d-flex align-items-end flex-wrap justify-content-end">
        <div class="d-flex align-items-end justify-content-end">
            <div class="input-group mr-1 mb-0" style="width: 150px;">
                <label f class="mb-0 mr-1 " or="namea"><strong> From</strong></label>
                <div class="input-group mb-0">
                    <input readOnly style="height:32px;width:100px;" type="text"
                        value="{{patientChurnAnalysisReportDto.from | date: 'MMM yyyy'}}" class="form-control bg-white"
                        autocomplete="off">
                    <div class="input-group-append ">
                        <span style="cursor: pointer; border-radius: 0 4px 4px 0; border-color: #ced4da;"
                            [dpDayPicker]="datePickerConfig2" [theme]="'dp-material ccm-date-picker'" [mode]="'month'"
                             (onSelect)="filterByMonth($event, 'from')"
                            class="input-group-text bg-white">
                            <i class="las la-calendar fa-lg"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="input-group mr-1 mb-0" style="width: 150px;">
                <label class="mb-0 mr-1 w-100" or="namea"><strong> To</strong></label>
                <div class="input-group mb-0">
                    <input readOnly style="height:32px;width:100px;" type="text"
                        value="{{patientChurnAnalysisReportDto.to | date: 'MMM yyyy'}}" class="form-control bg-white"
                        autocomplete="off">
                    <div class="input-group-append ">
                        <span style="cursor: pointer;border-radius: 0 4px 4px 0;border-color: #ced4da;"
                            [dpDayPicker]="datePickerConfig2" [theme]="'dp-material ccm-date-picker'" [mode]="'month'"
                            (onSelect)="filterByMonth($event, 'to')"
                            class="input-group-text bg-white">
                            <i class="las la-calendar fa-lg"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="form-group ml-0 mb-0 mr-1" style="width: 150px;">
                <label f class="mb-0 mr-1 " or="namea"><strong> Service</strong></label>
                <ng-select style="min-width:150px;" class="ng-select-small " [clearable]="false" appendTo="body"
                    [(ngModel)]="patientChurnAnalysisReportDto.serviceType" [multiple]="false" [searchable]="true"
                    placeholder="Services">
                    <ng-option *ngFor="let item of twoCModulesList" [value]="item.value">{{item.name}}</ng-option>
                </ng-select>
            </div>
            <div *hasClaim="'IsAppAdmin'" class="form-group ml-0 mb-0 mr-1" style="width: 150px;">
                <label f class="mb-0 mr-1 " or="namea"><strong> Facilities</strong></label>
                <ng-select style="min-width:150px;" class="ng-select-small "
                    [(ngModel)]="patientChurnAnalysisReportDto.facilityId" [clearable]="false" appendTo="body"
                    [loading]="isLoadingFacilities" [multiple]="false" [searchable]="true" placeholder="Facility">
                    <ng-option *ngFor="let item of facilityList" [value]="item.id">{{item.facilityName}}</ng-option>
                </ng-select>
            </div>

            <button [disabled]="isLoadingReport" (click)="getPatientChurnAnalysisReport()"
                class="btn btn-dynamic-2c btn-sm mr-1  ml-0" style="margin: 0px;height: 30px;">Load <span
                    *ngIf="isLoadingReport" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span></button>
            <button (click)="resetFilters()" class="btn btn-dynamic-secondary-2c btn-sm my-0 flex-shrink-0"
                style="margin: 0px;height: 30px;">Reset </button>
        </div>
    </div>
</div>
<div class=" w-100">
    <button [disabled]="!churnReportList.length || isLoadingReport"
        class="d-flex  btn btn-dynamic-2c btn-sm m-0 ml-auto " style="margin: 0px;height: 33px;"
        (click)="makeExcelForChurnReport()">Download</button>
</div>
<div class="table-data-list mt-3">
    <div class="dataTables_length " id="example_length">
        <label class="mb-0">Show
            <select [(ngModel)]="count" name="example_length" aria-controls="example" class="">
                <option [value]="10">10</option>
                <option [value]="25">25</option>
                <option [value]="50">50</option>
                <option [value]="100">100</option>
            </select> entries</label>
    </div>
    <div *ngIf="isLoadingReport" class="d-flex justify-content-center text-center">
        <mdb-progress-bar class="progress primary-color-dark-s mb-0" mode="indeterminate"></mdb-progress-bar>
    </div>

    <ngx-datatable class="material" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="40"
        [externalPaging]="false" [scrollbarV]="false" [scrollbarH]="true" [rows]="churnReportList" [limit]="count">

        <!-- Row Index Column -->
        <ngx-datatable-column name="#" [width]="30">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex">
                {{ rowIndex + 1 }}
            </ng-template>
        </ngx-datatable-column>

        <!-- Dynamically Generated Columns -->
        <ngx-datatable-column [width]="120" *ngFor="let column of columns" [name]="column.name" [prop]="column.prop"
            [sortable]="column.sortable">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                {{ value }}
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
</div>
<div class="row">
    <div class="col-xl-12 pb-3 pt-3 border-left border-right border-top border-bottom mt-1"
        *ngIf="churnReportList?.length">
        <h5 class="bg-dynamic-2c p-2 mb-2"
            style="font-size: 18px;font-weight: 600;color:#fff;border-left:1px solid #fff;border-right:1px solid #fff;">
            Analysis Graph</h5>
        <div *ngIf="churnReportList?.length" class="panel-body p-0" style="display: block">
            <div *ngIf="isLoadingEgvs">
                <div class="d-flex justify-content-center text-center">
                    <mdb-progress-bar class="progress primary-color-dark-s mb-0"
                        mode="indeterminate"></mdb-progress-bar>
                </div>
            </div>
            <div class="bg-white" style="position: relative;">
                <canvas baseChart [type]="'line'" style="min-height: 185px;" [datasets]="chartDataSet"
                    [labels]="chartLabels" [options]="chartOptions" [legend]="true" [colors]="ReportChartColors">
                </canvas>
            </div>
        </div>
    </div>
</div>