<div mdbModal #HealthFormModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myHealthFormModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="HealthFormModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100 text-centerd" myModalLabel>Bulk Communication</h4>
      </div>
      <div class="modal-body">

        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex ">
            <div class="tabview-bulk">
              <button class="btn btn-tabview" [class.active]="communicationMethod==CommunicationMethod.Telephony" (click)="communicationMethod=CommunicationMethod.Telephony">SMS <span class="ml-2 badge badge-light">{{consentCounts.onSms}}</span></button>
              <button class="btn btn-tabview" [class.active]="communicationMethod==CommunicationMethod.App" (click)="communicationMethod=CommunicationMethod.App">APP <span class="ml-2 badge badge-light">{{consentCounts.onApp}}</span></button>
            </div>
          </div>
          <div class="d-flex">
            <div class="tabview-bulk">
              <button class="btn btn-tabview" [class.active]="bulkCommunicationOption==BulkCommunicationOption.Template" (click)="bulkCommunicationOption=BulkCommunicationOption.Template">Template</button>
              <button class="btn btn-tabview" [class.active]="bulkCommunicationOption==BulkCommunicationOption.HealthForm" (click)="bulkCommunicationOption=BulkCommunicationOption.HealthForm">Form</button>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <div class="alert alert-warning alert-dismissible animated fade show mb-0 py-2 w-100" role="alert">
              Patient SMS Sending Consent can be taken in next step
            </div>
          </div>

        </div>
        <div class="table-height">
          <div class="table-scroll">
            <div class="table-responsive mt-2">
              <table class="table table-bordered">
                  <thead class="bg-dynamic-2c text-white">
                    <tr>
                      <!-- <th width="30">
                        <div class="custom-control custom-checkbox">
                          <input class="custom-control-input" type="checkbox" id="selectAlsms" >
                          <label class="custom-control-label" for="selectAlsms"></label>
                        </div>
                      </th> -->
                      <th>Emr Id</th>
                      <th>Name</th>
                      <th>DOB</th>
                      <th width="90">On App</th>
                      <th width="90">On SMS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of selectedPatients">
                      <!-- <td>
                        <div class="custom-control custom-checkbox">
                          <input class="custom-control-input" type="checkbox" id="selectAllsms" >
                          <label class="custom-control-label" for="selectAllsms"></label>
                        </div>
                      </td> -->
                      <td title="Emr Id">{{item.patientEmrId}}</td>
                      <td>{{item.fullName}}</td>
                      <td>{{item.dateOfBirth | dateFormatPipe}}</td>
                      <td >
                        <div class="action-button d-flex justify-content-center">
                          <span title="{{item.lastAppLaunchDate}}"  *ngIf="item.lastAppLaunchDate && item.isActiveMobileUser" class=" fa-mobile-custom-active my-1 mr-1" >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 72.93 122.88" style="enable-background:new 0 0 72.93 122.88" xml:space="preserve"><style type="text/css">.st023{fill-rule:evenodd;clip-rule:evenodd;}</style><g><path class="st023" d="M10.94,0h51.04C68,0,72.93,4.93,72.93,10.94v100.99c0,6.02-4.93,10.94-10.94,10.94l-51.04,0 C4.93,122.88,0,117.95,0,111.94V10.95C0,4.93,4.93,0,10.94,0L10.94,0z M36.46,100.1c3.91,0,7.09,3.17,7.09,7.09 c0,3.91-3.18,7.09-7.09,7.09c-3.92,0-7.09-3.17-7.09-7.09C29.37,103.28,32.55,100.1,36.46,100.1L36.46,100.1z M25.17,8.86h22.59 c0.16,0,0.3,0.14,0.3,0.3v3.3c0,0.16-0.14,0.3-0.3,0.3H25.17c0,0,0,0,0,0c-0.16,0-0.3-0.13-0.3-0.3v0l0-3.3c0,0,0,0,0,0 C24.87,8.99,25,8.86,25.17,8.86L25.17,8.86L25.17,8.86L25.17,8.86z M9.65,21.53h53.63c0.98,0,1.78,0.81,1.78,1.78v70.01 c0,0.97-0.81,1.78-1.78,1.78H9.65c-0.97,0-1.78-0.8-1.78-1.78V23.3C7.87,22.33,8.67,21.53,9.65,21.53L9.65,21.53z"/></g></svg>
                          </span>
                          <span title="{{item.lastAppLaunchDate}}"  *ngIf="item.lastAppLaunchDate && !item.isActiveMobileUser" class=" fa-mobile-custom-inactive my-1 mr-1" >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 72.93 122.88" style="enable-background:new 0 0 72.93 122.88" xml:space="preserve"><style type="text/css">.st023{fill-rule:evenodd;clip-rule:evenodd;}</style><g><path class="st023" d="M10.94,0h51.04C68,0,72.93,4.93,72.93,10.94v100.99c0,6.02-4.93,10.94-10.94,10.94l-51.04,0 C4.93,122.88,0,117.95,0,111.94V10.95C0,4.93,4.93,0,10.94,0L10.94,0z M36.46,100.1c3.91,0,7.09,3.17,7.09,7.09 c0,3.91-3.18,7.09-7.09,7.09c-3.92,0-7.09-3.17-7.09-7.09C29.37,103.28,32.55,100.1,36.46,100.1L36.46,100.1z M25.17,8.86h22.59 c0.16,0,0.3,0.14,0.3,0.3v3.3c0,0.16-0.14,0.3-0.3,0.3H25.17c0,0,0,0,0,0c-0.16,0-0.3-0.13-0.3-0.3v0l0-3.3c0,0,0,0,0,0 C24.87,8.99,25,8.86,25.17,8.86L25.17,8.86L25.17,8.86L25.17,8.86z M9.65,21.53h53.63c0.98,0,1.78,0.81,1.78,1.78v70.01 c0,0.97-0.81,1.78-1.78,1.78H9.65c-0.97,0-1.78-0.8-1.78-1.78V23.3C7.87,22.33,8.67,21.53,9.65,21.53L9.65,21.53z"/></g></svg>
                          </span>
                        </div>
                      </td>
                      <td>
                        <button type="button" [class.btn-dynamic-2c]="item.telephonyCommunication" [class.btn-danger]="!item.telephonyCommunication"  class="btn  btn-icon my-1 position-relative">
                          <!-- <i (click)="getChatGroup(row, 2)" class="las la-comments"></i> -->
                          <i  class="las la-comments"></i>
                          <!-- <div (click)="selectedPatient=row;OpenCCMEncounterModel()" class="comments-counts" *ngIf="item.unAssociatedCommunication > 0">{{item.unAssociatedCommunication}}</div> -->
                        </button>
                      </td>
                    </tr>
                  </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer  d-flex justify-content-between">
        <div class="" >
          <div *ngIf="selectedPatients?.length" class="alert alert-warning alert-dismissible animated fade show mb-0 py-2" role="alert">
            <strong>{{selectedPatients?.length}} Patient(s)</strong> selected
          </div>
        </div>
        <div>
          <button type="button" class="relative btn btn-sm btn-dynamic-2c" (click)="OpenBulkCommWindow()">Proceed</button>
          <button type="button" class="waves-light btn btn-sm btn-dynamic-secondary-2c" aria-label="Close"
            (click)="HealthFormModal.hide();">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>
