<div class="logo-animation" *ngIf="!alertReason">
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  style="margin: auto; background:transperant; display: block; shape-rendering: auto;width:160px !important;" width="160px" height="160px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle cx="84" cy="50" r="10" fill="#4eaf48">
          <animate attributeName="r" repeatCount="indefinite" dur="0.4716981132075471s" calcMode="spline" keyTimes="0;1" values="10;0" keySplines="0 0.5 0.5 1" begin="0s"/>
          <animate attributeName="fill" repeatCount="indefinite" dur="1.8867924528301885s" calcMode="discrete" keyTimes="0;0.25;0.5;0.75;1" values="#4eaf48;#134389;#4eaf48;#134389;#4eaf48" begin="0s"/>
      </circle><circle cx="16" cy="50" r="10" fill="#4eaf48">
        <animate attributeName="r" repeatCount="indefinite" dur="1.8867924528301885s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"/>
        <animate attributeName="cx" repeatCount="indefinite" dur="1.8867924528301885s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"/>
      </circle><circle cx="50" cy="50" r="10" fill="#134389">
        <animate attributeName="r" repeatCount="indefinite" dur="1.8867924528301885s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.4716981132075471s"/>
        <animate attributeName="cx" repeatCount="indefinite" dur="1.8867924528301885s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.4716981132075471s"/>
      </circle><circle cx="84" cy="50" r="10" fill="#4eaf48">
        <animate attributeName="r" repeatCount="indefinite" dur="1.8867924528301885s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.9433962264150942s"/>
        <animate attributeName="cx" repeatCount="indefinite" dur="1.8867924528301885s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.9433962264150942s"/>
      </circle><circle cx="16" cy="50" r="10" fill="#134389">
        <animate attributeName="r" repeatCount="indefinite" dur="1.8867924528301885s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.4150943396226414s"/>
        <animate attributeName="cx" repeatCount="indefinite" dur="1.8867924528301885s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.4150943396226414s"/>
      </circle>
      <!-- [ldio] generated by https://loading.io/ --></svg>
  </div>
</div>
<div *ngIf="alertReason">
    <div class="modal-body card m-5">
      {{modalObj.Text}}
      <p class="text-center"><i class="las la-exclamation-triangle text-danger" style="font-size: 3rem;"></i> </p>
      <div class="text-center alert alert-warning" role="alert">
        <p class="mb-0" style="font-size: 14px;">{{alertReason}}
        </p>
      </div>
    </div>
</div>
