<div mdbModal #complaintsMOdal="mdbModal" class="modal fade" tabindex="-1" role="dialog" (close)="resetComplaintModal()"
  aria-labelledby="myBasicModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}" >
  <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="complaintsMOdal.hide();">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">
          New Complaint
        </h4>
      </div>
      <div class="modal-body">
        <div class=" row " >
          <div *ngIf="!isGeneralComplaint && CurrentPatient" class="col">
            <p><strong class="mr-1">Patient Name:</strong>{{CurrentPatient.fullName}} </p>
            <p><strong class="mr-1">EMR ID:</strong>{{CurrentPatient.patientEmrId || CurrentPatient.emrId }}</p>
          </div>
          <div class="col ">
            <div class="view mb-3 position-relative" *ngIf="currentRecordingUrl">
              <!--Video source-->
              <button class="video-delete" [disabled]="addingComplaint || uploadingRecording || uploadingRecording " (click)="removeVideo()">
                &times;
              </button>
              <video id="recordVideoPlay" class="video-fluid" controls>]
              </video>
              <div class="mask rgba-purple-slight waves-light" mdbWavesEffect></div>
            </div>
          </div>
        </div>
        <!-- <div class="d-flex align-items-center justify-content-between mb-3">
          <div class="w-100 px-0">
            <label for="searchBy">Search Patient</label>
            <div class="d-flex align-items-center justify-content-start">
              <input type="text" autocomplete="off" [(ngModel)]="searchParam" (ngModelChange)="changed(searchParam)"
                #chatUSerSearchInput class="form-control form-control-sm box-shadow w-100" placeholder="Search by Name" />
              <span *ngIf="searchingChatUsers" class="spinner-border spinner-border-sm position-absolute"
                style="right: 1.5rem;"></span>
            </div>

            <div *ngIf="(searchedChatUserList && searchedChatUserList.length > 0)" (appIsOutside)="searchedChatUserList=[]" class="userList searchable-list"
              style="height: 300px;" [style.overflow-y]="'auto'">
              <ul *ngIf="!searchingChatUsers">
                <li *ngFor="let item of searchedChatUserList" (click)="addComplaintUser(item)">
                  <p>{{item.fullName}}</p>
                </li>
              </ul>
            </div>
          </div>
        </div> -->
        <div class="row">
          <div class="col-12 mb-2">
            <div class="form-group mb-0">
              <input [(ngModel)]="addComplaintObject.title" type="text" class="form-control form-control-sm" placeholder="Title (Required)">
            </div>
          </div>
          <div class="col-6 mb-2">
            <ng-select class="ng-select-small"
              [closeOnSelect]="true"  [(ngModel)]="addComplaintObject.complaintTypeId" [multiple]="false"
              [searchable]="true" placeholder="Complaint Type " (change)="getComplaintSubTypes()" >
              <ng-option *ngFor="let item of complaintTypesList"  [value]="item.id">{{item.display}}</ng-option>

              <!-- <ng-option [value]="1">PatientRPM</ng-option>
              <ng-option [value]="2">4G Device Request</ng-option>
              <ng-option [value]="3">Other</ng-option> -->
            </ng-select>
          </div>
          <div class="col-6 mb-2">
            <ng-select class="ng-select-small"
              [closeOnSelect]="true"  [(ngModel)]="addComplaintObject.complaintSubTypeId" [multiple]="false"
              [searchable]="true" placeholder="Complaint SubType "  >
              <ng-option *ngFor="let item of complaintSubTypesList"  [value]="item.id">{{item.display}}</ng-option>

              <!-- <ng-option [value]="1">PatientRPM</ng-option>
              <ng-option [value]="2">4G Device Request</ng-option>
              <ng-option [value]="3">Other</ng-option> -->
            </ng-select>
          </div>
          <div class="col-6 mb-2">
            <ng-select class="ng-select-small" [(ngModel)]="addComplaintObject.departmentType"
              [closeOnSelect]="true" [multiple]="false"
              [searchable]="true" placeholder="Select Department">
              <ng-option [value]="1">General</ng-option>
              <ng-option [value]="2">CareDelivery</ng-option>
              <ng-option [value]="3">WorkPlace</ng-option>
              <ng-option [value]="4">Technical</ng-option>
              <ng-option [value]="0">Others</ng-option>
            </ng-select>
          </div>
          <div *ngIf="!isGeneralComplaint" class="col-6 mb-2">
            <ng-select class="ng-select-small" (ngModelChange)="putDeviceDetailsIntoComplaintDetails($event)" [(ngModel)]="selectedDevice"
              [closeOnSelect]="true" [multiple]="false"
              [searchable]="false" placeholder="Select Device">
              <ng-option [value]="item.id" *ngFor="let item of devicesList">{{item.modalityName}} - <span class="text-dynamic-2c">{{item.serialNo}}</span></ng-option>
            </ng-select>
          </div>
          <div class="col-12">
            <div class="form-group">
              <textarea class="form-control h-100" [(ngModel)]="addComplaintObject.details" rows="4" placeholder="Complaint Detail (Required)"></textarea>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 ">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="file-field flex-wrap align-items-center">
                <div class="file-item mr-3 ml-2" *ngFor="let item of ComplaintDocuments; let index = index;">
                  <small class="mb-0"><a>{{item.name}}  <span *ngIf="documentUploadProgress">{{documentUploadProgress}}</span></a>
                    <span *ngIf="item['processingDoc'] || uploadingDocument" class="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
                    <a *ngIf="!item.id"><i (click)="deletedSelectedDocument(index)" class="ml-2 las la-times fa-lg text-danger"></i></a>
                    <a *ngIf="item.id"><i (click)="DeletePADocument(item)" class="ml-2 las la-times fa-lg text-danger"></i></a>
                  </small>
                </div>
              </div>
              <div class="file-field">
                <div class="btn btn-success btn-sm m-0 mt-0">
                  <span class="w-100"><i class="las la-paperclip fa-lg"></i> Attach File
                    <span *ngIf="uploadingDocument" class="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
                  </span>
                  <input id="attachFile" type="file" multiple (change)="onUploadOutput($event);" mdbFileSelect
                    accept=".pdf, .xlsx, .csv,.docx, .doc, .odt , .txt, .ppt, .pptx, .png" #select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="documentUploadProgress">
          <mdb-progress class="custom-d-progress my-2" value="{{documentUploadProgress}}" min="0" max="100" type="success" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">Document uploading{{documentUploadProgress}}%</mdb-progress>
        </div>
        <div *ngIf="recordingUploadProgress">
          <mdb-progress class="custom-d-progress mb-2" value="{{recordingUploadProgress}}" min="0" max="100" type="success" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">Video uploading{{recordingUploadProgress}}%</mdb-progress>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-between align-items-center">
        <div>
          <button title="Start Screen Recording" [disabled]="uploadingRecording || uploadingRecording" *ngIf="!currentRecordingUrl" class="btn btn-icon btn-dynamic-2c" (click)="StartRecording(true)"> <span class="las la-video fa-lg"></span></button>
          <button title="Capture ScreenShot" class="btn btn-icon btn-dynamic-2c" (click)="captureScreenshot('attachFile')"><span class="las la-camera-retro fa-lg"></span></button>
          <!-- <button class="btn btn-sm btn-success" (click)="StopRecording()"> Stop Recording</button> -->
        </div>
        <div>
          <button type="button"  class="waves-light btn btn-sm btn-dynamic-secondary-2c" aria-label="Close" (click)="ResetAndCloseModal()"
          mdbWavesEffect>Close</button>
          <button type="button"[disabled]="addingComplaint || uploadingRecording || uploadingRecording || !addComplaintObject.title || addComplaintObject.departmentType == null || !addComplaintObject.complaintTypeId || !addComplaintObject.details"
            class="relative waves-light btn btn-sm btn-dynamic-2c" (click)="AddPatientComplaint()" mdbWavesEffect>
            <span *ngIf="addingComplaint || uploadingRecording || uploadingRecording" class="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
            Submit</button>

        </div>

      </div>
    </div>
  </div>
</div>
<div mdbModal #viewPdfModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myBasicModalLabel" [config]="{backdrop: false, ignoreBackdropClick: true}" aria-hidden="true"
  (close)="objectURLStrAW=''">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-body" style="height:calc(100vh - 50px); padding: 0px;">
        <button type="button" class="close pull-right document-close" aria-label="Close" (click)="viewPdfModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <app-pdf-view *ngIf="objectURLStrAW" [documentUrl]="objectURLStrAW"></app-pdf-view>
      </div>
    </div>
  </div>
</div>

<div mdbModal #patientComplaintsModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myBasicModalLabel" aria-hidden="true" (close)="resetComplaintModal()"  (opened)="updateModalConf(patientComplaintsModal)" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="resetSelectedPatient();patientComplaintsModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">
          Patient Complaints
        </h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6">
            <p><strong class="mr-1">Patient Name:</strong>{{CurrentPatient.fullName}} </p>
            <p><strong class="mr-1">EMR ID:</strong>{{CurrentPatient.patientEmrId}}</p>
          </div>
          <div class="col-md-6 text-right">
            <button type="button" (click)="patientComplaintsModal.hide();complaintsMOdal.show();"
            class="relative waves-light btn btn-dynamic-2c btn-sm" mdbWavesEffect>
            Add Complaint</button>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 ">
            <div>
              <div class="d-flex justify-content-center text-center" style='top:0;' *ngIf="gettingComplaints" >
                <mdb-progress-bar class="progress primary-color-dark-s mb-0" mode="indeterminate"></mdb-progress-bar>
              </div>
            </div>
            <div class="table-responsive" style="height: 350px;">

              <table class="table table-bordered">
                <thead class="bg-dynamic-2c text-white">
                  <tr>
                    <th>Ticket</th>
                    <th>Type</th>
                    <th>Subtype</th>
                    <th>Date</th>
                    <th>Department</th>
                    <th>Status</th>
                    <th>Comment</th>
                  </tr>
                </thead>
                <tbody class="rpm-table">
                  <tr *ngFor="let item of patientComplaintsList">
                    <td>
                     <div class="d-flex align-items-center"><button style="cursor: pointer;" class="status-bullet mr-1 bg-success" id="popTemplate"  container="body"  mdbPopoverHeader="Log Detail" placement="right" triggers="focus" [mdbPopover]="popTemplate"  #pop="bs-mdbPopover" (appIsOutside)="pop.isOpen=false"></button>{{item.ticketNo}}
                      <ng-template #popTemplate>
                        <!-- <ng-container *ngIf="!item.patientComplaintComments || !item.patientComplaintComments.length">
                          <p class="mb-0 py-1 px-2">No comments found</p>
                        </ng-container>
                        <ng-container *ngFor="let items of item.patientComplaintComments">
                          <p class="mb-0 pb-1 px-2" *ngIf="items.comment" >{{items.comment}}</p>
                        </ng-container> -->


                        <div style="max-height:200px; width:calc(100% - 27px); padding: .70rem;" (click)="$event.stopPropagation()" [innerHTML]="item.logDetails || '<p>No data</p>'" 
                          [style.overflow-y]="'auto'" >
                        </div>

                      </ng-template>
                    </div>
                    </td>
                    <td style="white-space:  pre;">{{item.complaintType}}</td>
                    <td style="white-space:  pre;">{{item.complaintSubType}}</td>
                    <td  style="white-space:  pre;">{{item.updatedOn | date}}</td>
                    <td>{{DepartmentTypeEnum[item.departmentType]}}</td>
                    <td style="vertical-align: top;">
                      <span style="min-width: 80px;" *ngIf="item.complaintStatus == 1" class="badge badge-danger">{{ComplaintStatusEnum[item.complaintStatus]}}</span>
                      <span style="min-width: 80px;" *ngIf="item.complaintStatus == 2" class="badge badge-warning">{{ComplaintStatusEnum[item.complaintStatus]}}</span>
                      <span style="min-width: 80px;" *ngIf="item.complaintStatus == 3" class="badge badge-primary">{{ComplaintStatusEnum[item.complaintStatus]}}</span>
                      <span style="min-width: 80px;" *ngIf="item.complaintStatus == 4" class="badge badge-success">{{ComplaintStatusEnum[item.complaintStatus]}}</span>
                    </td>
                    <td>{{item.details}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <img src="../../../assets/audios/timer_countdown.mp3" alt=""> -->